/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import CustomFormField from "components/CustomFormField";
import { connect } from "react-redux";
import {
  GRADES,
  COURSES,
  SKILLS,
  UNITS,
  OBJECTIVES,
  SPECIAL_DAYS,
  MATERIAL_LEVELS,
  DOCUMENT_TYPES,
  LEARNING_DESIGN_TYPES,
} from "redux/actions/constants";
import MaterialUpload from "../MaterialUpload";

const MaterialForm = ({
  formData,
  lookups,
  allBooks,
  localizations,
  url,
  onMaterialChange,
  onMaterialRemove,
  loading,
}) => {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
  } = formData;

  const {
    book: { errorMsg: _book, ...bookRest },
    grade: { errorMsg: _grade, ...gradeRest },
    course: { errorMsg: _course, ...courseRest },
    skill: { errorMsg: _skill, ...skillRest },
    unit: { errorMsg: _unit, ...unitRest },
    specialDay: { errorMsg: _specialDay, ...specialDayRest },
    objectives: { errorMsg: _objectives, ...objectivesRest },
    duration: { errorMsg: _dur, ...durationRest },
    level: { errorMsg: _level, ...levelRest },
    materialOrder: { errorMsg: _materialOrder, ...materialOrderRest },
    documentType: { errorMsg: _documentType, ...documentTypeRest },
    description: { errorMsg: _description, ...descriptionRest },
    introduction: { errorMsg: _introduction, ...introductionRest },
    activity: { errorMsg: _activity, ...activityRest },
    assessment: { errorMsg: _assessment, ...assessmentRest },
    learningDesignType: { errorMsg: _learningDesignType, ...learningDesignTypeRest },
  } = formField;

  const {
    book: bookV,
    grade: gradeV,
    course: courseV,
    skill: skillV,
    unit: unitV,
    specialDay: specialDayV,
    objectives: objectivesV,
    duration: durationV,
    level: levelV,
    materialOrder: materialOrderV,
    documentType: documentTypeV,
    description: descriptionV,
    introduction: introductionV,
    activity: activityV,
    assessment: assessmentV,
    learningDesignType: learningDesignTypeV,
  } = values;

  return (
    <>
      <SuiTypography variant="h5">
        {localizations?.MATERIAL_FORM_HEADER ?? "Materyal Genel Detayları"}
      </SuiTypography>
      <SuiBox mt={1} mb={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...bookRest}
              options={
                allBooks &&
                allBooks !== undefined &&
                allBooks !== null &&
                Object.values(allBooks).length > 0
                  ? Object.values(allBooks).map((b) => ({
                      value: b.id,
                      label: b.title,
                    }))
                  : []
              }
              value={bookV}
              error={errors.book?.length > 0 && !(touched.book !== true)}
              success={bookV && !errors.book}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...gradeRest}
              resets={[
                { name: courseRest.name, value: null },
                { name: skillRest.name, value: null },
                { name: unitRest.name, value: null },
                { name: specialDayRest.name, value: null },
                { name: objectivesRest.name, value: [] },
              ]}
              options={lookups.l_grades?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
              }))}
              value={gradeV}
              error={errors.grade?.length > 0 && !(touched.grade !== true)}
              success={gradeV && !errors.grade}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...courseRest}
              resets={[
                { name: skillRest.name, value: null },
                { name: unitRest.name, value: null },
                { name: specialDayRest.name, value: null },
                { name: objectivesRest.name, value: [] },
              ]}
              options={lookups.l_courses?.lookupValues
                ?.filter((lv) => lv.parentValueKey === gradeV?.value || lv.parentValueKey === null)
                ?.map((lv) => ({
                  value: lv.lookupValueKey,
                  label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
                }))}
              value={courseV}
              error={errors.course?.length > 0 && !(touched.course !== true)}
              success={courseV && !errors.course}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>

          {courseV?.value.includes("TURKISH") ? (
            <Grid item xs={12} lg={4}>
              <CustomFormField
                {...skillRest}
                resets={[{ name: objectivesRest.name, value: [] }]}
                options={lookups.l_skills?.lookupValues
                  ?.filter(
                    (lv) => lv.parentValueKey === courseV?.value || lv.parentValueKey === null
                  )
                  ?.map((lv) => ({
                    value: lv.lookupValueKey,
                    label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
                  }))}
                value={skillV}
                error={errors.skill?.length > 0 && !(touched.skill !== true)}
                success={skillV && !errors.skill}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </Grid>
          ) : null}

          {courseV?.value.includes("LIFE_SCIENCE") ? (
            <Grid item xs={12} lg={4}>
              <CustomFormField
                {...unitRest}
                resets={[{ name: objectivesRest.name, value: [] }]}
                options={lookups.l_units?.lookupValues
                  ?.filter(
                    (lv) => lv.parentValueKey === courseV?.value || lv.parentValueKey === null
                  )
                  ?.map((lv) => ({
                    value: lv.lookupValueKey,
                    label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
                  }))}
                value={unitV}
                error={errors.unit?.length > 0 && !(touched.unit !== true)}
                success={unitV && !errors.unit}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </Grid>
          ) : null}

          {courseV?.value === "SPECIAL_DAYS" ? (
            <Grid item xs={12} lg={4}>
              <CustomFormField
                {...specialDayRest}
                resets={[{ name: objectivesRest.name, value: [] }]}
                options={lookups.l_specialDays?.lookupValues
                  ?.filter(
                    (lv) => lv.parentValueKey === courseV?.value || lv.parentValueKey === null
                  )
                  ?.map((lv) => ({
                    value: lv.lookupValueKey,
                    label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
                  }))}
                value={specialDayV}
                error={errors.specialDay?.length > 0 && !(touched.specialDay !== true)}
                success={specialDayV && !errors.specialDay}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </Grid>
          ) : (
            <Grid item xs={12} lg={4}>
              <CustomFormField
                {...objectivesRest}
                options={lookups.l_objectives?.lookupValues
                  ?.filter((lv) => {
                    if (skillV && skillV.value) {
                      return lv.parentValueKey === skillV?.value || lv.parentValueKey === null;
                    }
                    if (unitV && unitV.value) {
                      return lv.parentValueKey === unitV?.value || lv.parentValueKey === null;
                    }

                    return lv.parentValueKey === null;
                  })
                  ?.map((lv) => ({
                    value: lv.lookupValueKey,
                    label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
                  }))}
                value={objectivesV}
                error={
                  errors.objectives?.length > 0 &&
                  touched.objectives !== undefined &&
                  touched.objectives !== false
                }
                success={objectivesV.length > 0 && !errors.objectives}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                isForObjective
              />
            </Grid>
          )}

          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...learningDesignTypeRest}
              options={lookups.l_learningDesignTypes?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
              }))}
              value={learningDesignTypeV}
              error={
                errors.learningDesignType?.length > 0 && !(touched.learningDesignType !== true)
              }
              success={learningDesignTypeV && !errors.learningDesignType}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...durationRest}
              value={durationV}
              error={errors.duration?.length > 0 && !(touched.duration !== true)}
              success={durationV > 0 && !errors.duration}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...levelRest}
              options={lookups.l_levels?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
              }))}
              value={levelV}
              error={errors.level?.length > 0 && !(touched.level !== true)}
              success={levelV && !errors.level}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...materialOrderRest}
              value={materialOrderV}
              error={errors.materialOrder?.length > 0 && !(touched.materialOrder !== true)}
              success={materialOrderV > 0 && !errors.materialOrder}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...documentTypeRest}
              options={lookups.l_documentTypes?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
              }))}
              value={documentTypeV}
              error={errors.documentType?.length > 0 && !(touched.documentType !== true)}
              success={documentTypeV && !errors.documentType}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Materyal Yükle
              </SuiTypography>
            </SuiBox>
            <MaterialUpload
              height={120}
              loading={loading}
              url={url}
              removeBtnRight="0"
              onMaterialRemove={onMaterialRemove}
              onMaterialChange={onMaterialChange}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...descriptionRest}
              value={descriptionV}
              error={errors.description?.length > 0 && touched.description}
              success={descriptionV.length > 0 && !errors.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...introductionRest}
              value={introductionV}
              error={errors.introduction?.length > 0 && touched.introduction}
              success={introductionV.length > 0 && !errors.introduction}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...activityRest}
              value={activityV}
              error={errors.activity?.length > 0 && touched.activity}
              success={activityV.length > 0 && !errors.activity}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...assessmentRest}
              value={assessmentV}
              error={errors.assessment?.length > 0 && touched.assessment}
              success={assessmentV.length > 0 && !errors.assessment}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

MaterialForm.defaultProps = {
  lookups: null,
  allBooks: null,
  localizations: null,
  url: "",
  onMaterialRemove: null,
  loading: false,
};

MaterialForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  lookups: PropTypes.objectOf(PropTypes.any),
  allBooks: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  url: PropTypes.string,
  onMaterialRemove: PropTypes.func,
  onMaterialChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  let lookups = {};
  if (state.data.lookups && Object.keys(state.data.lookups).length !== 0) {
    lookups = {
      l_grades: state.data.lookups[GRADES],
      l_courses: state.data.lookups[COURSES],
      l_skills: state.data.lookups[SKILLS],
      l_units: state.data.lookups[UNITS],
      l_objectives: state.data.lookups[OBJECTIVES],
      l_specialDays: state.data.lookups[SPECIAL_DAYS],
      l_levels: state.data.lookups[MATERIAL_LEVELS],
      l_documentTypes: state.data.lookups[DOCUMENT_TYPES],
      l_learningDesignTypes: state.data.lookups[LEARNING_DESIGN_TYPES],
    };
  }

  return { lookups, allBooks: state.data.books, localizations: state.localization?.pairs };
};

export default connect(mapStateToProps, null)(MaterialForm);
