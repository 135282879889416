/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import PageInfo from "layouts/book/components/PageInfo";
import CoverInfo from "layouts/book/components/CoverInfo";
import { connect } from "react-redux";

function getSteps(numberOfPage) {
  const pages = Array.from({ length: numberOfPage }, (_, i) => ({
    type: "page",
    name: `Page ${i + 1}`,
    number: i + 1,
  }));

  return [
    { type: "cover", name: "Front Outer Cover Page", number: 0 },
    { type: "cover", name: "Front Inner Cover Page", number: 0 },
    ...pages,
    { type: "cover", name: "Back Inner Cover Page", number: 0 },
    { type: "cover", name: "Back Outer Cover Page", number: 0 },
  ];
}

function getStepContent(step, isFirstStep, isLastStep, handleBack, handleNext, sequence) {
  if (!step) return null;

  switch (step.type) {
    case "cover":
      return (
        <CoverInfo
          key={`PageKey${sequence}`}
          name={step.name}
          firstStep={isFirstStep}
          lastStep={isLastStep}
          handleBack={handleBack}
          handleNext={handleNext}
          sequence={sequence}
          number={step.number}
        />
      );
    case "page":
      return (
        <PageInfo
          key={`PageKey${sequence}`}
          name={step.name}
          firstStep={isFirstStep}
          lastStep={isLastStep}
          handleBack={handleBack}
          handleNext={handleNext}
          sequence={sequence}
          number={step.number}
        />
      );
    default:
      return null;
  }
}

function BookPages({ book }) {
  const [steps, setSteps] = useState([]);

  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;
  const numberOfPage = book.pageCount;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    setSteps(getSteps(numberOfPage));
  }, [numberOfPage]);

  return (
    <>
      <SuiBox>
        <SuiTypography variant="h5" textTransform="capitalize">
          page settings
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" color="text" mt={0}>
          Set each page one by one and press the save button.
        </SuiTypography>
      </SuiBox>
      <SuiBox my={5}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <SuiBox>
              <SuiBox>
                {getStepContent(
                  steps[activeStep], // step
                  isFirstStep, // isFirstStep
                  isLastStep, // isLastStep
                  handleBack, // handleBack
                  handleNext, // handleNext
                  activeStep + 1 // sequence
                )}
              </SuiBox>
            </SuiBox>
            {/* </Card> */}
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
}

const mapStateToProps = (state) => {
  const book = state.data.books[state.data.currentBookId];
  return {
    book,
  };
};

export default connect(mapStateToProps, null)(BookPages);
