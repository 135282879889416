/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { startForgotPasswordPage, sendLinkByEmail } from "redux/actions/pages";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import lock from "assets/images/illustrations/lock.png";

import Alert from "custom/Alert";
import Loading from "custom/Loading";

const ForgotPassword = ({ redirectTo, startPage, sendLink }) => {
  const [email, setEmail] = useState("");

  const history = useHistory();

  useEffect(() => {
    startPage();
  }, []);

  useEffect(() => {
    if (redirectTo && redirectTo !== "" && redirectTo !== "/create-password") {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  const onSendLinkClick = (e) => {
    e.preventDefault();
    sendLink({ email });
  };

  return (
    <>
      <Alert />
      <Loading />
      <IllustrationLayout
        title="Şifremi unuttum"
        description="Bağlantı içeren bir email alacaksınız."
        illustration={{
          image: lock,
          title: "Class Readers",
          description:
            "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
        }}
      >
        <SuiBox component="form" role="form">
          <SuiBox mb={2}>
            <SuiInput
              type="email"
              placeholder="Email"
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              onClick={onSendLinkClick}
            >
              BAĞLANTI YOLLA
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </IllustrationLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state.ui.redirectTo,
});

const mapDispatchToProps = {
  startPage: startForgotPasswordPage,
  sendLink: sendLinkByEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
