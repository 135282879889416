import { PAGES } from "redux/actions/pages/constants";

// feature name
export const ALL_QUIZES = `${PAGES} [AllQuizes]`;
export const QUIZ_FORM = `${PAGES} [QuizForm]`;

// action types
export const SAVE_UNSAVED_QUIZ = `${QUIZ_FORM} SAVE_UNSAVED_QUIZ`;
export const START_NEW_QUIZ_SCREEN = `${QUIZ_FORM} START`;
export const GET_QUIZ_FORM_LOOKUPS = `${QUIZ_FORM} GET_LOOKUPS`;
export const SAVE_QUIZ_INFO = `${QUIZ_FORM} SAVE_QUIZ_INFO`;
export const SAVE_QUESTIONS = `${QUIZ_FORM} SAVE_QUESTIONS`;
export const START_ALL_QUIZES_SCREEN = `${ALL_QUIZES} START`;
export const UPDATE_QUIZ_ON_ALL_QUIZES = `${ALL_QUIZES} UPDATE`;

// action creators
export const saveUnsavedQuiz = ({ unsavedQuiz }) => ({
  type: SAVE_UNSAVED_QUIZ,
  payload: { unsavedQuiz },
});

export const startNewQuizScreen = () => ({
  type: START_NEW_QUIZ_SCREEN,
});

export const getQuizFormLookups = () => ({
  type: GET_QUIZ_FORM_LOOKUPS,
});

// quiz = { id?, bookId, type, duration, grade, level, order, isPublished, isPrimary }
export const saveQuizInfo = ({ quiz }) => ({
  type: SAVE_QUIZ_INFO,
  payload: { quiz },
});

export const saveQuestions = ({ quizId, questions }) => ({
  type: SAVE_QUESTIONS,
  payload: { quizId, questions },
});

export const startAllQuizesScreen = () => ({
  type: START_ALL_QUIZES_SCREEN,
});

export const updateQuizOnAllQuizes = ({ quizId, isPublished }) => ({
  type: UPDATE_QUIZ_ON_ALL_QUIZES,
  payload: { quizId, isPublished },
});
