export default {
  formId: "material-details-form",
  formField: {
    book: {
      name: "book",
      label: "Kitap",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen bir kitap seçiniz.",
      errorMsg: "Kitap seçimi zorunlu.",
    },
    grade: {
      name: "grade",
      label: "Sınıf",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen sınıf seçiniz.",
      errorMsg: "Sınıf seçimi zorunlu.",
    },
    course: {
      name: "course",
      label: "Ders",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen ders seçiniz.",
      errorMsg: "Ders seçimi zorunlu.",
    },
    skill: {
      name: "skill",
      label: "Beceri",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen beceri seçiniz.",
      errorMsg: "Beceri seçimi zorunlu.",
    },
    unit: {
      name: "unit",
      label: "Ünite",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen ünite seçiniz.",
      errorMsg: "Ünite seçimi zorunlu.",
    },
    specialDay: {
      name: "specialDay",
      label: "Özel Gün",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen özel gün seçiniz.",
      errorMsg: "Özel gün seçimi zorunlu.",
    },
    objectives: {
      name: "objectives",
      label: "Kazanım",
      type: "select",
      size: "large",
      isMulti: true,
      placeholder: "Lütfen kazanım seçiniz.",
      errorMsg: "Kazanım seçimi zorunlu.",
    },
    duration: {
      name: "duration",
      label: "Süre (dk)",
      type: "number",
      placeholder: "örn. 6",
      size: "large",
      errorMsg: "Süre en az 1 dk olmalıdır.",
    },
    level: {
      name: "level",
      label: "Seviye",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen seviye seçiniz.",
      errorMsg: "Seviye seçimi zorunlu.",
    },
    materialOrder: {
      name: "materialOrder",
      label: "Sıra",
      type: "number",
      placeholder: "örn. 1",
      size: "large",
      errorMsg: "Sıra numarası 0'dan büyük olmalıdır.",
    },
    documentType: {
      name: "documentType",
      label: "Döküman Türü",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen döküman türü seçiniz.",
      errorMsg: "Döküman türü seçimi zorunlu.",
    },
    description: {
      name: "description",
      label: "Açıklama",
      type: "text",
      multiline: true,
      rows: 5,
      placeholder: "Döküman hakkında açıklama.",
      errorMsg: "Açıklama alanı girilmelidir.",
      // invalidMsg: "General information can be up to 400 chars.",
    },
    introduction: {
      name: "introduction",
      label: "Giriş",
      type: "text",
      multiline: true,
      rows: 5,
      placeholder: "Ders girişi hakkında açıklama ve yapılacakları giriniz.",
      errorMsg: "Ders girişi zorunludur.",
      // invalidMsg: "General information can be up to 400 chars.",
    },
    activity: {
      name: "activity",
      label: "Öğrenim Etkinliği",
      type: "text",
      multiline: true,
      rows: 5,
      placeholder: "Öğrenim etkinliği giriniz.",
      errorMsg: "Öğrenim etkinliği zorunludur.",
      // invalidMsg: "General information can be up to 400 chars.",
    },
    assessment: {
      name: "assessment",
      label: "Öğrenim Değerlendirilmesi",
      type: "text",
      multiline: true,
      rows: 5,
      placeholder: "Öğrenim sonu değerlendirilmesi giriniz.",
      errorMsg: "Öğrenim değerlendirilmesi zorunludur.",
      // invalidMsg: "General information can be up to 400 chars.",
    },
    learningDesignType: {
      name: "learningDesignType",
      label: "Öğrenim Tasarım Türü",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen öğrenim tasarım türü seçiniz.",
      errorMsg: "Öğrenim tasarım türü seçimi zorunlu.",
    },
    // material: {},
    isPublished: {
      name: "isPublished",
      label: "Yayınla",
      type: "switch",
    },
  },
};
