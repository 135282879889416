/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import Alert from "custom/Alert";
import SuiBox from "components/SuiBox";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import { connect } from "react-redux";
import QuizDetails from "../components/quiz-details";

const EditQuiz = ({ quiz, redirectTo }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (redirectTo && redirectTo !== "" && redirectTo !== "/quiz/edit") {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox mt={3} mb={8}>
          <QuizDetails quiz={quiz} />
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

EditQuiz.defaultProps = {
  quiz: null,
  redirectTo: "",
};

EditQuiz.propTypes = {
  quiz: PropTypes.objectOf(PropTypes.any),
  redirectTo: PropTypes.string,
};

const mapStateToProps = (state, { location }) => ({
  quiz: state.data.quizes[location.state?.quizId],
  redirectTo: state.ui.redirectTo,
});

export default connect(mapStateToProps, null)(EditQuiz);
