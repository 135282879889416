/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import Swal from "sweetalert2";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import Table from "custom/Tables/Table";

import SuiTypography from "components/SuiTypography";
import DataTable from "custom/Tables/DataTable";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import SuiButton from "components/SuiButton";
// import dataTableData from "layouts/book/book-list/data/tableData";
import { startAllBooksScreen, updateBookOnAllBooks } from "redux/actions/pages/book";
import { connect } from "react-redux";

// import book1 from "assets/images/keremin-siiri.png";
import BookCell from "layouts/book/book-list/components/BookCell";
import BookLevelCell from "layouts/book/book-list/components/BookLevelCell";
import Switch from "@mui/material/Switch";
import coverPlaceholder from "assets/images/book-cover-placeholder.png";
import Alert from "custom/Alert";
import { ALL_BOOKS } from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import MessageTypes from "enums/message-types";

const columns = [
  {
    Header: "BOOK",
    accessor: "book",
    width: "20%",
    Cell: ({ value: [name, data] }) => (
      <BookCell
        image={data.image}
        name={name}
        fontWeight={data.fontWeight}
        path={data.editPath}
        id={data.bookId}
      />
    ),
  },
  {
    Header: "AUTHOR",
    accessor: "author",
  },
  {
    Header: "SERIES",
    accessor: "series",
  },
  {
    Header: "BOOK LEVEL",
    accessor: "bookLevel",
    Cell: ({ value: [name] }) => <BookLevelCell key={name} content={name} />,
  },
  {
    Header: "GRADE",
    accessor: "grade",
  },
  {
    Header: "CATEGORY",
    accessor: "category",
  },
  {
    Header: "PAGES",
    accessor: "pages",
  },
  {
    Header: "DATE OF ENTRY",
    accessor: "dateOfEntry",
  },
  {
    Header: "PUBLISHER",
    accessor: "publisher",
  },
  {
    Header: "PUBLISH",
    accessor: "publish",
    Cell: ({ value: [data, handleChange] }) => (
      <SuiBox mx={1}>
        <Switch
          checked={data.checked}
          onChange={(e) => {
            handleChange({ bookId: data.id, isPublished: e.target.checked });
          }}
        />
      </SuiBox>
    ),
  },
];

function BookList({ allBooks, startScreen, togglePublish, message, removeNotificationMsg }) {
  const currentPath = useLocation().pathname;
  const history = useHistory();

  // HELPER FUNCTIONS
  const getDataTableData = (bookList) => ({
    columns,
    rows: bookList
      ? bookList.map((b) => ({
          book: [
            b.title,
            {
              image: b.pages?.find((p) => p.pageSequence === 1)?.image ?? coverPlaceholder,
              fontWeight: "medium",
              editPath: `${currentPath}/edit`,
              bookId: b.id,
            },
          ],
          author: b.author,
          series: b.series,
          bookLevel: [b.level],
          grade: b.grade,
          category: b.category,
          pages: b.pageCount,
          dateOfEntry: "14/06/2021",
          publisher: b.publisher,
          publish: [{ id: b.id, checked: b.isPublished }, togglePublish],
        }))
      : [],
  });

  // Table data of books
  const [books, setBooks] = useState({
    columns,
    rows: [],
  });

  useEffect(() => {
    startScreen();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeof allBooks !== "undefined") {
      const bookArray = Object.values(allBooks);
      setBooks(getDataTableData(bookArray));
    }
    // eslint-disable-next-line
  }, [allBooks]);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title: message.messageType === MessageTypes.success ? "BAŞARILI!" : "HATA!",
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: `Anasayfaya dön`,
      }).then(() => {
        removeNotificationMsg(ALL_BOOKS);
        history.push("/");
      });
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox my={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
              <SuiBox lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  All Books
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" color="text">
                  List of books both published and not published.
                </SuiTypography>
              </SuiBox>
              <Stack spacing={1} direction="row">
                <Link to={`${currentPath}/new`}>
                  <SuiButton variant="contained" color="dark" size="small">
                    + new book
                  </SuiButton>
                </Link>
              </Stack>
            </SuiBox>
            <DataTable
              table={books}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 10, 15, 20, 25],
              }}
              canSearch
              pagination={{ variant: "contained", color: "dark" }}
            />
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

const mapStateToProps = (state) => ({
  allBooks: state.data.books,
  loading: state.ui.loading,
  message: state.message[ALL_BOOKS],
});

const mapDispatchToProps = {
  startScreen: startAllBooksScreen,
  togglePublish: updateBookOnAllBooks,
  removeNotificationMsg: removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookList);
