import React, { useState, useEffect } from "react";
import { Card, Dialog, Checkbox, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { saveUnsavedQuiz } from "redux/actions/pages/quiz";
import { connect } from "react-redux";
import { Howl } from "howler";
import ImageKit from "imagekit-javascript";
import AudioUpload from "../AudioUpload";
import ImageUpload from "../ImageUpload";

const ChoiceEditDialog = ({
  onClose,
  open,
  choice,
  questionId,
  unsavedQuiz,
  saveUnsavedData,
  localizations,
}) => {
  const [currentChoice, setCurrentChoice] = useState({ ...choice });
  const [soundUploadLoading, setSoundUploadLoading] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    setCurrentChoice({ ...choice });
  }, [choice]);

  useEffect(() => {
    if (currentChoice.choiceAudioUrl && currentChoice.choiceAudioUrl !== "") {
      if (audio) {
        audio.unload();
        if (isAudioPlaying) {
          setIsAudioPlaying(false);
        }
      }

      const sound = new Howl({
        src: currentChoice.choiceAudioUrl,
      });

      sound.on("end", () => {
        setIsAudioPlaying(false);
        // setAudio(null);
      });

      setAudio(sound);
    }
  }, [currentChoice.choiceAudioUrl]);

  useEffect(() => {
    if (isAudioPlaying) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [isAudioPlaying, audio]);

  const onChoiceTextChange = (event) =>
    setCurrentChoice({ ...currentChoice, choiceText: event.target.value });

  const onChoiceSoundFileChange = (url) =>
    setCurrentChoice({ ...currentChoice, choiceAudioUrl: url });

  const onChoiceImageFileChange = (url) =>
    setCurrentChoice({ ...currentChoice, choiceImageUrl: url });

  const onFileInputChange = (event, isImage = true) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      if (isImage) {
        setImageUploadLoading(true);
        const imagekit = new ImageKit({
          publicKey: "public_qaLsRdm60abMeMnCPrq6XYAc0Gw=",
          urlEndpoint: "https://ik.imagekit.io/mvnw0kw1mga",
          authenticationEndpoint: "https://okumaplatformu.com/api/upload/imagekit-endpoint",
        });

        imagekit.upload(
          {
            file,
            fileName: file.name,
          },
          (err, result) => {
            if (err) {
              // eslint-disable-next-line no-console
              console.log("Error on imagekit.upload");
              // eslint-disable-next-line no-console
              console.log(err);
              return;
            }
            // setImageUrl(result.url);
            onChoiceImageFileChange(result.url);
            setImageUploadLoading(false);
          }
        );
      } else {
        setSoundUploadLoading(true);
        const fileNameArray = file.name.split(".");

        let extention;
        if (fileNameArray && fileNameArray.length > 1) {
          extention = fileNameArray[fileNameArray.length - 1];
        }
        const folderName = "quizsoundfiles";

        axios
          .get(
            `https://okumaplatformu.com/api/upload/get-do-presigned-url?type=audio/mpeg&folder=${folderName}&extention=${extention}`
          )
          .then((res) => {
            const { key, url } = res.data;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", `${url}`);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.setRequestHeader("x-amz-acl", "public-read");
            xhr.send(file);
            // setSoundUploadLoading(true);

            xhr.onload = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  onChoiceSoundFileChange(
                    `https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`
                  );
                } else {
                  // eslint-disable-next-line no-console
                  console.error(xhr.statusText);
                }
              }
            };

            xhr.onerror = () => {
              // eslint-disable-next-line no-console
              console.error("on sound upload error");
              // eslint-disable-next-line no-console
              console.error(xhr.statusText);
            };

            xhr.onloadend = () => {
              setSoundUploadLoading(false);
            };
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }
  };

  const onCheckboxChange = (event) =>
    setCurrentChoice({ ...currentChoice, isCorrect: event.target.checked });

  const handleSubmit = (e) => {
    e.preventDefault();
    let choices = { ...unsavedQuiz?.questions[questionId]?.choices };

    if (currentChoice.isCorrect && !choice.isCorrect) {
      unsavedQuiz?.questions[questionId]?.choiceOrder?.forEach((cId) => {
        choices = {
          ...choices,
          [cId]: {
            ...choices[cId],
            isCorrect: false,
          },
        };
      });
    }

    saveUnsavedData({
      unsavedQuiz: {
        ...unsavedQuiz,
        questions: {
          ...unsavedQuiz?.questions,
          [questionId]: {
            ...unsavedQuiz?.questions[questionId],
            choices: {
              ...choices,
              [currentChoice.choiceId]: currentChoice,
            },
          },
        },
      },
    });

    onClose();
  };

  return (
    <Dialog scroll="body" onClose={onClose} open={open}>
      <Card>
        <SuiBox
          bgColor="white"
          variant="gradient"
          width={{
            lg: "500px",
            md: "400px",
            sm: "300px",
            xs: "200px",
          }}
        >
          <SuiBox pt={3} px={3}>
            <SuiTypography variant="h6" fontWeight="medium" color="dark">
              {localizations?.EDIT_CHOICE ?? "Cevap Şıkkını Düzenle"}
            </SuiTypography>
          </SuiBox>
          <SuiBox p={2}>
            <>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="block">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {localizations?.CHOICE_TEXT ?? "Cevap Şıkkı Metni"}
                  </SuiTypography>
                  <AudioUpload
                    audioUrl={currentChoice.choiceAudioUrl}
                    uploadTitle={localizations?.AUDIO_UPLOAD_TITLE ?? "Ses Yükle"}
                    disabled={soundUploadLoading}
                    onFileInputChange={(e) => onFileInputChange(e, false)}
                    isPlaying={isAudioPlaying}
                    setIsPlaying={setIsAudioPlaying}
                    onRemoveAudioUrl={() => {
                      onChoiceSoundFileChange("");
                    }}
                  />
                </div>
              </SuiBox>
              <SuiInput
                type="text"
                name="choiceText"
                value={currentChoice.choiceText}
                onChange={onChoiceTextChange}
              />
            </>
          </SuiBox>
          <SuiBox px={3}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="block">
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={currentChoice.isCorrect}
                    onChange={onCheckboxChange}
                  />
                }
                label={localizations?.CORRECT_ANSWER ?? "Doğru Cevap"}
              />
            </SuiBox>
          </SuiBox>
          <SuiBox p={2} mb={3}>
            <ImageUpload
              disabled={imageUploadLoading}
              imageUrl={currentChoice.choiceImageUrl}
              onImageChange={(e) => onFileInputChange(e, true)}
              onImageRemove={() => onChoiceImageFileChange("")}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox p={2} mb={4} display="flex">
          <SuiBox ml="auto">
            <SuiButton
              // disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="light"
              size="small"
              onClick={onClose}
            >
              {localizations?.CANCEL_BTN ?? "İPTAL"}
            </SuiButton>
          </SuiBox>
          <SuiBox ml={1}>
            <SuiButton
              // disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="dark"
              size="small"
              onClick={handleSubmit}
            >
              {localizations?.SAVE_BTN ?? "KAYDET"}
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </Card>
    </Dialog>
  );
};

ChoiceEditDialog.defaultProps = {
  choice: null,
  unsavedQuiz: null,
  localizations: null,
};

ChoiceEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  choice: PropTypes.objectOf(PropTypes.any),
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unsavedQuiz: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  saveUnsavedData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  unsavedQuiz: state.data.unsavedQuiz,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  saveUnsavedData: saveUnsavedQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceEditDialog);
