import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Tooltip, IconButton } from "@mui/material";
import { FunnelSimple, FileSearch, Pencil } from "phosphor-react";

import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import DataTable from "custom/Tables/DataTable";

import Alert from "custom/Alert";
import MessageTypes from "enums/message-types";
import {
  ALL_MATERIALS,
  startAllMaterialsScreen,
  updateMaterialOnAllMaterials,
} from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import BookCell from "../components/BookCell";
import PublishCell from "../components/PublishCell";

const MaterialList = ({
  message,
  localizations,
  allMaterials,
  startScreen,
  removeErrorMsg,
  updateMaterial,
  language,
}) => {
  const [materialFilter, setMaterialFilter] = useState("");
  const history = useHistory();

  useEffect(() => startScreen(), []);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title:
          message.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS ?? "BAŞARILI!"
            : localizations?.MESSAGE_ERROR ?? "HATA!",
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_GO_HOME_CONFIRM_BTN,
      }).then(() => {
        removeErrorMsg(ALL_MATERIALS);
        history.push("/");
      });
    }
    // eslint-disable-next-line
  }, [message]);

  const handlePublishChange = useCallback(({ materialId, isPublished }) => {
    updateMaterial({ materialId, isPublished });
  }, []);

  const columns = [
    {
      Header: localizations?.BOOK ?? "Kitap",
      accessor: "book",
      width: "20%",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <BookCell data={data} />,
    },
    {
      Header: localizations?.GRADE ?? "Sınıf",
      accessor: "grade",
    },
    {
      Header: localizations?.COURSE ?? "Ders",
      accessor: "course",
    },
    {
      Header: localizations?.DURATION ?? "Süre",
      accessor: "duration",
    },
    {
      Header: localizations?.OBJECTIVES ?? "Kazanımlar",
      accessor: "objectives",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => {
        const objectiveList = data?.split(",") ?? [];

        return objectiveList.map((obj) => (
          <div key={obj} style={{ marginBottom: "5px" }}>
            <Tooltip title={localizations[obj] ?? obj} placement="left">
              <SuiBadge color="light" badgeContent={obj.replace(/_/g, ".")} size="sm" container />
            </Tooltip>
          </div>
        ));
      },
    },
    {
      Header: localizations?.LEVEL ?? "Seviye",
      accessor: "level",
    },
    {
      Header: localizations?.ORDER ?? "Sıra",
      accessor: "order",
    },
    {
      Header: localizations?.PUBLISH ?? "Yayınla",
      accessor: "publish",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => (
        <PublishCell data={data} handlePublishChange={handlePublishChange} />
      ),
    },
    {
      Header: localizations?.ACTIONS ?? "Actions",
      accessor: "preview",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => (
        <SuiBox mx={1} display="flex">
          <Tooltip
            title={localizations?.OPEN_DOCUMENT_IN_NEW_TAB ?? "Dökümanı yeni sekmede aç"}
            placement="left"
          >
            <IconButton
              aria-label={localizations?.OPEN_DOCUMENT_IN_NEW_TAB}
              href={data.fileUrl}
              target="_blank"
            >
              <FileSearch size={32} weight="duotone" color="#7493b6" />
            </IconButton>
          </Tooltip>
          <Tooltip title={localizations?.EDIT ?? "Düzenle"} placement="bottom">
            <IconButton
              aria-label={localizations?.EDIT}
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/material/edit",
                  state: { materialId: data.id },
                });
              }}
            >
              <Pencil size={32} weight="duotone" color="#7493b6" />
            </IconButton>
          </Tooltip>
        </SuiBox>
      ),
    },
  ];

  const getDataTableData = (materialList, filter, lang) => ({
    columns,
    rows:
      materialList && materialList.length > 0
        ? materialList
            .filter((m) => {
              let lowercaseFilter = "";
              if (filter && filter !== "") {
                lowercaseFilter = filter.toLocaleLowerCase(lang);
              }
              return (
                m.book.title.toLocaleLowerCase(lang).includes(lowercaseFilter) ||
                (localizations[m.course] ?? m.course)
                  ?.toLocaleLowerCase(lang)
                  ?.includes(lowercaseFilter) ||
                (localizations[m.level] ?? m.level)
                  ?.toLocaleLowerCase(lang)
                  ?.includes(lowercaseFilter) ||
                m.objectives.replace(/_/g, ".")?.toLocaleLowerCase(lang)?.includes(lowercaseFilter)
              );
            })
            .map((m) => ({
              book: [
                {
                  coverImage: m.book.coverImage,
                  fontWeight: "medium",
                  title: m.book.title,
                },
              ],
              grade: localizations[m.grade] ?? m.grade,
              course: localizations[m.course] ?? m.course,
              duration: m.duration,
              objectives: [m.objectives],
              level: localizations[m.level] ?? m.level,
              order: m.materialOrder,
              publish: [{ id: m.id, checked: m.isPublished }, handlePublishChange],
              preview: [{ id: m.id, fileUrl: m.fileUrl }],
            }))
        : [],
  });

  const memoizedTableData = useMemo(
    () => getDataTableData(allMaterials, materialFilter, language),
    [allMaterials, materialFilter, language]
  );

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            {/* <Grid item lg={12}> */}
            <Grid item sm={7} xs={12}>
              <h3>{localizations?.MATERIAL_LIST_HEADER}</h3>
            </Grid>
            <Grid item sm={5} xs={12}>
              <SuiInput
                size="small"
                type="search"
                placeholder={localizations?.MATERIAL_FILTER_INPUT_PLACEHOLDER}
                icon={{ component: <FunnelSimple />, direction: "left" }}
                value={materialFilter}
                onChange={(e) => {
                  setMaterialFilter(e.target.value);
                }}
              />{" "}
            </Grid>
            <Grid item lg={12} mt={3} xs={12}>
              <DataTable
                table={memoizedTableData}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25],
                }}
                // canSearch
                pagination={{ variant: "contained", color: "dark" }}
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

MaterialList.defaultProps = {
  allMaterials: [],
  message: null,
  localizations: null,
  language: "TR",
};

MaterialList.propTypes = {
  allMaterials: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  message: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  startScreen: PropTypes.func.isRequired,
  removeErrorMsg: PropTypes.func.isRequired,
  updateMaterial: PropTypes.func.isRequired,
  language: PropTypes.string,
};

const mapStateToProps = (state) => ({
  allMaterials:
    state.data.materials !== undefined && state.data.materials !== null
      ? Object.values(state.data.materials)
      : [],
  message: state.message[ALL_MATERIALS],
  localizations: state.localization?.pairs,
  language: state.localization?.language ?? "TR",
});

const mapDispatchToProps = {
  startScreen: startAllMaterialsScreen,
  removeErrorMsg: removeMessage,
  updateMaterial: updateMaterialOnAllMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialList);
