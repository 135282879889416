/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/book/components/book-details/schemas/generalInfoForm";

const {
  formField: {
    generalInfo,
    author,
    language,
    series,
    level,
    orderInLevel,
    pageCount,
    wordCount,
    category,
    genre,
    subjectArea,
    keywords,
    use,
    title,
    publisher,
  },
} = checkout;

export default {
  validation: Yup.object().shape({
    [generalInfo.name]: Yup.string()
      .required(generalInfo.errorMsg)
      .max(400, generalInfo.invalidMsg),
    [title.name]: Yup.string().required(title.errorMsg),
    [publisher.name]: Yup.object().nullable().required(publisher.errorMsg),
    [author.name]: Yup.array()
      .min(1, author.errorMsg)
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() })),
    [language.name]: Yup.object().nullable().required(language.errorMsg),
    [series.name]: Yup.object().nullable().required(series.errorMsg),
    [level.name]: Yup.object().nullable().required(level.errorMsg),
    [orderInLevel.name]: Yup.number().min(1, orderInLevel.errorMsg),
    [pageCount.name]: Yup.number()
      .min(1, pageCount.errorMsg)
      .test("is even?", pageCount.invalidMsg, (val) => val % 2 === 0),
    [wordCount.name]: Yup.number().min(1, wordCount.errorMsg),
    [category.name]: Yup.object().nullable().required(category.errorMsg),
    [genre.name]: Yup.object().nullable().required(genre.errorMsg),
    [subjectArea.name]: Yup.object().nullable().required(subjectArea.errorMsg),
    [keywords.name]: Yup.array()
      .min(1, keywords.errorMsg)
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() })),
    [use.name]: Yup.object().nullable().required(use.errorMsg),
  }),
};
