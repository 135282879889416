import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { DragDropContext } from "react-beautiful-dnd";
import { saveUnsavedQuiz, saveQuestions } from "redux/actions/pages/quiz";
import PlaceholderCard from "components/PlaceholderCard";
import QuestionTypes from "enums/question-types";
import MessageTypes from "enums/message-types";
import QuestionsDroppable from "../QuestionsDroppable";
import "./_question-container.css";

const QuestionContainer = ({
  unsavedQuiz,
  saveUnsavedData,
  saveQuizQuestions,
  isInfoChanged,
  localizations,
}) => {
  const [permittedDroppable, setPermittedDroppable] = useState("");

  const createInitialQuestion = () => {
    const id = uuidv4();
    const newInitialQuestion = {
      [id]: {
        questionId: id,
        questionType: QuestionTypes.multipleChoice,
        questionText: "",
        questionImageUrl: "",
        questionVideoUrl: "",
        questionAudioUrl: "",
        choices: null,
        choiceOrder: [],
      },
    };

    const newUnsavedQuiz = {
      ...unsavedQuiz,
      questions: {
        ...unsavedQuiz?.questions,
        ...newInitialQuestion,
      },
      questionOrder:
        unsavedQuiz && unsavedQuiz.questionOrder ? [...unsavedQuiz?.questionOrder, id] : [id],
    };

    saveUnsavedData({ unsavedQuiz: newUnsavedQuiz });
  };

  const onDragStart = (start) => setPermittedDroppable(start.source.droppableId);

  const onDragEnd = (result) => {
    setPermittedDroppable("");
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === "question") {
      const newQuestionOrder = Array.from(unsavedQuiz?.questionOrder);
      newQuestionOrder.splice(source.index, 1);
      newQuestionOrder.splice(destination.index, 0, draggableId);
      const updatedUnsavedQuiz = {
        ...unsavedQuiz,
        questionOrder: newQuestionOrder,
      };

      saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
      return;
    }

    // const home = unsavedQuiz?.questions[source.droppableId];
    // const foreign = unsavedQuiz?.questions[destination.droppableId];

    if (source.droppableId === destination.droppableId) {
      const newChoiceOrder = Array.from(unsavedQuiz?.questions[source.droppableId]?.choiceOrder);
      newChoiceOrder.splice(source.index, 1);
      newChoiceOrder.splice(destination.index, 0, draggableId);

      const updatedUnsavedQuiz = {
        ...unsavedQuiz,
        questions: {
          ...unsavedQuiz.questions,
          [source.droppableId]: {
            ...unsavedQuiz?.questions[source.droppableId],
            choiceOrder: newChoiceOrder,
          },
        },
      };

      saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
    }
  };

  const onSaveQuestions = () => {
    if (unsavedQuiz && unsavedQuiz.id > 0 && unsavedQuiz.questions) {
      const errorMessages = [];
      const prepareBody = {
        quizId: unsavedQuiz.id,
        questions: unsavedQuiz.questionOrder.map((qId, index) => {
          const currentQuestion = unsavedQuiz.questions[qId];

          if (currentQuestion) {
            if (!currentQuestion.questionType || currentQuestion.questionType === "") {
              errorMessages.push(
                `${localizations?.QUESTION ?? "Soru"} ${index + 1}: ${
                  localizations?.QUESTION_TYPE_ERROR ?? "Soru tipi seçilmemiştir."
                }`
              );
            }
            if (!currentQuestion.questionText || currentQuestion.questionText === "") {
              errorMessages.push(
                `${localizations?.QUESTION ?? "Soru"} ${index + 1}: ${
                  localizations?.QUESTION_TEXT_ERROR ?? "Soru metni boş olamaz."
                }`
              );
            }

            if (!currentQuestion.choices) {
              errorMessages.push(
                `${localizations?.QUESTION ?? "Soru"} ${index + 1}: ${
                  localizations?.QUESTION_NO_CHOICES_ERROR ??
                  "Herhangi bir cevap şıkkı tanımlanmamıştır."
                }`
              );
            } else if (
              Object.values(currentQuestion.choices).every((ch) => ch.isCorrect === false)
            ) {
              errorMessages.push(
                `${localizations?.QUESTION ?? "Soru"} ${index + 1}: ${
                  localizations?.QUESTION_CORRECT_ANSWER_ERROR ?? "Doğru cevap belirtilmemiştir."
                }`
              );
            }

            return {
              questionType: currentQuestion.questionType,
              text: currentQuestion.questionText,
              imageUrl: currentQuestion.questionImageUrl,
              videoUrl: currentQuestion.questionVideoUrl,
              audioUrl: currentQuestion.questionAudioUrl,
              questionOrder: index + 1,
              choices: currentQuestion.choiceOrder?.map((cId, i) => {
                const currentChoice = currentQuestion.choices[cId];
                if (currentChoice) {
                  if (
                    (!currentChoice.choiceText || currentChoice.choiceText === "") &&
                    (!currentChoice.choiceImageUrl || currentChoice.choiceImageUrl === "") &&
                    (!currentChoice.choiceAudioUrl || currentChoice.choiceAudioUrl === "")
                  ) {
                    errorMessages.push(
                      `${localizations?.QUESTION ?? "Soru"} ${index + 1} - ${
                        localizations?.CHOICE ?? "Şık"
                      } ${["A", "B", "C", "D"][i]}: ${
                        localizations?.CHOICE_NO_CONTENT_ERROR ??
                        "Herhangi bir içerik bulunmamaktadır."
                      }`
                    );
                  }
                  return {
                    text: currentChoice.choiceText,
                    imageUrl: currentChoice.choiceImageUrl,
                    audioUrl: currentChoice.choiceAudioUrl,
                    choiceOrder: i + 1,
                    isCorrectAnswer: currentChoice.isCorrect,
                  };
                }
                return {};
              }),
            };
          }

          return {};
        }),
      };

      if (errorMessages.length === 0) {
        saveQuizQuestions(prepareBody);
      } else {
        Swal.fire({
          icon: "error",
          title: localizations?.MISSING_INFO_ERROR_TITLE ?? "Eksik Bilgi",
          html: `
                  <p class="error-desc">
                    ${
                      localizations?.MISSING_INFO_ERROR_DESC ??
                      "Zorunlu alan girişleri eksik yapıldı. Lütfen aşağıda belirtilen eksiklikleri gidererek tekrar deneyiniz."
                    }
                  </p>
                  <ul class="message-error-list">
                    ${errorMessages
                      .map(
                        (errMessage) => `
                      <li class="message-error-item">* ${errMessage}</li>
                    `
                      )
                      .join("")}
                  </ul>
                `,
          showCancelButton: false,
          confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? "Tamam",
        }).then(() => {
          // Cancelled
        });
      }
    }
  };

  const onSaveQuestionsClick = (event) => {
    event.preventDefault();
    if (isInfoChanged) {
      const newSwalInstance = Swal.mixin({
        customClass: {
          confirmButton: "button button-dark",
          cancelButton: "button button-light",
        },
        buttonsStyling: false,
      });

      newSwalInstance
        .fire({
          icon: MessageTypes.error,
          title: localizations?.MESSAGE_WARNING_TITLE ?? "UYARI!",
          html: `
            <p class="justify">
              ${
                localizations?.QUIZ_GENERAL_INFO_WARNING_DESC ??
                "Quiz genel detaylarında değişiklik yapılmıştır. Bu değişiklileri kaybetmemek için bu işlemi iptal ediniz ve öncelikle quiz genel detaylarındaki <b>KAYDET</b> butonuna basınız."
              }
            </p>
            <p class="justify">
              ${
                localizations?.QUIZ_GO_ON_SAVING_QUESTIONS ??
                "Quiz Genel Detay'larındaki değişiklikleri kaydetmeden işleminize devam etmek istiyor musunuz?"
              }
            </p>
          `,
          showCancelButton: true,
          confirmButtonText: localizations?.MESSAGE_GO_ON_BTN ?? "Evet, devam et!",
          cancelButtonText: localizations?.MESSAGE_CANCEL_BTN ?? "Hayır, iptal et!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            onSaveQuestions();
          }
        });
    } else {
      onSaveQuestions();
    }
  };

  return (
    <Card sx={{ overflow: "visible", height: "100%" }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{localizations?.QUESTIONS ?? "Sorular"}</SuiTypography>
      </SuiBox>
      {unsavedQuiz?.questions ? (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <SuiBox p={3}>
            <QuestionsDroppable permittedDroppable={permittedDroppable} />
          </SuiBox>
        </DragDropContext>
      ) : null}
      <SuiBox p={3}>
        <div
          aria-hidden="true"
          onClick={(e) => {
            e.preventDefault();
            createInitialQuestion();
          }}
          style={{ cursor: "pointer" }}
        >
          <PlaceholderCard
            title={{ variant: "h5", text: localizations?.ADD_NEW_QUESTION_BTN ?? "Yeni Soru Ekle" }}
            outlined
          />
        </div>
      </SuiBox>
      <SuiBox display="flex" my={5}>
        {unsavedQuiz?.questions ? (
          <SuiBox pr={3} ml="auto">
            <SuiButton onClick={onSaveQuestionsClick} variant="contained" color="dark" size="small">
              {localizations?.SAVE_QUESTIONS_BTN ?? "SORULARI KAYDET"}
            </SuiButton>
          </SuiBox>
        ) : null}
      </SuiBox>
    </Card>
  );
};

QuestionContainer.defaultProps = {
  unsavedQuiz: null,
  isInfoChanged: false,
  localizations: null,
};

QuestionContainer.propTypes = {
  unsavedQuiz: PropTypes.objectOf(PropTypes.any),
  saveUnsavedData: PropTypes.func.isRequired,
  saveQuizQuestions: PropTypes.func.isRequired,
  isInfoChanged: PropTypes.bool,
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  unsavedQuiz: state.data.unsavedQuiz,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  saveUnsavedData: saveUnsavedQuiz,
  saveQuizQuestions: saveQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionContainer);
