// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

import { ErrorMessage, Field } from "formik";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";

function CustomFormField({ label, name, type, ...rest }) {
  if (type === "select") {
    const { setFieldValue, setFieldTouched, resets, ...others } = rest;
    return (
      <>
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </SuiTypography>
        </SuiBox>
        <Field
          {...others}
          tooltip="osman"
          name={name}
          onChange={(val) => {
            if (resets && resets.length > 0) {
              resets.forEach((r) => {
                setFieldValue(r.name, r.value);
                setFieldTouched(r.name, false);
              });
            }
            setFieldValue(name, val);
          }}
          onBlur={() => setFieldTouched(name, true)}
          as={SuiSelect}
        />
        <SuiBox mt={0.75}>
          <SuiTypography component="div" variant="caption" color="error">
            <ErrorMessage name={name} />
          </SuiTypography>
        </SuiBox>
      </>
    );
  }

  if (type === "switch") {
    const { value, setFieldValue, inForm } = rest;
    return (
      <>
        {inForm ? (
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </SuiTypography>
        ) : (
          <SuiTypography variant="button" fontWeight="regular" fontSize="0.75rem" lineHeight={1}>
            {label}
          </SuiTypography>
        )}
        <SuiBox mx={1}>
          <Field
            as={Switch}
            name={name}
            value={value}
            checked={value}
            onChange={(_, checked) => {
              setFieldValue(name, checked);
            }}
          />
        </SuiBox>
      </>
    );
  }

  if (type === "editor") {
    const { setFieldValue, setFieldTouched, ...restForEditor } = rest;
    return (
      <>
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </SuiTypography>
        </SuiBox>
        <Field
          as={SuiEditor}
          name={name}
          {...restForEditor}
          onChange={(e) => setFieldValue(name, e)}
          onBlur={() => setFieldTouched(name, true)}
        />
        {/* <ReactQuill value={values.description} onChange={(e) => setFieldValue("description", e)} /> */}
        {/* <Field {...rest} name={name}>
          {({ field }) => {
            // eslint-disable-next-line no-console
            console.log("field.value:", field.value);
            return <SuiEditor value={field.value} onChange={field.onChange(field.name)} />;
          }}
        </Field> */}
        <SuiBox mt={0.75}>
          <SuiTypography component="div" variant="caption" color="error">
            <ErrorMessage name={name} />
          </SuiTypography>
        </SuiBox>
      </>
    );
  }

  return (
    <>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <Field {...rest} name={name} type={type} as={SuiInput} />
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </>
  );
}

CustomFormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CustomFormField;
