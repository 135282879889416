/* eslint-disable react/prop-types */
import PacmanLoader from "react-spinners/PacmanLoader";
import { connect } from "react-redux";
import "./_custom-loading.css";

const Loading = ({ loading }) =>
  loading ? (
    <div className="loading whole-screen">
      <PacmanLoader color="#67BCEC" size={50} />
    </div>
  ) : null;

const mapStateToProps = (state) => ({
  loading: state.ui.loading,
});

export default connect(mapStateToProps, {})(Loading);
