import React from "react";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import { Switch } from "@mui/material";

const PublishCell = ({ data, handlePublishChange }) => (
  <SuiBox mx={1}>
    <Switch
      checked={data.checked}
      onChange={(e) => {
        handlePublishChange({ quizId: data.id, isPublished: e.target.checked });
      }}
    />
  </SuiBox>
);

PublishCell.defaultProps = {};

PublishCell.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  handlePublishChange: PropTypes.func.isRequired,
};

export default React.memo(
  PublishCell,
  (prevProps, nextProps) =>
    prevProps.data.checked === nextProps.data.checked &&
    prevProps.data.id === nextProps.data.id &&
    prevProps.handlePublishChange === nextProps.handlePublishChange
);
