import checkout from "layouts/book/components/PageInfo/schema/pageInfoForm";

const {
  formField: { imageStyle, textStyle, textContent },
} = checkout;

export default {
  [imageStyle.name]: null,
  [textStyle.name]: null,
  [textContent.name]: "",
};
