/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */

import { useState, useEffect } from "react";

// react-router-dom components
import { useHistory, Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";

// Redux
import { connect } from "react-redux";
import { startSigninPage, signin } from "redux/actions/pages/signin";
import Alert from "custom/Alert";
import Loading from "custom/Loading";

function Signin({ auth, /* notifications, loading, */ redirectTo, startScreen, signinAction }) {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  useEffect(() => {
    startScreen();
  }, [auth]);

  useEffect(() => {
    if (redirectTo && redirectTo !== "" && redirectTo !== "/login") {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const onSigninClick = (e) => {
    e.preventDefault();
    signinAction({ email, password });
  };

  return (
    <>
      <Alert />
      <Loading />
      <IllustrationLayout
        title="Giriş Yap"
        description="Lütfen giriş yapmak için kullanıcı adı ve şifrenizi giriniz."
        illustration={{
          image: chat,
          title: '"İletişim güçtür."',
          description:
            "İletişim güçtür, iletişimi etkili kullanabilenler, kendilerinin dünya deneyimlerini ve dünyanın onlar üzerindeki deneyimlerini değiştirebilirler.",
        }}
      >
        <SuiBox component="form" role="form">
          <SuiBox mb={2}>
            <SuiInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
            />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiInput
              type="password"
              placeholder="Şifre"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size="large"
            />
          </SuiBox>
          <SuiBox display="flex" alignItems="center">
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <SuiTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;Beni Hatırla
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              onClick={onSigninClick}
              size="large"
              fullWidth
            >
              GİRİŞ
            </SuiButton>
          </SuiBox>
          <SuiBox mt={3} textAlign="center">
            <SuiTypography variant="button" color="text" fontWeight="regular">
              Şifreni hatırlamıyor musun?{" "}
              <SuiTypography
                component={Link}
                to="/forgot-password"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Şifremi unuttum
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </IllustrationLayout>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  notifications: state.notifications,
  loading: state.loading,
  redirectTo: state.ui.redirectTo,
});

const mapDispatchToProps = {
  startScreen: startSigninPage,
  signinAction: signin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
