/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Formik, Form } from "formik";
import { connect } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";

// BookDetails page components
import BookTitle from "layouts/book/components/BookTitle";
import BookGeneralInfo from "layouts/book/components/BookGeneralInfo";
import BookPages from "layouts/book/components/BookPages";

import validations from "layouts/book/components/book-details/schemas/generalInfoFormValidations";
import form from "layouts/book/components/book-details/schemas/generalInfoForm";
import initialValues from "layouts/book/components/book-details/schemas/generalInfoFormInitialValues";

import {
  saveGeneralInfo,
  startBookFormScreen,
  closeBookFormScreen,
  getBookFormLookups,
  GET_BOOK_FORM_LOOKUPS,
} from "redux/actions/pages";
import { useEffect } from "react";
import Alert from "custom/Alert";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { removeMessage } from "redux/actions/message";
import MessageTypes from "enums/message-types";

function BookDetails({
  book,
  bookId,
  currentBookId,
  saveGeneralInformation,
  startScreen,
  closeScreen,
  getLookups,
  message,
  removeNotificationMsg,
}) {
  const { formId, formField } = form;

  const history = useHistory();

  useEffect(() => {
    if (bookId && bookId > 0) {
      startScreen({ bookId });
    }

    return () => {
      closeScreen();
    };
  }, [bookId]);

  useEffect(() => {
    getLookups();
  }, []);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title: message.messageType === MessageTypes.success ? "BAŞARILI!" : "HATA!",
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: `Anasayfaya dön`,
      }).then(() => {
        removeNotificationMsg(GET_BOOK_FORM_LOOKUPS);
        history.push("/");
      });
    }
    // eslint-disable-next-line
  }, [message]);

  const handleSubmit = (values, actions) => {
    const formattedValues = {
      ...values,
      author: values.author.map((v) => v.value).join(","),
      illustrator: values.illustrator.map((v) => v.value).join(","),
      translator: values.translator.map((v) => v.value).join(","),
      keywords: values.keywords.map((v) => v.value).join(","),
      grade: values.grade.map((v) => v.value).join(","),
      language: values.language?.value,
      series: values.series?.value,
      level: values.level?.value,
      category: values.category?.value,
      genre: values.genre?.value,
      subjectArea: values.subjectArea?.value,
      use: values.use?.value,
      publisher: values.publisher?.value,
    };

    // If there is a current book id, it is not create, it is update
    // so we have to send id
    if (currentBookId && currentBookId > 0) {
      formattedValues.id = currentBookId;
    }

    saveGeneralInformation(formattedValues);
    actions.setSubmitting(false);
    // actions.resetForm();
  };

  const getInitialValue = () => {
    if (bookId && bookId > 0) {
      return {
        generalInfo: book.generalInfo,
        author: book.author.split(",").map((a) => ({ value: a, label: a })) ?? [],
        illustrator:
          book.illustrator && book.illustrator !== ""
            ? book.illustrator.split(",").map((a) => ({ value: a, label: a }))
            : [],
        translator:
          book.translator && book.translator !== ""
            ? book.translator.split(",").map((a) => ({ value: a, label: a }))
            : [],
        language: book.language ? { value: book.language, label: book.language } : null,
        grade:
          book.grade && book.grade !== ""
            ? book.grade.split(",").map((a) => ({ value: a, label: a }))
            : [],
        series: book.series ? { value: book.series, label: book.series } : null,
        level: book.level ? { value: book.level, label: book.level } : null,
        orderInLevel: book.orderInLevel ?? 0,
        pageCount: book.pageCount ?? 0,
        wordCount: book.wordCount ?? 0,
        category: book.category ? { value: book.category, label: book.category } : null,
        genre: book.genre ? { value: book.genre, label: book.genre } : null,
        subjectArea: book.subjectArea ? { value: book.subjectArea, label: book.subjectArea } : null,
        keywords: book.keywords.split(",").map((a) => ({ value: a, label: a })) ?? [],
        use: book.use ? { value: book.use, label: book.use } : null,
        title: book.title ?? "",
        publisher: book.publisher ? { value: book.publisher, label: book.publisher } : null,
        isPublished: book.isPublished ?? false,
      };
    }

    return initialValues;
  };

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <Formik
          initialValues={getInitialValue()}
          validationSchema={validations.validation}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <>
              <Form id={formId} autoComplete="off">
                <SuiBox my={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                      <BookTitle
                        author={values.author.map((a) => a.label).join(", ")}
                        illustrator={values.illustrator.map((il) => il.label).join(", ")}
                        title={values.title}
                        cover={book?.pages?.find((p) => p.pageSequence === 1)?.image}
                        {...formField.isPublished}
                        value={values.isPublished}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Card sx={{ overflow: "visible" }}>
                        <SuiBox p={3}>
                          <BookGeneralInfo
                            formData={{
                              values,
                              touched,
                              formField,
                              setFieldValue,
                              setFieldTouched,
                              handleBlur,
                              handleChange,
                              errors,
                            }}
                          />
                          <SuiBox display="flex" my={5}>
                            <SuiBox ml="auto" mr={3}>
                              <SuiButton variant="contained" color="dark" size="small">
                                preview book
                              </SuiButton>
                            </SuiBox>
                            <SuiButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="dark"
                              size="small"
                            >
                              save general info
                            </SuiButton>
                          </SuiBox>
                        </SuiBox>
                      </Card>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Form>
            </>
          )}
        </Formik>
        {(currentBookId && currentBookId) > 0 ? (
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Card sx={{ overflow: "visible" }}>
                <SuiBox p={3}>
                  <BookPages />
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
        ) : null}
        <SuiBox mt={2}>
          <Footer />
        </SuiBox>
      </DashboardLayout>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  let book = null;
  if (ownProps.bookId && ownProps.bookId > 0) {
    book = state.data.books[ownProps.bookId];
  } else if (state.data.currentBookId && state.data.currentBookId > 0) {
    book = state.data.books[state.data.currentBookId];
  }
  return {
    book,
    currentBookId: state.data.currentBookId,
    lookups: state.data.lookups,
    message: state.message[GET_BOOK_FORM_LOOKUPS],
  };
};

const mapDispatchToProps = {
  saveGeneralInformation: saveGeneralInfo,
  startScreen: startBookFormScreen,
  closeScreen: closeBookFormScreen,
  getLookups: getBookFormLookups,
  removeNotificationMsg: removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookDetails);
