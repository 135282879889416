import React from "react";
import PropTypes from "prop-types";
import { Tooltip, IconButton } from "@mui/material";
import SuiButton from "components/SuiButton";
import { ArrowFatUp, Pause, Play, X } from "phosphor-react";
import { connect } from "react-redux";

const AudioUpload = ({
  audioUrl,
  uploadTitle,
  disabled,
  onFileInputChange,
  isPlaying,
  setIsPlaying,
  onRemoveAudioUrl,
  localizations,
}) => (
  <div>
    {!audioUrl || audioUrl === "" ? (
      <Tooltip title={uploadTitle} placement="left">
        <SuiButton
          disabled={disabled}
          variant="text"
          size="small"
          aria-label={uploadTitle}
          component="label"
          style={{
            color: "#7493b6",
            padding: 0,
          }}
        >
          <span style={{ textTransform: "none", marginRight: "3px" }}>{uploadTitle}</span>
          <ArrowFatUp size={20} weight="duotone" color="#7493b6" />
          <input name="file" type="file" accept="audio/*" hidden onChange={onFileInputChange} />
        </SuiButton>
      </Tooltip>
    ) : (
      <>
        {isPlaying ? (
          <Tooltip title={localizations?.PAUSE_TOOLTIP ?? "Durdur"} placement="left">
            <IconButton
              aria-label={localizations?.PAUSE_TOOLTIP ?? "Durdur"}
              onClick={(e) => {
                e.preventDefault();
                setIsPlaying(false);
              }}
            >
              <Pause size={20} weight="duotone" color="#7493b6" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={localizations?.PLAY_TOOLTIP ?? "Oynat"} placement="left">
            <IconButton
              aria-label={localizations?.PLAY_TOOLTIP ?? "Oynat"}
              onClick={(e) => {
                e.preventDefault();
                setIsPlaying(true);
              }}
            >
              <Play size={20} weight="duotone" color="#7493b6" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title={localizations?.REMOVE_AUDIO_TOOLTIP ?? "Ses dosyasını sil"}
          placement="bottom"
        >
          <IconButton
            aria-label={localizations?.REMOVE_AUDIO_TOOLTIP ?? "Ses dosyasını sil"}
            onClick={(e) => {
              e.preventDefault();
              onRemoveAudioUrl();
            }}
          >
            <X size={20} weight="duotone" color="#7493b6" />
          </IconButton>
        </Tooltip>
      </>
    )}
  </div>
);

AudioUpload.defaultProps = {
  audioUrl: "",
  uploadTitle: "",
  disabled: false,
  localizations: null,
};

AudioUpload.propTypes = {
  audioUrl: PropTypes.string,
  uploadTitle: PropTypes.string,
  disabled: PropTypes.bool,
  onFileInputChange: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  setIsPlaying: PropTypes.func.isRequired,
  onRemoveAudioUrl: PropTypes.func.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(AudioUpload);
