/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
import { apiRequest, API_SUCCESS, API_ERROR } from "redux/actions/api";
import {
  getFromStorage,
  removeFromStorage,
  FOUND_IN_STORAGE,
  NOT_FOUND_IN_STORAGE,
} from "redux/actions/storage";
import { setAuth, AUTH, START_AUTH } from "redux/actions/auth";
import {
  TOKEN_STORAGE_KEY,
  BASE_URL,
  PRODUCT_TYPE,
  MAIN_FRONTEND_URL,
  ACCOUNTS_DASHBOARD_URL,
} from "redux/actions/constants";
import { setRedirect, setLoader } from "redux/actions/ui";
import { setNotification } from "redux/actions/notifications";
import { setHeader } from "redux/actions/header";
import MessageTypes from "enums/message-types";
import { sendLog } from "redux/actions/log";
import SeverityTypes from "enums/severity-types";
import UserTypes from "enums/user-types";

// Constants
const CURRENT_USER_URL = `${BASE_URL}/api/user/current-user`;

// Auth Middleware implementation
export const authMiddleware = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case START_AUTH:
      next(getFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: AUTH }));
      break;
    case `${AUTH} ${FOUND_IN_STORAGE}`:
      next(setLoader({ state: true, feature: AUTH }));
      next(
        apiRequest({
          body: null,
          method: "GET",
          url: CURRENT_USER_URL,
          feature: AUTH,
          token: action.payload,
        })
      );
      break;
    case `${AUTH} ${NOT_FOUND_IN_STORAGE}`:
      break;
    case `${AUTH} ${API_SUCCESS}`:
      // const userType = action.payload.currentUser?.userType;
      // if (userType === UserTypes.other || userType === UserTypes.demoOther) {
      //   window.location.href = ACCOUNTS_DASHBOARD_URL;
      // } else if (userType === UserTypes.student || userType === UserTypes.demoStudent) {
      //   window.location.href = MAIN_FRONTEND_URL;
      // }

      next(setLoader({ state: false, feature: AUTH }));
      const userType = action.payload.currentUser?.userType;
      if (userType !== UserTypes.admin) {
        next(setRedirect({ to: "/error403", feature: AUTH }));
        next(removeFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: AUTH }));
      } else {
        next(setAuth({ auth: action.payload }));
        next(
          setHeader({
            header: {
              organization: action.payload.currentUser?.organization?.id,
              suborganization: action.payload.currentUser?.suborganizations[0]?.id,
            },
          })
        );
      }
      break;
    case `${AUTH} ${API_ERROR}`:
      next(setLoader({ state: false, feature: AUTH }));
      if (action.payload.errors) {
        action.payload.errors.forEach((error) => {
          next(
            setNotification({ type: MessageTypes.error, message: error.message, feature: AUTH })
          );
        });
      } else {
        // TODO: Sonraki satır test edilecektir.
        next(setNotification({ type: MessageTypes.error, message: action.payload, feature: AUTH }));
      }
      next(removeFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: AUTH }));
      next(
        sendLog({
          product: PRODUCT_TYPE,
          title: `${AUTH} ${API_ERROR}`,
          detail: action.payload,
          severity: SeverityTypes.major,
        })
      );
      break;
    default:
      break;
  }
};
