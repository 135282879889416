import * as Yup from "yup";
import checkout from "layouts/quiz/components/quiz-details/schemas/quizDetailsForm";

const {
  formField: { book, quizType, duration, quizGrade, quizLevel, quizOrder },
} = checkout;

export default {
  validation: Yup.object().shape({
    // [book.name]: Yup.object()
    //   .shape({ label: Yup.string(), value: Yup.string() })
    //   .required(book.errorMsg),
    [book.name]: Yup.object().nullable().required(book.errorMsg),
    [quizType.name]: Yup.object().nullable().required(quizType.errorMsg),
    [duration.name]: Yup.number().min(1, duration.errorMsg),
    [quizGrade.name]: Yup.object().nullable().required(quizGrade.errorMsg),
    [quizLevel.name]: Yup.object().nullable().required(quizLevel.errorMsg),
    [quizOrder.name]: Yup.number().min(1, quizOrder.errorMsg),
  }),
};
