/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "custom/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
// import allRoutes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav } from "context";

// Images
// import brand from "assets/images/logo-ct.png";
import Default from "layouts/dashboards/default";
import CreatePassword from "layouts/authentication/create-password";
import ForgotPassword from "layouts/authentication/forgot-password";
import Signin from "layouts/authentication/sign-in";
import Signup from "layouts/authentication/sign-up";
import Error from "layouts/error";
import Error403 from "layouts/error/error403";
import Home from "layouts/home";
import { connect } from "react-redux";

// REDUX
import { startAuth } from "redux/actions/auth";

import { screenComponentMapping } from "enums/screenComponentMapping";
import Shield from "custom/icons/Shield";
import ErrorBoundary from "ErrorBoundary";
import { startLocalization } from "redux/actions/localization";
import { iconMapper } from "mappers/icon.mapper";

const App = ({
  startApplication,
  currentUser,
  organizationId,
  getLocalizations,
  localizations,
}) => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // const { routes, others } = allRoutes;

  useEffect(() => {
    startApplication();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getLocalizations();
    // eslint-disable-next-line
  }, [organizationId]);

  // useEffect(() => {
  //   if (currentUser && currentUser.id && currentUser.id > 0) {
  //   }
  // }, [currentUser]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRouteList = (userDetails, isForSidenav) => {
    // TODO: LOCALIZATION ile EKRANDA GÖRÜLEN KISIMLAR KEY yerine VALUE koyulacaktır.
    if (userDetails && userDetails.id && userDetails.id > 0) {
      const responsibilityRoutes = userDetails.responsibilities.map((res) => {
        let screens;
        if (isForSidenav) {
          screens = res.screens
            .filter((s) => s.sidenavVisible)
            .map((sc) => ({
              id: sc.id,
              name: localizations && (localizations[sc.screenKey] ?? sc.screenKey),
              key: sc.screenKey,
              route: `${sc.screenPath}`,
              component: screenComponentMapping[sc.screenKey],
            }));
        } else {
          screens = res.screens.map((sc) => ({
            id: sc.id,
            name: localizations && (localizations[sc.screenKey] ?? sc.screenKey),
            key: sc.screenKey,
            route: `${sc.screenPath}`,
            component: screenComponentMapping[sc.screenKey],
          }));
        }

        return {
          id: res.id,
          type: "collapse",
          name: localizations && (localizations[res.responsibilityKey] ?? res.responsibilityKey),
          key: res.responsibilityKey.toLowerCase().replace(/_/g, "-"),
          icon: iconMapper[res.icon]({ weight: "duotone", size: 16, color: "#344767" }) ?? (
            <Shield size="16px" />
          ),
          collapse: screens,
        };
      });
      return [
        { type: "title", title: localizations?.RESPONSIBILITIES, key: "title-responsibilities" },
        ...responsibilityRoutes,
      ];
    }
    return [];
  };

  const getRoutes = (routeList) =>
    routeList.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          routes={getRouteList(currentUser, true)}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <ErrorBoundary>
        <Switch>
          <Route exact path="/forgot-password" component={ForgotPassword} key="forgot-password" />
          <Route exact path="/create-password" component={CreatePassword} key="create-password" />
          <Route exact path="/signin" component={Signin} key="signin" />
          <Route exact path="/signup" component={Signup} key="signup" />
          <Route exact path="/dashboards/default" component={Default} key="default" />
          <Route exact path="/home" component={Home} key="home" />
          <Route exact path="/error" component={Error} key="error" />
          <Route exact path="/error403" component={Error403} key="error403" />
          {getRoutes(getRouteList(currentUser, false))}
          {/* {getRoutes(others)} */}
          <Redirect from="*" to="/signin" />
        </Switch>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  organizationId: state.header.organization,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = { startApplication: startAuth, getLocalizations: startLocalization };

export default connect(mapStateToProps, mapDispatchToProps)(App);
