import { PAGES } from "redux/actions/pages";

// feature name
export const NAVBAR = `${PAGES} [Navbar]`;

// action types
export const SIGNOUT = `${NAVBAR} SIGNOUT`;

// action creators
export const signout = ({ email }) => ({
  type: SIGNOUT,
  payload: { email },
});
