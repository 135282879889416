/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Hand, Trash, Pencil, X, Check } from "phosphor-react";
import SuiBox from "components/SuiBox";
import pxToRem from "assets/theme/functions/pxToRem";
import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { saveUnsavedQuiz } from "redux/actions/pages/quiz";
import "./_choice-draggable.css";
import ChoiceEditDialog from "../ChoiceEditDialog";

const ChoiceDraggable = ({
  questionId,
  choiceId,
  index,
  saveUnsavedData,
  unsavedQuiz,
  localizations,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const removeChoice = (e) => {
    e.preventDefault();
    const updatedUnsavedQuiz = {
      ...unsavedQuiz,
      questions: {
        ...unsavedQuiz?.questions,
        [questionId]: {
          ...unsavedQuiz?.questions[questionId],
          choiceOrder: unsavedQuiz?.questions[questionId]?.choiceOrder?.filter(
            (order) => order !== choiceId
          ),
        },
      },
    };

    delete updatedUnsavedQuiz?.questions[questionId]?.choices[choiceId];
    saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
  };

  const checkChoice = (e) => {
    e.preventDefault();

    const currentChoice = unsavedQuiz?.questions[questionId]?.choices[choiceId];
    if (currentChoice && !currentChoice.isCorrect) {
      let updatedChoices = { ...unsavedQuiz.questions[questionId].choices };

      unsavedQuiz.questions[questionId].choiceOrder?.forEach((cId) => {
        updatedChoices = {
          ...updatedChoices,
          [cId]: {
            ...updatedChoices[cId],
            isCorrect: cId === choiceId,
          },
        };
      });
      const updatedUnsavedQuiz = {
        ...unsavedQuiz,
        questions: {
          ...unsavedQuiz.questions,
          [questionId]: {
            ...unsavedQuiz.questions[questionId],
            choices: updatedChoices,
          },
        },
      };
      saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
    }
  };

  const onChoiceTextChange = (e) => {
    const updatedUnsavedQuiz = {
      ...unsavedQuiz,
      questions: {
        ...unsavedQuiz?.questions,
        [questionId]: {
          ...unsavedQuiz?.questions[questionId],
          choices: {
            ...unsavedQuiz?.questions[questionId]?.choices,
            [choiceId]: {
              ...unsavedQuiz?.questions[questionId]?.choices[choiceId],
              choiceText: e.target.value,
            },
          },
        },
      },
    };
    saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
  };

  return (
    <>
      <Draggable draggableId={choiceId.toString()} index={index}>
        {(provided, snapshot) => (
          <Card
            {...provided.draggableProps}
            ref={provided.innerRef}
            raised
            sx={({ borders: { borderWidth, borderColor } }) => ({
              backgroundColor: "transparent",
              boxShadow: "none",
              border: `${borderWidth[1]} solid ${borderColor}`,
              borderRadius: "8px",
              marginBottom: pxToRem(5),
            })}
          >
            <SuiBox display="flex" alignItems="stretch">
              <div
                {...provided.dragHandleProps}
                style={{
                  minWidth: "35px",
                  height: "100%",
                  backgroundColor: "rgba(23, 75, 134, 0.6)",
                  display: "block",
                  textAlign: "center",
                }}
              >
                <Hand size={15} color="#ffffff" weight="duotone" />
              </div>
              <Tooltip
                title={
                  unsavedQuiz?.questions[questionId]?.choices[choiceId]?.isCorrect
                    ? ""
                    : localizations?.MARK_AS_CORRECT_ANSWER ?? "Doğru Cevap Olarak İşaretle"
                }
                placement="top"
              >
                <div
                  onClick={checkChoice}
                  style={{
                    minWidth: "35px",
                    height: "100%",
                    backgroundColor: "#f7f9fb",
                    display: "block",
                    textAlign: "center",
                    cursor: unsavedQuiz?.questions[questionId]?.choices[choiceId]?.isCorrect
                      ? "default"
                      : "pointer",
                  }}
                  aria-hidden
                >
                  {unsavedQuiz?.questions[questionId]?.choices[choiceId]?.isCorrect ? (
                    <Check size={15} color="#7493B6" weight="duotone" />
                  ) : (
                    <X size={15} color="#7493B6" weight="duotone" />
                  )}
                </div>
              </Tooltip>
              <div
                className="choice-input-container"
                style={{
                  backgroundColor: snapshot.isDragging ? "#EBF4FF" : "white",
                }}
              >
                <input
                  type="text"
                  name="choiceText"
                  value={unsavedQuiz?.questions[questionId]?.choices[choiceId]?.choiceText}
                  onChange={onChoiceTextChange}
                />
              </div>
              <div
                style={{
                  minWidth: "35px",
                  height: "100%",
                  backgroundColor: "rgba(252, 165, 2, 0.6)",
                  display: "block",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => setIsModalOpen(true)}
                aria-hidden="true"
              >
                <Pencil size={15} color="#ffffff" weight="duotone" />
              </div>
              <div
                style={{
                  minWidth: "35px",
                  height: "100%",
                  backgroundColor: "rgba(223, 40, 40, 0.6)",
                  display: "block",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={removeChoice}
                aria-hidden="true"
              >
                <Trash size={15} color="#ffffff" weight="duotone" />
              </div>
            </SuiBox>
          </Card>
        )}
      </Draggable>
      <ChoiceEditDialog
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        choice={unsavedQuiz?.questions[questionId]?.choices[choiceId]}
        questionId={questionId}
      />
    </>
  );
};

ChoiceDraggable.defaultProps = {
  unsavedQuiz: null,
  localizations: null,
};

ChoiceDraggable.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  choiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  unsavedQuiz: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  saveUnsavedData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  unsavedQuiz: state.data.unsavedQuiz,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  saveUnsavedData: saveUnsavedQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceDraggable);
