import React from "react";
import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import { Tooltip } from "@mui/material";
import { ArrowFatUp, X } from "phosphor-react";
import "./_image-upload.css";
import { connect } from "react-redux";

const ImageUpload = ({
  disabled,
  imageUrl,
  onImageChange,
  size,
  removeBtnRight,
  onImageRemove,
  localizations,
}) => (
  <div className="media-container">
    <SuiButton
      variant="text"
      disabled={disabled}
      size="small"
      aria-label={localizations?.UPLOAD_IMAGE ?? "Resim Yükle"}
      component="label"
      style={{ padding: 0 }}
    >
      <SuiBox
        className="image-container"
        bgColor="#f1f3f4"
        style={{
          height: `${size}px`,
          width: `${size}px`,
        }}
        width={
          size
            ? `${size}px`
            : {
                lg: "468px",
                md: "368px",
                sm: "268px",
                xs: "168px",
              }
        }
        height={
          size
            ? `${size}px`
            : {
                lg: "468px",
                md: "368px",
                sm: "268px",
                xs: "168px",
              }
        }
      >
        {imageUrl && imageUrl !== "" ? (
          <img src={imageUrl} alt={localizations?.QUESTION_IMAGE ?? "Soru Resmi"} />
        ) : (
          <div className="upload-image-placeholder">
            <ArrowFatUp size={size ? size / 2 : 100} weight="duotone" color="#7493b6" />
            <span>{localizations?.UPLOAD_IMAGE ?? "Resim Yükle"}</span>
          </div>
        )}
      </SuiBox>
      <input name="file" type="file" accept="image/*" hidden onChange={onImageChange} />
    </SuiButton>
    {imageUrl && imageUrl !== "" ? (
      <Tooltip title={localizations?.REMOVE_IMAGE ?? "Resmi Kaldır"} placement="top">
        <div
          onClick={onImageRemove}
          aria-hidden
          className="image-remove"
          style={{
            top: 0,
            right: removeBtnRight ?? 0,
            cursor: onImageRemove ? "pointer" : "default",
          }}
        >
          <X weight="duotone" size={16} color="#E3E9F0" />
        </div>
      </Tooltip>
    ) : null}
  </div>
);

ImageUpload.defaultProps = {
  disabled: false,
  imageUrl: "",
  size: null,
  removeBtnRight: null,
  onImageRemove: null,
  localizations: null,
};

ImageUpload.propTypes = {
  disabled: PropTypes.bool,
  imageUrl: PropTypes.string,
  onImageChange: PropTypes.func.isRequired,
  onImageRemove: PropTypes.func,
  size: PropTypes.number,
  removeBtnRight: PropTypes.string,
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(ImageUpload);
