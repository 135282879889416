/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import CustomFormField from "components/CustomFormField";
import { connect } from "react-redux";

// LOOKUP KEYS
import {
  AUTHORS,
  ILLUSTRATORS,
  TRANSLATORS,
  LANGUAGES,
  GRADES,
  SERIES,
  LEVELS,
  CATEGORIES,
  GENRES,
  KEYWORDS,
  SUBJECT_AREAS,
  USES,
  PUBLISHERS,
} from "redux/actions/constants";

// eslint-disable-next-line no-unused-vars
function BookGeneralInfo({ formData, lookups }) {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
  } = formData;
  const {
    generalInfo: { errorMsg: _gi, invalidMsg: __gi, ...generalInfoRest },
    title: { errorMsg: _ti, ...titleRest },
    publisher: { errorMsg: _pub, ...publisherRest },
    author: { errorMsg: _au, ...authorRest },
    illustrator,
    translator,
    language: { errorMsg: _lng, ...languageRest },
    grade,
    series: { errorMsg: _srs, ...seriesRest },
    level: { errorMsg: _lvl, ...levelRest },
    orderInLevel: { errorMsg: _oil, ...orderInLevelRest },
    pageCount: { errorMsg: _pg, invalidMsg: __pg, ...pageCountRest },
    wordCount: { errorMsg: _wc, ...wordCountRest },
    category: { errorMsg: _ctg, ...categoryRest },
    genre: { errorMsg: _gnr, ...genreRest },
    subjectArea: { errorMsg: _sa, ...subjectAreaRest },
    keywords: { errorMsg: _kws, ...keywordsRest },
    use: { errorMsg: _use, ...useRest },
  } = formField;

  const {
    generalInfo: generalInfoV,
    author: authorV,
    illustrator: illustratorV,
    translator: translatorV,
    language: languageV,
    grade: gradeV,
    series: seriesV,
    level: levelV,
    orderInLevel: orderInLevelV,
    pageCount: pageCountV,
    wordCount: wordCountV,
    category: categoryV,
    genre: genreV,
    subjectArea: subjectAreaV,
    keywords: keywordsV,
    use: useV,
    title: titleV,
    publisher: publisherV,
  } = values;
  return (
    <>
      <SuiTypography variant="h5">General Info</SuiTypography>
      <SuiBox mt={1} mb={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...titleRest}
              value={titleV}
              error={errors.title?.length > 0 && touched.title}
              success={titleV.length > 0 && !errors.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...authorRest}
              options={lookups.l_authors?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={authorV}
              error={
                errors.author?.length > 0 &&
                touched.author !== undefined &&
                touched.author !== false
              }
              success={authorV.length > 0 && !errors.author}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...pageCountRest}
              value={pageCountV}
              error={errors.pageCount?.length > 0 && !(touched.pageCount !== true)}
              success={pageCountV > 0 && pageCountV % 2 === 0 && !errors.pageCount}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...wordCountRest}
              value={wordCountV}
              error={errors.wordCount?.length > 0 && !(touched.wordCount !== true)}
              success={wordCountV > 0 && !errors.wordCount}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...illustrator}
              options={lookups.l_illustrators?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={illustratorV}
              error={errors.illustrator?.length > 0 && !(touched.illustrator !== true)}
              success={illustratorV.length > 0 && !errors.illustrator}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...publisherRest}
              options={lookups.l_publishers?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={publisherV}
              error={errors.publisher?.length > 0 && !(touched.publisher !== true)}
              success={publisherV && !errors.publisher}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...grade}
              options={lookups.l_grades?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={gradeV}
              error={errors.grade?.length > 0 && !(touched.grade !== true)}
              success={gradeV.length > 0 && !errors.grade}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...seriesRest}
              options={lookups.l_series?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={seriesV}
              error={errors.series?.length > 0 && !(touched.series !== true)}
              success={seriesV && !errors.series}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...translator}
              options={lookups.l_translators?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={translatorV}
              error={errors.translator?.length > 0 && !(touched.translator !== true)}
              success={translatorV.length > 0 && !errors.translator}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...languageRest}
              options={lookups.l_languages?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={languageV}
              error={errors.language?.length > 0 && !(touched.language !== true)}
              success={languageV && !errors.language}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...levelRest}
              options={lookups.l_levels?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={levelV}
              error={errors.level?.length > 0 && !(touched.level !== true)}
              success={levelV && !errors.level}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormField
              {...orderInLevelRest}
              value={orderInLevelV}
              error={errors.orderInLevel?.length > 0 && !(touched.orderInLevel !== true)}
              success={orderInLevelV > 0 && !errors.orderInLevel}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CustomFormField
              {...generalInfoRest}
              value={generalInfoV}
              error={errors.generalInfo?.length > 0 && touched.generalInfo}
              success={generalInfoV.length > 0 && generalInfoV.length <= 400 && !errors.generalInfo}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <CustomFormField
                  {...useRest}
                  options={lookups.l_uses?.lookupValues?.map((lv) => ({
                    value: lv.lookupValueKey,
                    label: lv.lookupValueKey,
                  }))}
                  value={useV}
                  error={errors.use?.length > 0 && !(touched.use !== true)}
                  success={useV && !errors.use}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormField
                  {...categoryRest}
                  options={lookups.l_categories?.lookupValues?.map((lv) => ({
                    value: lv.lookupValueKey,
                    label: lv.lookupValueKey,
                  }))}
                  value={categoryV}
                  error={errors.category?.length > 0 && !(touched.category !== true)}
                  success={categoryV && !errors.category}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormField
                  {...genreRest}
                  options={lookups.l_genres?.lookupValues?.map((lv) => ({
                    value: lv.lookupValueKey,
                    label: lv.lookupValueKey,
                  }))}
                  value={genreV}
                  error={errors.genre?.length > 0 && !(touched.genre !== true)}
                  success={genreV && !errors.genre}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormField
                  {...subjectAreaRest}
                  options={lookups.l_subjectAreas?.lookupValues?.map((lv) => ({
                    value: lv.lookupValueKey,
                    label: lv.lookupValueKey,
                  }))}
                  value={subjectAreaV}
                  error={errors.subjectArea?.length > 0 && !(touched.subjectArea !== true)}
                  success={subjectAreaV && !errors.subjectArea}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <CustomFormField
              {...keywordsRest}
              options={lookups.l_keywords?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: lv.lookupValueKey,
              }))}
              value={keywordsV}
              error={
                errors.keywords?.length > 0 &&
                touched.keywords !== undefined &&
                touched.keywords !== false
              }
              success={keywordsV.length > 0 && !errors.keywords}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
}

BookGeneralInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

const mapStateToProps = (state) => {
  let lookups = {};
  if (state.data.lookups && Object.keys(state.data.lookups).length !== 0) {
    lookups = {
      l_authors: state.data.lookups[AUTHORS],
      l_illustrators: state.data.lookups[ILLUSTRATORS],
      l_translators: state.data.lookups[TRANSLATORS],
      l_languages: state.data.lookups[LANGUAGES],
      l_grades: state.data.lookups[GRADES],
      l_series: state.data.lookups[SERIES],
      l_levels: state.data.lookups[LEVELS],
      l_categories: state.data.lookups[CATEGORIES],
      l_genres: state.data.lookups[GENRES],
      l_keywords: state.data.lookups[KEYWORDS],
      l_subjectAreas: state.data.lookups[SUBJECT_AREAS],
      l_uses: state.data.lookups[USES],
      l_publishers: state.data.lookups[PUBLISHERS],
    };
  }

  return { lookups };
};

export default connect(mapStateToProps, null)(BookGeneralInfo);
