/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Draggable } from "react-beautiful-dnd";
import axios from "axios";
import ImageKit from "imagekit-javascript";
import { Hand, Trash, ListPlus } from "phosphor-react";
import { v4 as uuidv4 } from "uuid";
import { Howl } from "howler";
import Tooltip from "@mui/material/Tooltip";

import { saveUnsavedQuiz } from "redux/actions/pages/quiz";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import pxToRem from "assets/theme/functions/pxToRem";
import ChoiceDroppable from "../ChoiceDroppable";
import AudioUpload from "../AudioUpload";
import ImageUpload from "../ImageUpload";

import "./_question-draggable.css";

const QuestionDraggable = ({
  questionId,
  index,
  unsavedQuiz,
  saveUnsavedData,
  permittedDroppable,
  localizations,
}) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [soundUploadLoading, setSoundUploadLoading] = useState(false);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    if (
      unsavedQuiz?.questions[questionId]?.questionAudioUrl &&
      unsavedQuiz?.questions[questionId]?.questionAudioUrl !== ""
    ) {
      if (audio) {
        audio.unload();
        if (isAudioPlaying) {
          setIsAudioPlaying(false);
        }
      }

      const sound = new Howl({
        src: unsavedQuiz?.questions[questionId]?.questionAudioUrl,
      });

      sound.on("end", () => {
        setIsAudioPlaying(false);
      });

      setAudio(sound);
    }
  }, [unsavedQuiz?.questions[questionId]?.questionAudioUrl]);

  useEffect(() => {
    if (isAudioPlaying) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [isAudioPlaying, audio]);

  const createInitialChoice = () => {
    if (unsavedQuiz?.questions[questionId]?.choiceOrder.length > 3) {
      return;
    }
    const id = uuidv4();
    const newInitialChoice = {
      [id]: {
        choiceId: id,
        choiceText: "",
        choiceImageUrl: "",
        choiceAudioUrl: "",
        isCorrect: false,
      },
    };

    const newUnsavedQuiz = {
      ...unsavedQuiz,
      questions: {
        ...unsavedQuiz?.questions,
        [questionId]: {
          ...unsavedQuiz?.questions[questionId],
          choices: {
            ...unsavedQuiz?.questions[questionId]?.choices,
            ...newInitialChoice,
          },
          choiceOrder:
            unsavedQuiz &&
            unsavedQuiz.questions &&
            unsavedQuiz.questions[questionId] &&
            unsavedQuiz.questions[questionId].choiceOrder
              ? [...unsavedQuiz.questions[questionId].choiceOrder, id]
              : [id],
        },
      },
    };

    saveUnsavedData({ unsavedQuiz: newUnsavedQuiz });
  };

  const removeQuestion = (event) => {
    event.preventDefault();

    if (unsavedQuiz) {
      const updatedUnsavedQuiz = {
        ...unsavedQuiz,
        questionOrder: unsavedQuiz.questionOrder?.filter((order) => order !== questionId),
      };

      delete updatedUnsavedQuiz.questions[questionId];
      saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
    }
  };

  const onQuestionTextChange = (e) => {
    const updatedUnsavedQuiz = {
      ...unsavedQuiz,
      questions: {
        ...unsavedQuiz?.questions,
        [questionId]: {
          ...unsavedQuiz?.questions[questionId],
          questionText: e.target.value,
        },
      },
    };
    saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
  };

  const onQuestionSoundFileChange = (url) => {
    const updatedUnsavedQuiz = {
      ...unsavedQuiz,
      questions: {
        ...unsavedQuiz?.questions,
        [questionId]: {
          ...unsavedQuiz?.questions[questionId],
          questionAudioUrl: url,
        },
      },
    };
    saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
  };

  const onQuestionImageFileChange = (url) => {
    const updatedUnsavedQuiz = {
      ...unsavedQuiz,
      questions: {
        ...unsavedQuiz?.questions,
        [questionId]: {
          ...unsavedQuiz?.questions[questionId],
          questionImageUrl: url,
        },
      },
    };
    saveUnsavedData({ unsavedQuiz: updatedUnsavedQuiz });
  };

  const onFileInputChange = (event, isImage = true) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      if (isImage) {
        setImageUploadLoading(true);
      } else {
        setSoundUploadLoading(true);
      }

      const file = files[0];

      if (isImage) {
        const imagekit = new ImageKit({
          publicKey: "public_qaLsRdm60abMeMnCPrq6XYAc0Gw=",
          urlEndpoint: "https://ik.imagekit.io/mvnw0kw1mga",
          authenticationEndpoint: "https://okumaplatformu.com/api/upload/imagekit-endpoint",
        });

        imagekit.upload(
          {
            file,
            fileName: file.name,
          },
          (err, result) => {
            if (err) {
              // eslint-disable-next-line no-console
              console.log("Error on imagekit.upload");
              // eslint-disable-next-line no-console
              console.log(err);
              return;
            }
            // setImageUrl(result.url);
            onQuestionImageFileChange(result.url);
            setImageUploadLoading(false);
          }
        );
      } else {
        const fileNameArray = file.name.split(".");
        let extention;
        if (fileNameArray && fileNameArray.length > 1) {
          extention = fileNameArray[fileNameArray.length - 1];
        }
        const folderName = "quizsoundfiles";

        axios
          .get(
            `https://okumaplatformu.com/api/upload/get-do-presigned-url?type=audio/mpeg&folder=${folderName}&extention=${extention}`
          )
          .then((res) => {
            const { key, url } = res.data;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", `${url}`);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.setRequestHeader("x-amz-acl", "public-read");
            xhr.send(file);
            // setSoundUploadLoading(true);

            xhr.onload = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  onQuestionSoundFileChange(
                    `https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`
                  );
                } else {
                  // eslint-disable-next-line no-console
                  console.error(xhr.statusText);
                }
              }
            };

            xhr.onerror = () => {
              // eslint-disable-next-line no-console
              console.error("on sound upload error");
              // eslint-disable-next-line no-console
              console.error(xhr.statusText);
            };

            xhr.onloadend = () => {
              setSoundUploadLoading(false);
            };
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }
  };

  return (
    <>
      <Draggable draggableId={questionId.toString()} index={index}>
        {(provided, snapshot) => (
          <Card
            {...provided.draggableProps}
            ref={provided.innerRef}
            raised
            sx={({ borders: { borderWidth, borderColor } }) => ({
              height: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: `${borderWidth[1]} solid ${borderColor}`,
              marginBottom: pxToRem(10),
            })}
          >
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" height="100%">
              <div
                {...provided.dragHandleProps}
                style={{
                  minWidth: "60px",
                  height: "100%",
                  backgroundColor: "rgba(23, 75, 134, 0.6)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Hand size={30} color="#ffffff" weight="duotone" />
              </div>

              <div
                style={{
                  flex: 1,
                  minHeight: "100%",
                  backgroundColor: snapshot.isDragging ? "#EBF4FF" : "white",
                }}
              >
                <SuiBox p={1}>
                  <Grid container spacing={1}>
                    <Grid item sm={12} lg={7}>
                      <Grid container spacing={1}>
                        <Grid item xs={8.5} lg={8.5}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <SuiTypography variant="caption" fontWeight="bold">{`${
                              localizations?.QUESTION ?? "Soru"
                            }: ${
                              unsavedQuiz?.questionOrder?.findIndex((q) => q === questionId) + 1
                            }`}</SuiTypography>
                            <AudioUpload
                              audioUrl={unsavedQuiz?.questions[questionId]?.questionAudioUrl}
                              uploadTitle={localizations?.AUDIO_UPLOAD_TITLE ?? "Ses Yükle"}
                              disabled={soundUploadLoading}
                              onFileInputChange={(e) => onFileInputChange(e, false)}
                              isPlaying={isAudioPlaying}
                              setIsPlaying={setIsAudioPlaying}
                              onRemoveAudioUrl={() => {
                                onQuestionSoundFileChange("");
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3.5} lg={3.5}>
                          &nbsp;
                        </Grid>
                        <Grid item xs={12} lg={8.5}>
                          <SuiInput
                            name="questionText"
                            type="text"
                            multiline
                            rows={4}
                            placeholder={localizations?.QUESTION_TEXT ?? "Soru Metni"}
                            value={unsavedQuiz?.questions[questionId]?.questionText}
                            onChange={onQuestionTextChange}
                          />
                        </Grid>
                        <Grid item xs={12} lg={3.5}>
                          <ImageUpload
                            disabled={imageUploadLoading}
                            imageUrl={unsavedQuiz?.questions[questionId]?.questionImageUrl}
                            onImageChange={(e) => onFileInputChange(e, true)}
                            size={100}
                            removeBtnRight="18px"
                            onImageRemove={() => onQuestionImageFileChange("")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} lg={5}>
                      <SuiBox mb={0} ml={0} lineHeight={0} display="block">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {localizations?.CHOICES ?? "Şıklar"}
                          </SuiTypography>
                          <Tooltip
                            title={localizations?.ADD_NEW_CHOICE_PLACEHOLDER ?? "Yeni Şık Ekle"}
                            placement="left"
                          >
                            <IconButton
                              aria-label="more"
                              onClick={(e) => {
                                e.preventDefault();
                                createInitialChoice();
                              }}
                            >
                              <ListPlus weight="duotone" color="#7493b6" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </SuiBox>
                      <ChoiceDroppable
                        questionId={questionId}
                        isDropDisabled={permittedDroppable !== questionId}
                      />
                    </Grid>
                  </Grid>
                </SuiBox>
              </div>
              <div
                style={{
                  minWidth: "60px",
                  height: "100%",
                  backgroundColor: "rgba(223, 40, 40, 0.6)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={removeQuestion}
                aria-hidden="true"
              >
                <Trash size={30} color="#ffffff" weight="duotone" />
              </div>
            </SuiBox>
          </Card>
        )}
      </Draggable>
    </>
  );
};

QuestionDraggable.defaultProps = {
  unsavedQuiz: null,
  localizations: null,
  permittedDroppable: "",
};

QuestionDraggable.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  unsavedQuiz: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  saveUnsavedData: PropTypes.func.isRequired,
  permittedDroppable: PropTypes.string,
};

const mapStateToProps = (state) => ({
  unsavedQuiz: state.data.unsavedQuiz,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  saveUnsavedData: saveUnsavedQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDraggable);
