import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Alert from "custom/Alert";
import SuiBox from "components/SuiBox";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import MaterialDetails from "../components/material-details";

const EditMaterial = ({ material, redirectTo }) => {
  const history = useHistory();

  useEffect(() => {
    if (redirectTo && redirectTo !== "" && redirectTo !== "/material/edit") {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox mt={3} mb={8}>
          <MaterialDetails material={material} />
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

EditMaterial.defaultProps = {
  material: null,
  redirectTo: "",
};

EditMaterial.propTypes = {
  material: PropTypes.objectOf(PropTypes.any),
  redirectTo: PropTypes.string,
};

const mapStateToProps = (state, { location }) => ({
  material: state.data.materials[location.state?.materialId],
  redirectTo: state.ui.redirectTo,
});

export default connect(mapStateToProps, null)(EditMaterial);
