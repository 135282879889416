/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "general-info-form",
  formField: {
    generalInfo: {
      name: "generalInfo",
      label: "General Info",
      type: "text",
      multiline: true,
      rows: 8,
      placeholder: "Informatioan about book. Text only and character limit: 400",
      errorMsg: "General information is required.",
      invalidMsg: "General information can be up to 400 chars.",
    },
    title: {
      name: "title",
      label: "Title",
      type: "text",
      placeholder: "eg. Kerem'in Şiiri",
      size: "large",
      errorMsg: "Title is required.",
    },
    author: {
      name: "author",
      label: "Author",
      type: "select",
      size: "large",
      isMulti: true,
      // options: [
      //   { value: "OMER_SEYFETTIN", label: "Ömer Seyfettin" },
      //   { value: "SABAHATTIN_ALI", label: "Sabahattin Ali" },
      //   { value: "ZEYNEP_CANDIR", label: "Zeynep Candır" },
      // ],
      placeholder: "Select author(s) of the book",
      errorMsg: "Author is required.",
    },
    illustrator: {
      name: "illustrator",
      label: "Illustrator",
      type: "select",
      size: "large",
      isMulti: true,
      // options: [
      //   { value: "DAMLA_OZTURK", label: "Damla Öztürk" },
      //   { value: "ZEYNEP_OZATALAY", label: "Zeynep Özatalay" },
      // ],
      placeholder: "Select illustrator(s) of the book",
    },
    translator: {
      name: "translator",
      label: "Translator",
      type: "select",
      size: "large",
      isMulti: true,
      // options: [
      //   { value: "AYSEGUL_DILAVER", label: "Ayşegül Dilaver" },
      //   { value: "ERKAN_YENIER", label: "Erkan Yenier" },
      // ],
      placeholder: "Select translator(s) of the book",
    },
    language: {
      name: "language",
      label: "Language",
      type: "select",
      size: "large",
      isMulti: false,
      // options: [
      //   { value: "TR", label: "Turkish" },
      //   { value: "EN", label: "English" },
      //   { value: "FR", label: "French" },
      // ],
      placeholder: "Select language of the book",
      errorMsg: "Language is required.",
    },
    grade: {
      name: "grade",
      label: "Grade",
      type: "select",
      size: "large",
      isMulti: true,
      // options: [
      //   { value: "ONE", label: "1" },
      //   { value: "TWO", label: "2" },
      //   { value: "THREE", label: "3" },
      //   { value: "FOUR", label: "4" },
      //   { value: "FIVE", label: "5" },
      // ],
      placeholder: "Select grade(s) of the book",
    },
    series: {
      name: "series",
      label: "Series",
      type: "select",
      size: "large",
      isMulti: false,
      // options: [
      //   { value: "CIN_ALI", label: "Cin Ali" },
      //   { value: "OKA", label: "OKA" },
      // ],
      placeholder: "Select series of the book",
      errorMsg: "Series field is required.",
    },
    level: {
      name: "level",
      label: "Level",
      type: "select",
      size: "large",
      isMulti: false,
      // options: [
      //   { value: "1", label: "1" },
      //   { value: "1+", label: "1+" },
      //   { value: "2", label: "2" },
      //   { value: "2+", label: "2+" },
      //   { value: "3", label: "3" },
      //   { value: "3+", label: "3+" },
      //   { value: "4", label: "4" },
      //   { value: "4+", label: "4+" },
      //   { value: "5", label: "5" },
      //   { value: "6", label: "6" },
      //   { value: "7", label: "7" },
      //   { value: "8", label: "8" },
      //   { value: "9", label: "9" },
      //   { value: "10", label: "10" },
      // ],
      placeholder: "Select level of the book",
      errorMsg: "Level is required.",
    },
    orderInLevel: {
      name: "orderInLevel",
      label: "Order In Level",
      type: "number",
      placeholder: "eg. 1",
      size: "large",
      errorMsg: "Order in level must be greater than 0.",
    },
    pageCount: {
      name: "pageCount",
      label: "Page Count",
      type: "number",
      placeholder: "eg. 2",
      size: "large",
      errorMsg: "Pages field must be greater than 0.",
      invalidMsg: "Pages value must be even.",
    },
    wordCount: {
      name: "wordCount",
      label: "Word Count",
      type: "number",
      placeholder: "eg. 200",
      size: "large",
      errorMsg: "Words Count field must be greater than 0.",
    },
    category: {
      name: "category",
      label: "Category",
      type: "select",
      size: "large",
      isMulti: false,
      // options: [
      //   { value: "FICTION", label: "Fiction" },
      //   { value: "NON_FICTION", label: "Non-Fiction" },
      // ],
      placeholder: "Select category of the book",
      errorMsg: "Category is required",
    },
    publisher: {
      name: "publisher",
      label: "Publisher",
      type: "select",
      size: "large",
      isMulti: false,
      options: [
        { value: "VAKIF_K12", label: "Vakıf K12" },
        { value: "CAN", label: "Can Yayınları" },
      ],
      placeholder: "Select publisher",
      errorMsg: "Publisher is required",
    },
    genre: {
      name: "genre",
      label: "Genre",
      type: "select",
      size: "large",
      isMulti: false,
      // options: [
      //   { value: "BIYOGRAFI", label: "Biyografi" },
      //   { value: "MASAL", label: "Masal" },
      //   { value: "FABL", label: "Fabl" },
      //   { value: "BILGI", label: "Bilgi Kitabı" },
      // ],
      placeholder: "Select genre of the book",
      errorMsg: "Genre is required",
    },
    subjectArea: {
      name: "subjectArea",
      label: "Subject Area",
      type: "select",
      size: "large",
      isMulti: false,
      // options: [
      //   { value: "ABOUT_ME", label: "About Me" },
      //   { value: "HOBIES", label: "Hobies" },
      //   { value: "LITERATURE", label: "Literature" },
      // ],
      placeholder: "Select subject area of the book",
      errorMsg: "Subject area is required",
    },
    keywords: {
      name: "keywords",
      label: "Keywords",
      type: "select",
      size: "large",
      isMulti: true,
      // options: [
      //   { value: "MY_BODY", label: "My Body" },
      //   { value: "PETS", label: "Pets" },
      //   { value: "DINOSAURS", label: "Dinosaurs" },
      //   { value: "IMAGINATION", label: "Imagination" },
      //   { value: "DANCE", label: "Dance" },
      //   { value: "FABLES", label: "Fables" },
      //   { value: "PICTURE_BOOKS", label: "Picture Books" },
      //   { value: "COUNTING", label: "Counting" },
      //   { value: "TECH", label: "Technology" },
      //   { value: "VEHICLES", label: "Vehicles" },
      // ],
      placeholder: "Select keywords of the book",
      errorMsg: "Keywords field is required",
    },
    use: {
      name: "use",
      label: "Use",
      type: "select",
      size: "large",
      isMulti: false,
      // options: [
      //   { value: "SCHOOL", label: "School" },
      //   { value: "HOME", label: "Home" },
      // ],
      placeholder: "Select use of the book",
      errorMsg: "Use is required",
    },
    isPublished: {
      name: "isPublished",
      label: "Publish",
      type: "switch",
    },
  },
};
