/* eslint-disable no-case-declarations */
import MessageTypes from "enums/message-types";
import { setRedirect, setLoader, setOrigin } from "redux/actions/ui";
import { apiRequest, API_SUCCESS, API_ERROR } from "redux/actions/api";
import {
  setData,
  putData,
  updateItemWithItem,
  removeData,
  updateItemOfArrayOfObject,
  clearData,
  manipulateData,
} from "redux/actions/data";
import { setMessage } from "redux/actions/message";
import { setAuth } from "redux/actions/auth";
import { setNotification } from "redux/actions/notifications";
import { setToStorage, removeFromStorage } from "redux/actions/storage";
import { setHeader } from "redux/actions/header";
import { sendLog } from "redux/actions/log";
import SeverityTypes from "enums/severity-types";
import {
  ALL_BOOKS,
  START_ALL_BOOKS_SCREEN,
  UPDATE_BOOK_ON_ALL_BOOKS,
  BOOK_FORM,
  START_BOOK_FORM_SCREEN,
  CLOSE_BOOK_FORM_SCREEN,
  GET_BOOK_FORM_LOOKUPS,
  SAVE_GENERAL_INFO,
  SAVE_PAGE_INFO,
  SIGNIN_FEATURE,
  START_SIGNIN_PAGE,
  SIGNIN,
  SELECT_SIDENAV_ITEM,
  SIGNOUT,
  NAVBAR,
  CREATE_PASSWORD_PAGE,
  START_CREATE_PASSWORD_PAGE,
  VALIDATE_EMAIL_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD_PAGE,
  START_FORGOT_PASSWORD_PAGE,
  SEND_LINK_BY_EMAIL,
  SAVE_UNSAVED_QUIZ,
  START_NEW_QUIZ_SCREEN,
  GET_QUIZ_FORM_LOOKUPS,
  SAVE_QUIZ_INFO,
  SAVE_QUESTIONS,
  ALL_QUIZES,
  UPDATE_QUIZ_ON_ALL_QUIZES,
  START_ALL_QUIZES_SCREEN,
  START_HOME_SCREEN,
  START_NEW_MATERIAL_SCREEN,
  GET_MATERIAL_FORM_LOOKUPS,
  SAVE_MATERIAL,
  ALL_MATERIALS,
  START_ALL_MATERIALS_SCREEN,
  UPDATE_MATERIAL_ON_ALL_MATERIALS,
} from "redux/actions/pages";

// LOOKUP KEYS
import {
  TOKEN_STORAGE_KEY,
  BASE_URL,
  PRODUCT_TYPE,
  // ACCOUNTS_DASHBOARD_URL,
  // MAIN_FRONTEND_URL,
  AUTHORS,
  ILLUSTRATORS,
  TRANSLATORS,
  LANGUAGES,
  GRADES,
  SERIES,
  LEVELS,
  CATEGORIES,
  GENRES,
  KEYWORDS,
  SUBJECT_AREAS,
  USES,
  PUBLISHERS,
  TEXT_STYLES,
  IMAGE_STYLES,
  QUIZ_TYPES,
  QUIZ_LEVELS,
  COURSES,
  SKILLS,
  UNITS,
  OBJECTIVES,
  SPECIAL_DAYS,
  MATERIAL_LEVELS,
  DOCUMENT_TYPES,
  LEARNING_DESIGN_TYPES,
} from "redux/actions/constants";
import UserTypes from "enums/user-types";
import FinderTypes from "enums/finder-types";
import StateOperationTypes from "enums/state-operation-types";

// Route Part + Url + Error Message
// ALL BOOKS
const ALL_BOOKS_ROUTE = "/book";
const GET_BOOKS_URL = `${BASE_URL}/api/book/extended`;
const GET_ALL_BOOKS_API_ERROR_MSG =
  "An error occured while all books are gotten. Please try again later.";
const UPDATE_BOOK_URL = `${BASE_URL}/api/book/save-book`;
const UPDATE_BOOK_API_SUCCESS_MSG = "Book updated successfully.";
const UPDATE_BOOK_API_ERROR_MSG = "An error occured while updating the book.";
const ALL_BOOKS_LOCATION = "books";

// BOOK FORM
const SAVE_GENERAL_INFO_URL = `${BASE_URL}/api/book/save-book`;
const SAVE_GENERAL_INFO_API_SUCCESS_MSG = "Book general information saved successfully.";
const SAVE_GENERAL_INFO_API_ERROR_MSG =
  "An error occured while saving the book general information.";
const SAVE_PAGE_INFO_URL = `${BASE_URL}/api/page/save-page`;
const SAVE_PAGE_INFO_API_SUCCESS_MSG = "Book's page information saved successfully.";
const SAVE_PAGE_INFO_API_ERROR_MSG = "An error occured while saving the book's page information.";
const CURRENT_BOOK_ID_LOCATION = "currentBookId";
const GET_LOOKUPS_DETAILED_URL = `${BASE_URL}/api/lookup/get-lookups-detailed`;
const GET_LOOKUPS_DETAILED_API_ERROR_MSG =
  "An error occured while selection options are gotten. Please try again later.";

// SIGNIN
const SIGNIN_ROUTE = "/signin";
const SIGNIN_URL = `${BASE_URL}/api/user/signin`;
const DASHBOARD_ROUTE = "/home";

// SIGNOUT
const SIGNOUT_URL = `${BASE_URL}/api/user/signout`;

// CREATE PASSWORD
const CREATE_PASSWORD_PAGE_ROUTE = "/create-password";
const VALIDATE_EMAIL_TOKEN_URL = `${BASE_URL}/api/user/validate-email-token`;
const RESET_PASSWORD_URL = `${BASE_URL}/api/user/reset-password`;
const VALIDATE_EMAIL_TOKEN_ERROR_MSG = "Email bağlantısı doğrulanamadı.";
const RESET_PASSWORD_SUCCESS_MSG = "Şifreniz başarılı bir şekilde değiştirildi.";
const RESET_PASSWORD_ERROR_MSG = "Şifreniz değiştirilirken hata meydana geldi.";

// FORGOT PASSWORD
const FORGOT_PASSWORD_PAGE_ROUTE = "/forgot-password";
const SEND_LINK_BY_EMAIL_URL = `${BASE_URL}/api/user/send-reset-password-email`;
const SEND_LINK_BY_EMAIL_SUCCESS_MSG =
  "Şifre değiştirme bağlantısı email olarak gönderilmiştir. Lütfen email kutunuzu kontrol ediniz ve işleme devam ediniz.";
const SEND_LINK_BY_EMAIL_ERROR_MSG = "Şifre değiştirme bağlantısı yollanırken bir hata oluştu.";

// QUIZ
const NEW_QUIZ_ROUTE = "/quiz/new";
const QUIZ_LIST_ROUTE = "/quiz";
const SAVE_QUIZ_INFO_URL = `${BASE_URL}/api/book/quiz/save-quiz`;
const SAVE_QUIZ_INFO_ERROR_MSG = "Quiz bilgileri kaydedilirken hata meydana geldi.";
const SAVE_QUIZ_INFO_SUCCESS_MSG = "Quiz bilgileri başarılı bir şekilde kaydedildi.";
const SAVE_QUESTIONS_URL = `${BASE_URL}/api/book/quiz/save-questions`;
const SAVE_QUESTIONS_ERROR_MSG = "Quiz soruları kaydedilirken hata meydana geldi.";
const SAVE_QUESTIONS_SUCCESS_MSG = "Quiz soruları başarılı bir şekilde kaydedildi.";
const GET_QUIZ_LIST_URL = `${BASE_URL}/api/book/quiz/quiz-list`;
const GET_QUIZ_LIST_ERROR_MSG = "Quiz listesi alınırken hata meydana geldi.";
const UPDATE_QUIZ_URL = `${BASE_URL}/api/book/quiz/save-quiz`;
const UPDATE_QUIZ_API_SUCCESS_MSG = "Quiz başarılı bir şekilde düzenlendi.";
const UPDATE_QUIZ_API_ERROR_MSG = "Quiz düzenlemesi sırasında hata meydana geldi.";

// MATERIAL
const NEW_MATERIAL_ROUTE = "/material/new";
const MATERIAL_LIST_ROUTE = "/material";
const SAVE_MATERIAL_URL = `${BASE_URL}/api/book/material/save-material`;
const SAVE_MATERIAL_ERROR_MSG = "Materyal kaydedilirken hata meydana geldi.";
const SAVE_MATERIAL_SUCCESS_MSG = "Materyal başarılı bir şekilde kaydedildi.";
const GET_MATERIAL_LIST_URL = `${BASE_URL}/api/book/material/material-list`;
const GET_MATERIAL_LIST_ERROR_MSG = "Materyal listesi alınırken hata meydana geldi.";
const UPDATE_MATERIAL_URL = `${BASE_URL}/api/book/material/save-material`;
const UPDATE_MATERIAL_API_SUCCESS_MSG = "Materyal başarılı bir şekilde düzenlendi.";
const UPDATE_MATERIAL_API_ERROR_MSG = "Materyal düzenlemesi sırasında hata meydana geldi.";

export const pagesMiddleware =
  ({ getState /* dispatch */ }) =>
  (next) =>
  (action) => {
    next(action);

    const { ui, auth, data, localization } = getState();
    const localizations = localization?.pairs;

    switch (action.type) {
      // SIDENAV
      case SELECT_SIDENAV_ITEM:
        next(
          setHeader({
            header: action.payload,
          })
        );
        break;
      // SIGNIN PAGE
      case START_SIGNIN_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === SIGNIN_ROUTE) {
          next(setRedirect({ to: "", feature: SIGNIN_FEATURE }));
        }

        // eslint-disable-next-line no-case-declarations
        const { currentUser } = auth;
        if (currentUser && currentUser.id && currentUser.id > 0) {
          if (ui.origin && ui.origin !== "") {
            next(setRedirect({ to: ui.origin, feature: SIGNIN_FEATURE }));
            next(setOrigin({ origin: "", feature: SIGNIN_FEATURE }));
          } else {
            next(setRedirect({ to: DASHBOARD_ROUTE, feature: SIGNIN_FEATURE }));
          }
        }
        break;
      case SIGNIN:
        next(setLoader({ state: true, feature: SIGNIN_FEATURE }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SIGNIN_URL,
            feature: SIGNIN_FEATURE,
          })
        );
        break;
      case `${SIGNIN_FEATURE} ${API_SUCCESS}`:
        // const userType = action.payload.currentUser?.userType;
        // if (userType === UserTypes.other || userType === UserTypes.demoOther) {
        //   window.location.href = ACCOUNTS_DASHBOARD_URL;
        // } else if (userType === UserTypes.student || userType === UserTypes.demoStudent) {
        //   window.location.href = MAIN_FRONTEND_URL;
        // }
        next(setLoader({ state: false, feature: SIGNIN_FEATURE }));
        const userType = action.payload.currentUser?.userType;
        if (userType !== UserTypes.admin) {
          next(setRedirect({ to: "/error403", feature: SIGNIN_FEATURE }));
          next(removeFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: SIGNIN_FEATURE }));
        } else {
          next(setAuth({ auth: action.payload }));
          next(
            setHeader({
              header: {
                organization: action.payload.currentUser?.organization?.id,
                suborganization: action.payload.currentUser?.suborganizations[0]?.id,
              },
            })
          );
          next(
            setToStorage({
              storageKey: TOKEN_STORAGE_KEY,
              storageValue: action.payload.token,
              feature: SIGNIN_FEATURE,
            })
          );
          next(setRedirect({ to: DASHBOARD_ROUTE, feature: SIGNIN_FEATURE }));
        }
        break;
      case `${SIGNIN_FEATURE} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SIGNIN_FEATURE }));
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: error.message,
                feature: SIGNIN_FEATURE,
              })
            );
          });
        } else if (action.payload.status === "fail" && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: err,
                feature: SIGNIN_FEATURE,
              })
            );
          });
        } else {
          // TODO: Sonraki satır test edilecektir.
          next(
            setNotification({
              type: MessageTypes.error,
              message: action.payload.message,
              feature: SIGNIN_FEATURE,
            })
          );
        }
        next(removeFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: SIGNIN_FEATURE }));
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SIGNIN_FEATURE} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case SIGNOUT:
        next(setLoader({ state: true, feature: NAVBAR }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SIGNOUT_URL,
            feature: NAVBAR,
          })
        );
        break;
      case `${NAVBAR} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: NAVBAR }));
        next(setAuth({ auth: action.payload }));
        next(clearData());
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: NAVBAR,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: NAVBAR }));
        break;
      case `${NAVBAR} ${API_ERROR}`:
        next(setLoader({ state: false, feature: NAVBAR }));
        next(setAuth({ auth: {} }));
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: NAVBAR,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: NAVBAR }));
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${NAVBAR} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case START_CREATE_PASSWORD_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === CREATE_PASSWORD_PAGE_ROUTE) {
          next(
            setRedirect({
              to: "",
              feature: CREATE_PASSWORD_PAGE,
            })
          );
        }
        break;
      case VALIDATE_EMAIL_TOKEN:
        next(setLoader({ state: true, feature: VALIDATE_EMAIL_TOKEN }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: VALIDATE_EMAIL_TOKEN_URL,
            feature: VALIDATE_EMAIL_TOKEN,
          })
        );
        break;
      case `${VALIDATE_EMAIL_TOKEN} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: VALIDATE_EMAIL_TOKEN }));
        break;
      case `${VALIDATE_EMAIL_TOKEN} ${API_ERROR}`:
        next(setLoader({ state: false, feature: VALIDATE_EMAIL_TOKEN }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: VALIDATE_EMAIL_TOKEN_ERROR_MSG,
            feature: VALIDATE_EMAIL_TOKEN,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${VALIDATE_EMAIL_TOKEN} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case RESET_PASSWORD:
        next(setLoader({ state: true, feature: RESET_PASSWORD }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: RESET_PASSWORD_URL,
            feature: RESET_PASSWORD,
          })
        );
        break;
      case `${RESET_PASSWORD} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: RESET_PASSWORD }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: RESET_PASSWORD_SUCCESS_MSG,
            feature: RESET_PASSWORD,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: RESET_PASSWORD }));
        break;
      case `${RESET_PASSWORD} ${API_ERROR}`:
        next(setLoader({ state: false, feature: RESET_PASSWORD }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: RESET_PASSWORD_ERROR_MSG,
            feature: RESET_PASSWORD,
          })
        );
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: error.message,
                feature: RESET_PASSWORD,
              })
            );
          });
        } else if (action.payload.status === "fail" && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: err,
                feature: RESET_PASSWORD,
              })
            );
          });
        }
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${RESET_PASSWORD} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case START_FORGOT_PASSWORD_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === FORGOT_PASSWORD_PAGE_ROUTE) {
          next(
            setRedirect({
              to: "",
              feature: FORGOT_PASSWORD_PAGE,
            })
          );
        }
        break;
      case SEND_LINK_BY_EMAIL:
        next(setLoader({ state: true, feature: SEND_LINK_BY_EMAIL }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SEND_LINK_BY_EMAIL_URL,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        break;
      case `${SEND_LINK_BY_EMAIL} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SEND_LINK_BY_EMAIL }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SEND_LINK_BY_EMAIL_SUCCESS_MSG,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        break;
      case `${SEND_LINK_BY_EMAIL} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SEND_LINK_BY_EMAIL }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: SEND_LINK_BY_EMAIL_ERROR_MSG,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: error.message,
                feature: SEND_LINK_BY_EMAIL,
              })
            );
          });
        } else if (action.payload.status === "fail" && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: err,
                feature: SEND_LINK_BY_EMAIL,
              })
            );
          });
        }
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SEND_LINK_BY_EMAIL} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      // BOOK PAGE
      case START_ALL_BOOKS_SCREEN:
        // If I came to this screen via redirection, remove redirection from state
        if (ui && ui.redirectTo && ui.redirectTo.includes(ALL_BOOKS_ROUTE)) {
          next(setRedirect({ to: "", feature: ALL_BOOKS }));
        }

        // If book list in state is empty, get all books from backend
        if (data && data.books && data.books.length > 0) {
          // DO NOTHING
        } else {
          next(setLoader({ state: true, feature: ALL_BOOKS }));
          next(
            apiRequest({
              body: action.payload,
              method: "GET",
              url: GET_BOOKS_URL,
              feature: ALL_BOOKS,
            })
          );
        }
        break;
      case `${ALL_BOOKS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: ALL_BOOKS }));
        next(setData({ data: action.payload, feature: ALL_BOOKS }));
        break;
      case `${ALL_BOOKS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: ALL_BOOKS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: GET_ALL_BOOKS_API_ERROR_MSG,
            feature: ALL_BOOKS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${ALL_BOOKS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case UPDATE_BOOK_ON_ALL_BOOKS:
        // update on DB
        // eslint-disable-next-line no-case-declarations
        const updatedBook = {
          ...data.books[action.payload.bookId],
          isPublished: action.payload.isPublished,
        };
        delete updatedBook.pages;
        next(
          apiRequest({
            body: { book: updatedBook },
            method: "POST",
            url: UPDATE_BOOK_URL,
            feature: UPDATE_BOOK_ON_ALL_BOOKS,
          })
        );
        break;
      case `${UPDATE_BOOK_ON_ALL_BOOKS} ${API_SUCCESS}`:
        next(
          setNotification({
            type: MessageTypes.success,
            message: UPDATE_BOOK_API_SUCCESS_MSG,
            feature: UPDATE_BOOK_ON_ALL_BOOKS,
          })
        );
        // TODO: Burada action.payload.book varsa şeklinde bir check eklenebilir.
        next(
          updateItemWithItem({
            feature: UPDATE_BOOK_ON_ALL_BOOKS,
            where: ALL_BOOKS_LOCATION,
            itemLocation: Object.keys(action.payload.book)[0],
            itemValues: Object.values(action.payload.book)[0],
          })
        );
        break;
      case `${UPDATE_BOOK_ON_ALL_BOOKS} ${API_ERROR}`:
        // Set error notification
        next(
          setNotification({
            type: MessageTypes.error,
            message: UPDATE_BOOK_API_ERROR_MSG,
            feature: UPDATE_BOOK_ON_ALL_BOOKS,
          })
        );

        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${UPDATE_BOOK_ON_ALL_BOOKS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case START_BOOK_FORM_SCREEN:
        next(setData({ data: { currentBookId: action.payload.bookId }, feature: BOOK_FORM }));
        break;
      case CLOSE_BOOK_FORM_SCREEN:
        next(removeData({ feature: BOOK_FORM, where: CURRENT_BOOK_ID_LOCATION }));
        break;
      case GET_BOOK_FORM_LOOKUPS:
        next(setLoader({ state: true, feature: GET_BOOK_FORM_LOOKUPS }));
        // 1. Create an lookupKey array which are not included in state.data.lookups
        let missingBookLookupKeys = [];
        const requiredBookLookupKeys = [
          AUTHORS,
          ILLUSTRATORS,
          TRANSLATORS,
          LANGUAGES,
          GRADES,
          SERIES,
          LEVELS,
          CATEGORIES,
          GENRES,
          KEYWORDS,
          SUBJECT_AREAS,
          USES,
          PUBLISHERS,
          TEXT_STYLES,
          IMAGE_STYLES,
        ];

        if (!data.lookups || Object.keys(data.lookups).length === 0) {
          missingBookLookupKeys = requiredBookLookupKeys;
        } else {
          requiredBookLookupKeys.forEach((k) => {
            if (data.lookups[k] && Object.keys(data.lookups[k]).length > 0) {
              // Do nothing
            } else {
              missingBookLookupKeys.push(k);
            }
          });
        }

        if (missingBookLookupKeys.length > 0) {
          next(
            apiRequest({
              body: {
                lookupKeys: missingBookLookupKeys,
              },
              method: "POST",
              url: GET_LOOKUPS_DETAILED_URL,
              feature: GET_BOOK_FORM_LOOKUPS,
            })
          );
        }
        break;
      case `${GET_BOOK_FORM_LOOKUPS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_BOOK_FORM_LOOKUPS }));
        // 2. Append new lookups to data.lookups object
        next(
          setData({
            data: {
              lookups: {
                ...data.lookups,
                ...action.payload.lookups,
              },
            },
            feature: GET_BOOK_FORM_LOOKUPS,
          })
        );
        break;
      case `${GET_BOOK_FORM_LOOKUPS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_BOOK_FORM_LOOKUPS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: GET_LOOKUPS_DETAILED_API_ERROR_MSG,
            feature: GET_BOOK_FORM_LOOKUPS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_BOOK_FORM_LOOKUPS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case SAVE_GENERAL_INFO:
        next(
          apiRequest({
            body: { book: action.payload },
            method: "POST",
            url: SAVE_GENERAL_INFO_URL,
            feature: SAVE_GENERAL_INFO,
          })
        );
        break;
      case `${SAVE_GENERAL_INFO} ${API_SUCCESS}`:
        next(
          putData({
            feature: SAVE_GENERAL_INFO,
            where: ALL_BOOKS_LOCATION,
            data: action.payload.book,
          })
        );
        // get the id
        // eslint-disable-next-line no-case-declarations
        const savedBookId = parseInt(Object.keys(action.payload.book)[0], 10);
        next(setData({ data: { currentBookId: savedBookId }, feature: SAVE_GENERAL_INFO }));
        // Set success message
        // next(
        //   setMessage({
        //     messageType: MessageTypes.success,
        //     messageContent: SAVE_GENERAL_INFO_API_SUCCESS_MSG,
        //     feature: SAVE_GENERAL_INFO,
        //   })
        // );
        next(
          setNotification({
            type: MessageTypes.success,
            message: SAVE_GENERAL_INFO_API_SUCCESS_MSG,
            feature: SAVE_GENERAL_INFO,
          })
        );
        break;
      case `${SAVE_GENERAL_INFO} ${API_ERROR}`:
        // Set error message
        // next(
        //   setMessage({
        //     messageType: MessageTypes.error,
        //     messageContent: SAVE_GENERAL_INFO_API_ERROR_MSG,
        //     feature: SAVE_GENERAL_INFO,
        //   })
        // );
        next(
          setNotification({
            type: MessageTypes.error,
            message: SAVE_GENERAL_INFO_API_ERROR_MSG,
            feature: SAVE_GENERAL_INFO,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SAVE_GENERAL_INFO} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case SAVE_PAGE_INFO:
        next(
          apiRequest({
            body: { page: action.payload },
            method: "POST",
            url: SAVE_PAGE_INFO_URL,
            feature: SAVE_PAGE_INFO,
          })
        );
        break;
      case `${SAVE_PAGE_INFO} ${API_SUCCESS}`:
        next(
          updateItemOfArrayOfObject({
            feature: SAVE_PAGE_INFO,
            where: ALL_BOOKS_LOCATION,
            item: action.payload.page.bookId,
            key: "pages",
            id: action.payload.page.id,
            data: action.payload.page,
          })
        );
        // Set success message
        // next(
        //   setMessage({
        //     messageType: MessageTypes.success,
        //     messageContent: SAVE_PAGE_INFO_API_SUCCESS_MSG,
        //     feature: SAVE_PAGE_INFO,
        //   })
        // );
        next(
          setNotification({
            type: MessageTypes.success,
            message: SAVE_PAGE_INFO_API_SUCCESS_MSG,
            feature: SAVE_PAGE_INFO,
          })
        );
        break;
      case `${SAVE_PAGE_INFO} ${API_ERROR}`:
        // Set error message
        // next(
        //   setMessage({
        //     messageType: MessageTypes.error,
        //     messageContent: SAVE_PAGE_INFO_API_ERROR_MSG,
        //     feature: SAVE_PAGE_INFO,
        //   })
        // );
        next(
          setNotification({
            type: MessageTypes.error,
            message: SAVE_PAGE_INFO_API_ERROR_MSG,
            feature: SAVE_PAGE_INFO,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SAVE_PAGE_INFO} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;

      case SAVE_UNSAVED_QUIZ:
        next(
          setData({
            data: action.payload,
            feature: SAVE_UNSAVED_QUIZ,
          })
        );
        break;
      case START_NEW_QUIZ_SCREEN:
        if (ui && ui.redirectTo && ui.redirectTo.includes(NEW_QUIZ_ROUTE)) {
          next(setRedirect({ to: "", feature: START_NEW_QUIZ_SCREEN }));
        }

        if (data && data.books && data.books.length > 0) {
          // DO NOT TRY TO GET BOOK LIST
        } else {
          next(
            apiRequest({
              body: action.payload,
              method: "GET",
              url: GET_BOOKS_URL,
              feature: START_NEW_QUIZ_SCREEN,
            })
          );
        }
        break;
      case `${START_NEW_QUIZ_SCREEN} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: START_NEW_QUIZ_SCREEN }));
        next(setData({ data: action.payload, feature: START_NEW_QUIZ_SCREEN }));
        break;
      case `${START_NEW_QUIZ_SCREEN} ${API_ERROR}`:
        next(setLoader({ state: false, feature: START_NEW_QUIZ_SCREEN }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: GET_ALL_BOOKS_API_ERROR_MSG,
            feature: START_NEW_QUIZ_SCREEN,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${START_NEW_QUIZ_SCREEN} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_QUIZ_FORM_LOOKUPS:
        next(setLoader({ state: true, feature: GET_QUIZ_FORM_LOOKUPS }));
        let missingQuizLookupKeys = [];
        const requiredQuizLookupKeys = [GRADES, QUIZ_TYPES, QUIZ_LEVELS];
        if (!data.lookups || Object.keys(data.lookups).length === 0) {
          missingQuizLookupKeys = requiredQuizLookupKeys;
        } else {
          requiredQuizLookupKeys.forEach((k) => {
            if (data.lookups[k] && Object.keys(data.lookups[k]).length > 0) {
              // Do nothing
            } else {
              missingQuizLookupKeys.push(k);
            }
          });
        }
        if (missingQuizLookupKeys.length > 0) {
          next(
            apiRequest({
              body: {
                lookupKeys: missingQuizLookupKeys,
              },
              method: "POST",
              url: GET_LOOKUPS_DETAILED_URL,
              feature: GET_QUIZ_FORM_LOOKUPS,
            })
          );
        }
        break;
      case `${GET_QUIZ_FORM_LOOKUPS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_QUIZ_FORM_LOOKUPS }));
        next(
          setData({
            data: {
              lookups: {
                ...data.lookups,
                ...action.payload.lookups,
              },
            },
            feature: GET_QUIZ_FORM_LOOKUPS,
          })
        );
        break;
      case `${GET_QUIZ_FORM_LOOKUPS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_QUIZ_FORM_LOOKUPS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_LOOKUPS_DETAILED_API_ERROR_MSG ??
              GET_LOOKUPS_DETAILED_API_ERROR_MSG,
            feature: GET_QUIZ_FORM_LOOKUPS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_QUIZ_FORM_LOOKUPS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case SAVE_QUIZ_INFO:
        next(setLoader({ state: true, feature: SAVE_QUIZ_INFO }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SAVE_QUIZ_INFO_URL,
            feature: SAVE_QUIZ_INFO,
          })
        );
        break;
      case `${SAVE_QUIZ_INFO} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SAVE_QUIZ_INFO }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SAVE_QUIZ_INFO_SUCCESS_MSG,
            feature: SAVE_QUIZ_INFO,
          })
        );
        next(
          setData({
            data: {
              unsavedQuiz: {
                ...data?.unsavedQuiz,
                ...action.payload?.quiz,
              },
            },
            feature: SAVE_QUIZ_INFO,
          })
        );
        next(
          manipulateData({
            feature: SAVE_QUIZ_INFO,
            operation: StateOperationTypes.append,
            finder: [
              {
                where: "quizes",
                type: FinderTypes.object,
              },
            ],
            value: {
              ...data.quizes,
              [action.payload.quiz.id]: data.quizes
                ? {
                    ...data.quizes[action.payload.quiz.id],
                    ...action.payload.quiz,
                  }
                : { ...action.payload.quiz },
            },
          })
        );
        break;
      case `${SAVE_QUIZ_INFO} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SAVE_QUIZ_INFO }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: SAVE_QUIZ_INFO_ERROR_MSG,
            feature: SAVE_QUIZ_INFO,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SAVE_QUIZ_INFO} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case SAVE_QUESTIONS:
        next(setLoader({ state: true, feature: SAVE_QUESTIONS }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SAVE_QUESTIONS_URL,
            feature: SAVE_QUESTIONS,
          })
        );
        break;
      case `${SAVE_QUESTIONS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SAVE_QUESTIONS }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SAVE_QUESTIONS_SUCCESS_MSG,
            feature: SAVE_QUESTIONS,
          })
        );
        // Buradaki veri gidilip quizes'a yazılacak!
        if (data.quizes && Object.keys(data.quizes).length > 1) {
          next(
            manipulateData({
              feature: SAVE_QUESTIONS,
              operation: StateOperationTypes.append,
              finder: [
                {
                  where: "quizes",
                  type: FinderTypes.object,
                },
              ],
              value: {
                ...data.quizes,
                [action.payload.quiz.id]: data.quizes
                  ? {
                      ...data.quizes[action.payload.quiz.id],
                      ...action.payload.quiz,
                    }
                  : { ...action.payload.quiz },
              },
            })
          );
        }

        next(removeData({ feature: SAVE_QUESTIONS, where: "unsavedQuiz" }));
        next(setRedirect({ to: QUIZ_LIST_ROUTE, feature: SAVE_QUESTIONS }));
        break;
      case `${SAVE_QUESTIONS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SAVE_QUESTIONS }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: SAVE_QUESTIONS_ERROR_MSG,
            feature: SAVE_QUESTIONS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SAVE_QUESTIONS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case START_ALL_QUIZES_SCREEN:
        if (ui && ui.redirectTo && ui.redirectTo === QUIZ_LIST_ROUTE) {
          next(setRedirect({ to: "", feature: ALL_QUIZES }));
        }

        if (data && data.quizes && Object.keys(data.quizes).length > 1) {
          // DO NOTHING
        } else {
          next(setLoader({ state: true, feature: ALL_QUIZES }));
          next(
            apiRequest({
              method: "GET",
              url: GET_QUIZ_LIST_URL,
              feature: ALL_QUIZES,
            })
          );
        }
        break;
      case `${ALL_QUIZES} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: ALL_QUIZES }));
        next(setData({ data: action.payload, feature: ALL_QUIZES }));
        break;
      case `${ALL_QUIZES} ${API_ERROR}`:
        next(setLoader({ state: false, feature: ALL_QUIZES }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: GET_QUIZ_LIST_ERROR_MSG,
            feature: ALL_QUIZES,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${ALL_QUIZES} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case UPDATE_QUIZ_ON_ALL_QUIZES:
        const quiz = data.quizes[action.payload.quizId];
        if (quiz) {
          const updatedQuiz = {
            id: quiz.id,
            bookId: quiz.book.id,
            type: quiz.type,
            duration: quiz.duration,
            grade: quiz.grade,
            level: quiz.level,
            order: quiz.quizOrder,
            isPublished: action.payload.isPublished,
            isPrimary: quiz.isPrimary,
          };

          next(
            apiRequest({
              body: { quiz: updatedQuiz },
              method: "POST",
              url: UPDATE_QUIZ_URL,
              feature: UPDATE_QUIZ_ON_ALL_QUIZES,
            })
          );
        }
        break;
      case `${UPDATE_QUIZ_ON_ALL_QUIZES} ${API_SUCCESS}`:
        // Notification success
        next(
          setNotification({
            type: MessageTypes.success,
            message: UPDATE_QUIZ_API_SUCCESS_MSG,
            feature: UPDATE_QUIZ_ON_ALL_QUIZES,
          })
        );
        next(
          manipulateData({
            feature: UPDATE_QUIZ_ON_ALL_QUIZES,
            operation: StateOperationTypes.append,
            finder: [
              {
                where: "quizes",
                type: FinderTypes.object,
              },
            ],
            value: {
              ...data.quizes,
              [action.payload.quiz.id]: data.quizes
                ? {
                    ...data.quizes[action.payload.quiz.id],
                    ...action.payload.quiz,
                  }
                : { ...action.payload.quiz },
            },
          })
        );
        break;
      case `${UPDATE_QUIZ_ON_ALL_QUIZES} ${API_ERROR}`:
        // Loglama
        next(
          setNotification({
            type: MessageTypes.error,
            message: UPDATE_QUIZ_API_ERROR_MSG,
            feature: UPDATE_QUIZ_ON_ALL_QUIZES,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${UPDATE_QUIZ_ON_ALL_QUIZES} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case START_HOME_SCREEN:
        if (ui && ui.redirectTo && ui.redirectTo.includes(DASHBOARD_ROUTE)) {
          next(setRedirect({ to: "", feature: START_HOME_SCREEN }));
        }
        break;
      case START_NEW_MATERIAL_SCREEN:
        if (ui && ui.redirectTo && ui.redirectTo.includes(NEW_MATERIAL_ROUTE)) {
          next(setRedirect({ to: "", feature: START_NEW_MATERIAL_SCREEN }));
        }

        if (data && data.books && data.books.length > 0) {
          // DO NOT TRY TO GET BOOK LIST
        } else {
          next(
            apiRequest({
              body: action.payload,
              method: "GET",
              url: GET_BOOKS_URL,
              feature: START_NEW_MATERIAL_SCREEN,
            })
          );
        }
        break;
      case `${START_NEW_MATERIAL_SCREEN} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: START_NEW_MATERIAL_SCREEN }));
        next(setData({ data: action.payload, feature: START_NEW_MATERIAL_SCREEN }));
        break;
      case `${START_NEW_MATERIAL_SCREEN} ${API_ERROR}`:
        next(setLoader({ state: false, feature: START_NEW_MATERIAL_SCREEN }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: GET_ALL_BOOKS_API_ERROR_MSG,
            feature: START_NEW_MATERIAL_SCREEN,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${START_NEW_MATERIAL_SCREEN} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_MATERIAL_FORM_LOOKUPS:
        next(setLoader({ state: true, feature: GET_MATERIAL_FORM_LOOKUPS }));
        let missingMaterialLookupKeys = [];
        const requiredMaterialLookupKeys = [
          GRADES,
          COURSES,
          SKILLS,
          UNITS,
          OBJECTIVES,
          SPECIAL_DAYS,
          MATERIAL_LEVELS,
          DOCUMENT_TYPES,
          LEARNING_DESIGN_TYPES,
        ];
        if (!data.lookups || Object.keys(data.lookups).length === 0) {
          missingMaterialLookupKeys = requiredMaterialLookupKeys;
        } else {
          requiredMaterialLookupKeys.forEach((k) => {
            if (data.lookups[k] && Object.keys(data.lookups[k]).length > 0) {
              // Do nothing
            } else {
              missingMaterialLookupKeys.push(k);
            }
          });
        }

        if (missingMaterialLookupKeys.length > 0) {
          next(
            apiRequest({
              body: {
                lookupKeys: missingMaterialLookupKeys,
              },
              method: "POST",
              url: GET_LOOKUPS_DETAILED_URL,
              feature: GET_MATERIAL_FORM_LOOKUPS,
            })
          );
        }
        break;
      case `${GET_MATERIAL_FORM_LOOKUPS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_MATERIAL_FORM_LOOKUPS }));
        next(
          setData({
            data: {
              lookups: {
                ...data.lookups,
                ...action.payload.lookups,
              },
            },
            feature: GET_MATERIAL_FORM_LOOKUPS,
          })
        );
        break;
      case `${GET_MATERIAL_FORM_LOOKUPS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_MATERIAL_FORM_LOOKUPS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_LOOKUPS_DETAILED_API_ERROR_MSG ??
              GET_LOOKUPS_DETAILED_API_ERROR_MSG,
            feature: GET_MATERIAL_FORM_LOOKUPS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_MATERIAL_FORM_LOOKUPS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case SAVE_MATERIAL:
        next(setLoader({ state: true, feature: SAVE_MATERIAL }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SAVE_MATERIAL_URL,
            feature: SAVE_MATERIAL,
          })
        );
        break;
      case `${SAVE_MATERIAL} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SAVE_MATERIAL }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SAVE_MATERIAL_SUCCESS_MSG,
            feature: SAVE_MATERIAL,
          })
        );
        if (
          data.materials &&
          Object.keys(data.materials).length > 0 &&
          action.payload.material?.id > 0
        ) {
          next(
            setData({
              data: {
                materials: {
                  ...data.materials,
                  [action.payload.material?.id]: {
                    ...action.payload.material,
                  },
                },
              },
              feature: SAVE_MATERIAL,
            })
          );
        }

        next(setRedirect({ to: MATERIAL_LIST_ROUTE, feature: SAVE_MATERIAL }));
        break;
      case `${SAVE_MATERIAL} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SAVE_MATERIAL }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: SAVE_MATERIAL_ERROR_MSG,
            feature: SAVE_MATERIAL,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SAVE_MATERIAL} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case START_ALL_MATERIALS_SCREEN:
        if (ui && ui.redirectTo && ui.redirectTo === MATERIAL_LIST_ROUTE) {
          next(setRedirect({ to: "", feature: ALL_MATERIALS }));
        }

        if (data && data.materials && Object.keys(data.materials).length > 0) {
          // DO NOTHING
        } else {
          next(setLoader({ state: true, feature: ALL_MATERIALS }));
          next(
            apiRequest({
              method: "GET",
              url: GET_MATERIAL_LIST_URL,
              feature: ALL_MATERIALS,
            })
          );
        }
        break;

      case `${ALL_MATERIALS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: ALL_MATERIALS }));
        next(setData({ data: action.payload, feature: ALL_MATERIALS }));
        break;
      case `${ALL_MATERIALS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: ALL_MATERIALS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: GET_MATERIAL_LIST_ERROR_MSG,
            feature: ALL_MATERIALS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${ALL_MATERIALS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case UPDATE_MATERIAL_ON_ALL_MATERIALS:
        const material = data.materials[action.payload.materialId];
        if (material) {
          const updatedMaterial = {
            id: material.id,
            bookId: material.book.id,
            grade: material.grade,
            course: material.course,
            skill: material.skill,
            unit: material.unit,
            specialDay: material.specialDay,
            objectives: material.objectives,
            duration: material.duration,
            level: material.level,
            materialOrder: material.materialOrder,
            documentType: material.documentType,
            description: material.description,
            learningDesignType: material.learningDesignType,
            isPublished: action.payload.isPublished,
            fileUrl: material.fileUrl,
            introduction: material.introduction,
            activity: material.activity,
            assessment: material.assessment,
          };

          next(
            apiRequest({
              body: { material: updatedMaterial },
              method: "POST",
              url: UPDATE_MATERIAL_URL,
              feature: UPDATE_MATERIAL_ON_ALL_MATERIALS,
            })
          );
        }
        break;
      case `${UPDATE_MATERIAL_ON_ALL_MATERIALS} ${API_SUCCESS}`:
        // Notification success
        next(
          setNotification({
            type: MessageTypes.success,
            message: UPDATE_MATERIAL_API_SUCCESS_MSG,
            feature: UPDATE_MATERIAL_ON_ALL_MATERIALS,
          })
        );
        next(
          manipulateData({
            feature: UPDATE_MATERIAL_ON_ALL_MATERIALS,
            operation: StateOperationTypes.append,
            finder: [
              {
                where: "materials",
                type: FinderTypes.object,
              },
            ],
            value: {
              ...data.materials,
              [action.payload.material.id]: data.materials
                ? {
                    ...data.materials[action.payload.material.id],
                    ...action.payload.material,
                  }
                : { ...action.payload.material },
            },
          })
        );
        break;
      case `${UPDATE_MATERIAL_ON_ALL_MATERIALS} ${API_ERROR}`:
        // Loglama
        next(
          setNotification({
            type: MessageTypes.error,
            message: UPDATE_MATERIAL_API_ERROR_MSG,
            feature: UPDATE_MATERIAL_ON_ALL_MATERIALS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${UPDATE_MATERIAL_ON_ALL_MATERIALS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      default:
        break;
    }
  };
