/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select, { components } from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import { Tooltip } from "@mui/material";

// Custom styles for SuiSelect
import styles from "components/SuiSelect/styles";

const ObjectiveMultiValue = ({ tooltip, data, children, ...props }) => (
  <Tooltip title={children}>
    <div>
      <components.MultiValue data={data} {...props}>
        {data.value.replace(/_/g, ".")}
      </components.MultiValue>
    </div>
  </Tooltip>
);

ObjectiveMultiValue.defaultProps = {
  tooltip: null,
  data: null,
  children: "",
};

ObjectiveMultiValue.propTypes = {
  tooltip: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.string,
};

const SuiSelect = forwardRef(({ size, error, success, isForObjective, ...rest }, ref) => {
  const { light } = colors;

  return isForObjective ? (
    <Select
      {...rest}
      ref={ref}
      styles={styles(size, error, success)}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
      components={{
        MultiValue: ObjectiveMultiValue,
      }}
    />
  ) : (
    <Select
      {...rest}
      ref={ref}
      styles={styles(size, error, success)}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
    />
  );
});

// Setting default values for the props of SuiSelect
SuiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  isForObjective: false,
};

// Typechecking props for the SuiSelect
SuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  isForObjective: PropTypes.bool,
};

export default SuiSelect;
