import { SET_LOADER, SET_REDIRECT, SET_ORIGIN } from "redux/actions/ui";

const initState = { loading: false, redirectTo: "" };

export const uiReducer = (ui = initState, action) => {
  switch (true) {
    case action.type.includes(SET_LOADER):
      return { ...ui, loading: action.payload };
    case action.type.includes(SET_REDIRECT):
      return { ...ui, redirectTo: action.payload };
    case action.type.includes(SET_ORIGIN):
      return { ...ui, origin: action.payload };
    default:
      return ui;
  }
};
