/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ArrowFatUp, X } from "phosphor-react";
import { Tooltip } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";

import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import "./_material-upload.css";

const MaterialUpload = ({
  localizations,
  height,
  url,
  removeBtnRight,
  onMaterialRemove,
  onMaterialChange,
  loading,
}) => (
  <div className="upload-container">
    <SuiButton
      variant="text"
      size="small"
      aria-label={localizations?.UPLOAD_MATERIAL ?? "Materyal Yükle"}
      component="label"
      style={{ padding: 0 }}
    >
      <SuiBox
        className="material-container"
        bgColor="#f1f3f4"
        style={{
          height: `${height}px`,
          width: "100%",
        }}
        width="100%"
        height={height ? `${height}px` : `120px`}
      >
        {url && url !== "" ? (
          // <img src={url} alt={localizations?.MATERIAL_ALT_TEXT ?? "Soru Resmi"} />
          <embed src={url} type="application/pdf" width="100%" height={`${height}px`} />
        ) : loading ? (
          <div className="material-uploading">
            <ClipLoader color="#7493b6" size={35} />
          </div>
        ) : (
          <div className="upload-material-placeholder">
            <ArrowFatUp size={height ? height / 2 : 100} weight="duotone" color="#7493b6" />
            <span>{localizations?.UPLOAD_MATERIAL ?? "Materyal Yükle"}</span>
          </div>
        )}
      </SuiBox>
      <input
        name="file"
        type="file"
        accept="application/pdf, .pdf"
        hidden
        onChange={onMaterialChange}
      />
    </SuiButton>
    {url && url !== "" ? (
      <Tooltip title={localizations?.REMOVE_MATERIAL ?? "Materyali Kaldır"} placement="top">
        <div
          onClick={onMaterialRemove}
          aria-hidden
          className="material-remove"
          style={{
            top: 0,
            right: removeBtnRight ?? 0,
            cursor: onMaterialRemove ? "pointer" : "default",
          }}
        >
          <X weight="duotone" size={16} color="#E3E9F0" />
        </div>
      </Tooltip>
    ) : null}
  </div>
);

MaterialUpload.defaultProps = {
  localizations: null,
  height: null,
  url: "",
  removeBtnRight: null,
  onMaterialRemove: null,
  loading: false,
};

MaterialUpload.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  height: PropTypes.number,
  url: PropTypes.string,
  removeBtnRight: PropTypes.string,
  onMaterialRemove: PropTypes.func,
  onMaterialChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(MaterialUpload);
