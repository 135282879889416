/* eslint-disable no-console */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";

import requireAuth from "hocs/requireAuth";
import { connect } from "react-redux";
import { startHomeScreen, START_HOME_SCREEN } from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import Swal from "sweetalert2";
import MessageTypes from "enums/message-types";

function Home({ startScreen, message, removeNotificationMsg, localizations }) {
  const history = useHistory();

  useEffect(() => {
    startScreen();
  }, []);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title:
          message.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_GO_HOME_CONFIRM_BTN,
      }).then(() => {
        removeNotificationMsg(START_HOME_SCREEN);
        history.push("/");
      });
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container>
          <Grid item lg={12}>
            <h3>EDICT ADMIN ANA SAYFASI OLACAK!</h3>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  message: state.message[START_HOME_SCREEN],
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  startScreen: startHomeScreen,
  removeNotificationMsg: removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(Home));
