/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { Grid, Tooltip, IconButton } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import { FunnelSimple, FileSearch, Pencil } from "phosphor-react";
import DataTable from "custom/Tables/DataTable";
import { connect } from "react-redux";
import { ALL_QUIZES, startAllQuizesScreen, updateQuizOnAllQuizes } from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import MessageTypes from "enums/message-types";
import Alert from "custom/Alert";
import BookCell from "../components/BookCell";
import PublishCell from "../components/PublishCell";
import QuizPreviewDialog from "../components/QuizPreviewDialog";

const QuizList = ({
  allQuizes,
  message,
  startScreen,
  removeErrorMsg,
  localizations,
  updateQuiz,
  language,
}) => {
  const [quizFilter, setQuizFilter] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedQuizId, setSelectedQuizId] = useState(null);
  const history = useHistory();

  useEffect(() => {
    startScreen();
  }, []);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title: message.messageType === MessageTypes.success ? "BAŞARILI!" : "HATA!",
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_GO_HOME_CONFIRM_BTN,
      }).then(() => {
        removeErrorMsg(ALL_QUIZES);
        history.push("/");
      });
    }
    // eslint-disable-next-line
  }, [message]);

  const handlePublishChange = useCallback(({ quizId, isPublished }) => {
    updateQuiz({ quizId, isPublished });
  }, []);

  const onDialogClose = useCallback(() => {
    setModalOpen(false);
    setSelectedQuizId(null);
  }, []);

  const selectedQuiz = useMemo(
    () => allQuizes.find((q) => q.id === selectedQuizId),
    [allQuizes, selectedQuizId]
  );

  const columns = [
    {
      Header: localizations?.BOOK ?? "Book",
      accessor: "book",
      width: "20%",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <BookCell data={data} />,
    },
    {
      Header: localizations?.TYPE ?? "Type",
      accessor: "type",
    },
    {
      Header: localizations?.DURATION ?? "Duration",
      accessor: "duration",
    },
    {
      Header: localizations?.GRADE ?? "Grade",
      accessor: "grade",
    },
    {
      Header: localizations?.LEVEL ?? "Level",
      accessor: "level",
    },
    {
      Header: localizations?.ORDER ?? "Order",
      accessor: "order",
    },
    {
      Header: localizations?.PUBLISH ?? "Publish",
      accessor: "publish",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => (
        <PublishCell data={data} handlePublishChange={handlePublishChange} />
      ),
    },
    {
      Header: localizations?.ACTIONS ?? "Actions",
      accessor: "preview",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => (
        <SuiBox mx={1} display="flex">
          <Tooltip title={localizations?.PREVIEW ?? "Preview"} placement="left">
            <IconButton
              aria-label={localizations?.PREVIEW}
              onClick={(e) => {
                e.preventDefault();
                setModalOpen(true);
                setSelectedQuizId(data.id);
              }}
            >
              <FileSearch size={32} weight="duotone" color="#7493b6" />
            </IconButton>
          </Tooltip>
          <Tooltip title={localizations?.EDIT ?? "Edit"} placement="bottom">
            <IconButton
              aria-label={localizations?.EDIT}
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/quiz/edit",
                  state: { quizId: data.id },
                });
              }}
            >
              <Pencil size={32} weight="duotone" color="#7493b6" />
            </IconButton>
          </Tooltip>
        </SuiBox>
      ),
    },
  ];

  const getDataTableData = (quizList, filter, lang) => ({
    columns,
    rows:
      quizList && quizList.length > 0
        ? quizList
            .filter((q) => {
              let lowercaseFilter = "";
              if (filter && filter !== "") {
                lowercaseFilter = filter.toLocaleLowerCase(lang);
              }
              return (
                q.book?.title?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                (localizations[q.type] ?? q.type)
                  ?.toLocaleLowerCase(lang)
                  ?.includes(lowercaseFilter) ||
                (localizations[q.level] ?? q.level)
                  ?.toLocaleLowerCase(lang)
                  ?.includes(lowercaseFilter)
              );
            })
            .map((q) => ({
              book: [
                {
                  coverImage: q.book.coverImage,
                  fontWeight: "medium",
                  title: q.book.title,
                },
              ],
              type: localizations[q.type] ?? q.type,
              duration: q.duration,
              grade: localizations[q.grade] ?? q.grade,
              level: localizations[q.level] ?? q.level,
              order: q.quizOrder,
              publish: [{ id: q.id, checked: q.isPublished }, handlePublishChange],
              preview: [{ id: q.id }],
            }))
        : [],
  });

  const memoizedTableData = useMemo(
    () => getDataTableData(allQuizes, quizFilter, language),
    [allQuizes, quizFilter, language]
  );

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            {/* <Grid item lg={12}> */}
            <Grid item sm={7} xs={12}>
              <h3>{localizations?.QUIZ_LIST_HEADER}</h3>
            </Grid>
            <Grid item sm={5} xs={12}>
              <SuiInput
                size="small"
                type="search"
                placeholder={localizations?.QUIZ_FILTER_INPUT_PLACEHOLDER}
                icon={{ component: <FunnelSimple />, direction: "left" }}
                value={quizFilter}
                onChange={(e) => {
                  setQuizFilter(e.target.value);
                }}
              />{" "}
            </Grid>
            <Grid item lg={12} mt={3} xs={12}>
              <DataTable
                table={memoizedTableData}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25],
                }}
                // canSearch
                pagination={{ variant: "contained", color: "dark" }}
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
        </SuiBox>
      </DashboardLayout>
      <QuizPreviewDialog open={modalOpen} onClose={onDialogClose} quiz={selectedQuiz} />
    </>
  );
};

QuizList.defaultProps = {
  allQuizes: [],
  message: null,
  localizations: null,
  language: "TR",
};

QuizList.propTypes = {
  allQuizes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  message: PropTypes.objectOf(PropTypes.any),
  startScreen: PropTypes.func.isRequired,
  removeErrorMsg: PropTypes.func.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  updateQuiz: PropTypes.func.isRequired,
  language: PropTypes.string,
};

const mapStateToProps = (state) => ({
  allQuizes:
    state.data.quizes !== undefined && state.data.quizes !== null
      ? Object.values(state.data.quizes)
      : [],
  message: state.message[ALL_QUIZES],
  localizations: state.localization?.pairs,
  language: state.localization?.language ?? "TR",
});

const mapDispatchToProps = {
  startScreen: startAllQuizesScreen,
  removeErrorMsg: removeMessage,
  updateQuiz: updateQuizOnAllQuizes,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
