/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import ReactAudioPlayer from "react-audio-player";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import ImageKit from "imagekit-javascript";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import { grey } from "@mui/material/colors";
import { connect } from "react-redux";
import { savePageInfo } from "redux/actions/pages";
import { Divider } from "@mui/material";

function CoverInfo({
  name,
  firstStep,
  lastStep,
  handleBack,
  handleNext,
  number,
  sequence,
  currentBookId,
  pages,
  savePage,
}) {
  const [imageUrl, setImageUrl] = useState("");
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [soundUrl, setSoundUrl] = useState("");
  const [soundFileName, setSoundFileName] = useState("");
  const [soundUploadLoading, setSoundUploadLoading] = useState(false);

  const onFileInputChange = (event, isImage = true) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      if (isImage) {
        setImageUploadLoading(true);
      } else {
        setSoundUploadLoading(true);
      }
      const imagekit = new ImageKit({
        publicKey: "public_qaLsRdm60abMeMnCPrq6XYAc0Gw=",
        urlEndpoint: "https://ik.imagekit.io/mvnw0kw1mga",
        authenticationEndpoint: "https://okumaplatformu.com/api/upload/imagekit-endpoint",
      });

      const file = files[0];

      if (isImage) {
        imagekit.upload(
          {
            file,
            fileName: file.name,
          },
          (err, result) => {
            if (err) {
              // eslint-disable-next-line no-console
              console.log("Error on imagekit.upload");
              // eslint-disable-next-line no-console
              console.log(err);
              return;
            }
            setImageUrl(result.url);
            setImageUploadLoading(false);
          }
        );
      } else {
        // CODE HERE
        const fileNameArray = file.name.split(".");
        let extention;
        if (fileNameArray && fileNameArray.length > 1) {
          extention = fileNameArray[fileNameArray.length - 1];
        }
        const folderName = "edictsoundfiles";

        axios
          .get(
            `https://okumaplatformu.com/api/upload/get-do-presigned-url?type=audio/mpeg&folder=${folderName}&extention=${extention}`
          )
          .then((res) => {
            const { key, url } = res.data;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", `${url}`);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.setRequestHeader("x-amz-acl", "public-read");
            xhr.send(file);
            // setSoundUploadLoading(true);

            xhr.onload = (e) => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  // eslint-disable-next-line no-console
                  // console.log("Response Url:", xhr.responseURL.split("?")[0]);
                  setSoundUrl(`https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`);
                  setSoundFileName(file.name);
                } else {
                  // eslint-disable-next-line no-console
                  console.error(xhr.statusText);
                }
              }
            };

            xhr.onerror = (e) => {
              // eslint-disable-next-line no-console
              console.error("on sound upload error");
              // eslint-disable-next-line no-console
              console.error(xhr.statusText);
            };

            xhr.onloadend = (ev) => {
              setSoundUploadLoading(false);
            };
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    const currentPage = pages?.find((el) => el.pageSequence === sequence);

    if (currentPage) {
      setImageUrl(currentPage.image);
      setSoundUrl(currentPage.sound);
    }
  }, [pages, sequence]);

  const handleSubmit = () => {
    // If there is no bookId, do nothing
    if (!currentBookId || currentBookId < 1) {
      return;
    }

    // If there is no image uploaded , do nothing
    if (!imageUrl || imageUrl === "") {
      return;
    }

    if (!lastStep) {
      handleNext();
    }

    const index = pages?.findIndex((el) => el.pageSequence === sequence);
    let params;
    if (index >= 0) {
      params = { id: pages[index].id };
    } else {
      params = {};
    }

    savePage({
      ...params,
      bookId: currentBookId,
      pageSequence: sequence,
      pageNumber: number,
      image: imageUrl,
      sound: soundUrl,
    });

    if (!lastStep) {
      setImageUrl("");
      setImageUploadLoading(false);
      setSoundUrl("");
      setSoundUploadLoading(false);
    }
  };

  return (
    <>
      <SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SuiBox
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              pr={2}
              style={{
                borderRightColor: grey[200],
                borderRightWidth: "1px",
                borderRightStyle: "solid",
              }}
            >
              <SuiTypography variant="h5" align="left" textTransform="capitalize">
                {name}
              </SuiTypography>
              <SuiTypography align="left" variant="button" fontWeight="regular" color="text" mt={0}>
                Cover pages consist of images only.
              </SuiTypography>

              <SuiBox mt={5}>
                <SuiButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  component="label"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                >
                  {imageUploadLoading ? (
                    <CircularProgress color="primary" size="1rem" />
                  ) : (
                    "Upload Image"
                  )}
                  <input
                    name="file"
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={onFileInputChange}
                  />
                </SuiButton>
              </SuiBox>

              {sequence === 1 ? (
                <SuiBox mt={5}>
                  <SuiButton
                    variant="outlined"
                    color="dark"
                    size="small"
                    component="label"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    {soundUploadLoading ? (
                      <CircularProgress color="primary" size="1rem" />
                    ) : (
                      "Upload Sound"
                    )}
                    <input
                      name="file"
                      type="file"
                      accept="audio/*"
                      hidden
                      onChange={(e) => onFileInputChange(e, false)}
                    />
                  </SuiButton>
                </SuiBox>
              ) : null}
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SuiBox display="flex" flexDirection="column" alignItems="stretch">
              <SuiTypography variant="h5" align="left" textTransform="capitalize">
                Preview
              </SuiTypography>
              <SuiTypography align="left" variant="button" fontWeight="regular" color="text" mt={0}>
                {" "}
              </SuiTypography>
              <SuiBox mt={2}>
                {imageUploadLoading ? (
                  <CircularProgress color="primary" size="4rem" />
                ) : imageUrl !== "" ? (
                  <img
                    src={imageUrl}
                    alt="Kitap kapak dış ya da iç imajı"
                    style={{ objectFit: "fill", width: "100%", maxWidth: "100%" }}
                  />
                ) : (
                  <SuiBox />
                )}
              </SuiBox>
              {sequence === 1 ? (
                <SuiBox mt={5}>
                  {soundUploadLoading ? (
                    <CircularProgress color="primary" size="4rem" />
                  ) : soundUrl !== "" ? (
                    <>
                      <SuiTypography
                        align="left"
                        variant="h6"
                        fontWeight="regular"
                        color="text"
                        mt={0}
                      >
                        {soundFileName}
                      </SuiTypography>
                      <ReactAudioPlayer src={soundUrl} controls />
                    </>
                  ) : (
                    <SuiBox />
                  )}
                </SuiBox>
              ) : null}
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={5}>
        <Divider />
      </SuiBox>
      <SuiBox mt={5} width="100%" display="flex" justifyContent="space-between">
        {firstStep ? (
          <SuiBox />
        ) : (
          <SuiButton
            disabled={imageUploadLoading}
            variant="outlined"
            size="small"
            color="secondary"
            onClick={handleBack}
          >
            previous page
          </SuiButton>
        )}
        <SuiBox ml={1}>
          <SuiButton
            variant="outlined"
            disabled={imageUploadLoading || soundUploadLoading}
            color="dark"
            size="small"
            onClick={handleSubmit}
          >
            {lastStep ? "save last page" : "save and go next page"}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </>
  );
}

CoverInfo.propTypes = {
  name: PropTypes.string.isRequired,
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  sequence: PropTypes.number.isRequired,
  currentBookId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pages: PropTypes.array,
  savePage: PropTypes.func.isRequired,
};

CoverInfo.defaultProps = {
  pages: [],
};

const mapStateToProps = (state) => {
  const { currentBookId } = state.data;
  let pages = [];
  if (currentBookId && currentBookId > 0) {
    pages = state.data.books[currentBookId].pages;
  }
  return {
    currentBookId,
    pages,
  };
};

const mapDispatchToProps = {
  savePage: savePageInfo,
  // startScreen: startCoverInfoScreen,
  // getLookups: getBookFormLookups,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoverInfo);
