import React from "react";
import { useLocation } from "react-router-dom";

import BookDetails from "layouts/book/components/book-details";

const EditBook = () => {
  const location = useLocation();
  return <BookDetails bookId={location.value?.id} />;
};

export default EditBook;
