/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/quiz/components/quiz-details/schemas/quizDetailsForm";

const {
  formField: {
    book,
    quizType,
    duration,
    quizGrade,
    quizLevel,
    quizOrder,
    isQuizPublished,
    isQuizPrimary,
  },
} = checkout;

export default {
  [book.name]: null,
  [quizType.name]: null,
  [duration.name]: 0,
  [quizGrade.name]: null,
  [quizLevel.name]: null,
  [quizOrder.name]: 0,
  [isQuizPublished.name]: false,
  [isQuizPrimary.name]: false,
};
