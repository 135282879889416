/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { Formik, Form } from "formik";
import initialValues from "layouts/quiz/components/quiz-details/schemas/quizDetailsFormInitialValues";
import validations from "layouts/quiz/components/quiz-details/schemas/quizDetailsFormValidations";
import initialForm from "layouts/quiz/components/quiz-details/schemas/quizDetailsForm";
import localizeForm from "layouts/quiz/components/quiz-details/schemas/quizDetailsLocalizeForm";
import { connect } from "react-redux";
import {
  startNewQuizScreen,
  getQuizFormLookups,
  saveQuizInfo,
  saveUnsavedQuiz,
  GET_QUIZ_FORM_LOOKUPS,
} from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import MessageTypes from "enums/message-types";
import Swal from "sweetalert2";
import QuizTitle from "../QuizTitle";
import QuizGeneralInfo from "../QuizGeneralInfo";
import QuestionContainer from "../QuestionContainer";

const QuizDetails = ({
  quiz,
  message,
  allBooks,
  startScreen,
  getLookups,
  removeNotificationMsg,
  saveQuiz,
  unsavedQuiz,
  saveUnsavedData,
  localizations,
}) => {
  const history = useHistory();
  const [form, setForm] = useState(initialForm);
  const [showQuestionsSection, setShowQuestionsSection] = useState(false);

  useEffect(() => {
    startScreen();
    getLookups();
  }, []);

  useEffect(() => {
    if (localizations) {
      setForm(localizeForm(form, localizations));
    }
  }, [localizations]);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title:
          message.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_GO_QUIZ_LIST_CONFIRM_BTN,
      }).then(() => {
        removeNotificationMsg(GET_QUIZ_FORM_LOOKUPS);
        history.push("/quiz");
      });
    }
  }, [message]);

  useEffect(() => {
    if (unsavedQuiz?.id > 0) {
      setShowQuestionsSection(true);
    }
  }, [unsavedQuiz?.id]);

  useEffect(() => {
    if (quiz && quiz.id > 0) {
      let questions;
      let questionOrder;
      if (unsavedQuiz?.id === quiz.id) {
        questions = { ...unsavedQuiz?.questions };
        questionOrder = [...unsavedQuiz?.questionOrder];
      } else {
        questions = Object.assign(
          {},
          ...quiz.questions.map((item) => ({
            [item.id]: {
              questionId: item.id,
              questionType: item.questionType,
              questionText: item.text,
              questionImageUrl: item.imageUrl,
              questionVideoUrl: item.videoUrl,
              questionAudioUrl: item.audioUrl,
              choices: Object.assign(
                {},
                ...item.choices.map((ch) => ({
                  [ch.id]: {
                    choiceId: ch.id,
                    choiceText: ch.text,
                    choiceImageUrl: ch.imageUrl,
                    choiceAudioUrl: ch.audioUrl,
                    isCorrect: ch.isCorrectAnswer,
                  },
                }))
              ),
              choiceOrder: item.choices
                .sort((a, b) => a.choiceOrder - b.choiceOrder)
                .map((ch) => ch.id),
            },
          }))
        );
        questionOrder = quiz.questions
          .sort((a, b) => a.questionOrder - b.questionOrder)
          .map((q) => q.id);
      }
      // This is Edit case
      const editUnsavedQuiz = {
        id: quiz.id,
        book: { ...quiz.book },
        type: quiz.type,
        duration: quiz.duration,
        grade: quiz.grade,
        level: quiz.level,
        quizOrder: quiz.quizOrder,
        isPublished: quiz.isPublished,
        isPrimary: quiz.isPrimary,
        questions,
        questionOrder,
      };

      saveUnsavedData({ unsavedQuiz: editUnsavedQuiz });
    } else {
      // This is Create case
      const initialUnsavedQuiz = {
        book: null,
        type: null,
        duration: 0,
        grade: null,
        level: null,
        quizOrder: 0,
        isPublished: false,
        isPrimary: false,
      };

      saveUnsavedData({ unsavedQuiz: initialUnsavedQuiz });
    }
  }, [quiz]);

  const getInitialValues = () => {
    if (unsavedQuiz) {
      return {
        book: unsavedQuiz.book
          ? { value: unsavedQuiz.book?.id, label: unsavedQuiz.book?.title }
          : null,
        quizType: unsavedQuiz.type
          ? { value: unsavedQuiz.type, label: localizations[unsavedQuiz.type] ?? unsavedQuiz.type }
          : null,
        duration: unsavedQuiz.duration ?? 0,
        quizGrade: unsavedQuiz.grade
          ? {
              value: unsavedQuiz.grade,
              label: localizations[unsavedQuiz.grade] ?? unsavedQuiz.grade,
            }
          : null,
        quizLevel: unsavedQuiz.level
          ? {
              value: unsavedQuiz.level,
              label: localizations[unsavedQuiz.level] ?? unsavedQuiz.level,
            }
          : null,
        quizOrder: unsavedQuiz.quizOrder ?? 0,
        isQuizPublished: unsavedQuiz.isPublished ?? false,
        isQuizPrimary: unsavedQuiz.isPrimary ?? false,
      };
    }
    return initialValues;
  };

  const handleSubmit = (values, actions) => {
    const formattedValues = {
      bookId: values.book?.value,
      type: values.quizType?.value,
      duration: values.duration,
      grade: values.quizGrade?.value,
      level: values.quizLevel?.value,
      order: values.quizOrder,
      isPublished: values.isQuizPublished,
      isPrimary: values.isQuizPrimary,
    };

    if (unsavedQuiz && unsavedQuiz.id && unsavedQuiz.id > 0) {
      formattedValues.id = unsavedQuiz.id;
    }

    saveQuiz({ quiz: formattedValues });
    actions.setSubmitting(false);
  };

  const checkIsInfoChanged = (values) => {
    if (
      (values && values.book?.value !== unsavedQuiz?.book?.id) ||
      values.quizType?.value !== unsavedQuiz?.type ||
      values.duration !== unsavedQuiz?.duration ||
      values.quizGrade?.value !== unsavedQuiz?.grade ||
      values.quizLevel?.value !== unsavedQuiz?.level ||
      values.quizOrder !== unsavedQuiz?.quizOrder ||
      values.isQuizPublished !== unsavedQuiz?.isPublished ||
      values.isQuizPrimary !== unsavedQuiz?.isPrimary
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        validationSchema={validations.validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => {
          let book = null;
          if (
            values &&
            values.book &&
            values.book.value &&
            allBooks &&
            allBooks[values.book.value]
          ) {
            book = allBooks[values.book.value];
          }
          return (
            <Form id={form.formId} autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <QuizTitle
                    cover={book?.pages?.find((p) => p.pageSequence === 1)?.image}
                    bookTitle={book?.title ?? ""}
                    quizType={
                      localizations[values?.quizType?.value] ?? values?.quizType?.value ?? ""
                    }
                    duration={values?.duration ?? 0}
                    {...form.formField.isQuizPublished}
                    value={values.isQuizPublished}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Card sx={{ overflow: "visible" }}>
                    <SuiBox p={3}>
                      <QuizGeneralInfo
                        formData={{
                          values,
                          touched,
                          formField: form.formField,
                          setFieldValue,
                          setFieldTouched,
                          handleBlur,
                          handleChange,
                          errors,
                        }}
                      />
                      <SuiBox display="flex" my={5}>
                        <SuiBox ml="auto">
                          <SuiButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="dark"
                            size="small"
                          >
                            {localizations?.FORM_SAVE_BTN}
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Grid>
                {showQuestionsSection ? (
                  <Grid item xs={12} lg={12}>
                    <QuestionContainer isInfoChanged={checkIsInfoChanged(values)} />
                  </Grid>
                ) : null}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

QuizDetails.defaultProps = {
  message: null,
  allBooks: null,
  unsavedQuiz: null,
  quiz: null,
  localizations: null,
};

QuizDetails.propTypes = {
  message: PropTypes.objectOf(PropTypes.any),
  startScreen: PropTypes.func.isRequired,
  getLookups: PropTypes.func.isRequired,
  removeNotificationMsg: PropTypes.func.isRequired,
  allBooks: PropTypes.objectOf(PropTypes.any),
  saveQuiz: PropTypes.func.isRequired,
  unsavedQuiz: PropTypes.objectOf(PropTypes.any),
  quiz: PropTypes.objectOf(PropTypes.any),
  saveUnsavedData: PropTypes.func.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  allBooks: state.data.books,
  message: state.message[GET_QUIZ_FORM_LOOKUPS],
  unsavedQuiz: state.data.unsavedQuiz,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  startScreen: startNewQuizScreen,
  getLookups: getQuizFormLookups,
  removeNotificationMsg: removeMessage,
  saveQuiz: saveQuizInfo,
  saveUnsavedData: saveUnsavedQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizDetails);
