import { PAGES } from "redux/actions/pages/constants";

// feature name
export const HOME = `${PAGES} [Home]`;

// action types
export const START_HOME_SCREEN = `${HOME} START`;

// action creators
export const startHomeScreen = () => ({
  type: START_HOME_SCREEN,
});
