import axios from "axios";

import { API_REQUEST, apiSuccess, apiError } from "redux/actions/api";

export const apiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type.includes(API_REQUEST)) {
      const { url, method, feature, token } = action.meta;

      let bearerToken = "";

      if (token === undefined || token === null || token === "") {
        // try to get token from state
        bearerToken = getState().auth.token;
      } else {
        bearerToken = token;
      }

      if (bearerToken !== undefined && bearerToken !== null && bearerToken !== "") {
        // eslint-disable-next-line dot-notation
        axios.defaults.headers.common["Authorization"] = `Bearer ${bearerToken}`;
      } else {
        // eslint-disable-next-line dot-notation
        delete axios.defaults.headers.common["Authorization"];
      }

      const userInfo = getState().header;

      axios[method.toLowerCase()](url, action.payload, {
        withCredentials: true,
        headers: { ...userInfo },
      })
        .then((response) => {
          if (response.data.status === "success") {
            dispatch(apiSuccess({ response: response.data.data, feature }));
          } else if (response.data.status === "error") {
            dispatch(apiError({ error: response.data.message, feature }));
          } else {
            // eslint-disable-next-line no-console
            console.log("Error occured on response:", response);
            dispatch(apiError({ error: "Error occured", feature }));
          }
        })
        .catch((error) => {
          dispatch(apiError({ error: error?.response?.data, feature }));
        });
    }
  };
