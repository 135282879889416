/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import CustomFormField from "components/CustomFormField";
import { connect } from "react-redux";
import { QUIZ_TYPES, QUIZ_LEVELS, GRADES } from "redux/actions/constants";

const QuizGeneralInfo = ({ formData, lookups, allBooks, localizations }) => {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
  } = formData;

  const {
    book: { errorMsg: _book, ...bookRest },
    quizType: { errorMsg: _qt, ...quizTypeRest },
    duration: { errorMsg: _dur, ...durationRest },
    quizGrade: { errorMsg: _qg, ...quizGradeRest },
    quizLevel: { errorMsg: _ql, ...quizLevelRest },
    quizOrder: { errorMsg: _qo, ...quizOrderRest },
    isQuizPrimary,
  } = formField;

  const {
    book: bookV,
    quizType: quizTypeV,
    duration: durationV,
    quizGrade: quizGradeV,
    quizLevel: quizLevelV,
    quizOrder: quizOrderV,
    isQuizPrimary: isQuizPrimaryV,
  } = values;

  return (
    <>
      <SuiTypography variant="h5">Quiz Genel Detayları</SuiTypography>
      <SuiBox mt={1} mb={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...bookRest}
              options={
                allBooks &&
                allBooks !== undefined &&
                allBooks !== null &&
                Object.values(allBooks).length > 0
                  ? Object.values(allBooks).map((b) => ({
                      value: b.id,
                      label: b.title,
                    }))
                  : []
              }
              value={bookV}
              error={errors.book?.length > 0 && !(touched.book !== true)}
              success={bookV && !errors.book}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...quizTypeRest}
              options={lookups.l_quizTypes?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
              }))}
              value={quizTypeV}
              error={errors.quizType?.length > 0 && !(touched.quizType !== true)}
              success={quizTypeV && !errors.quizType}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...durationRest}
              value={durationV}
              error={errors.duration?.length > 0 && !(touched.duration !== true)}
              success={durationV > 0 && !errors.duration}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...quizGradeRest}
              options={lookups.l_grades?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
              }))}
              value={quizGradeV}
              error={errors.quizGrade?.length > 0 && !(touched.quizGrade !== true)}
              success={quizGradeV && !errors.quizGrade}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...quizLevelRest}
              options={lookups.l_quizLevels?.lookupValues?.map((lv) => ({
                value: lv.lookupValueKey,
                label: localizations[lv.lookupValueKey] ?? lv.lookupValueKey,
              }))}
              value={quizLevelV}
              error={errors.quizLevel?.length > 0 && !(touched.quizLevel !== true)}
              success={quizLevelV && !errors.quizLevel}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...quizOrderRest}
              value={quizOrderV}
              error={errors.quizOrder?.length > 0 && !(touched.quizOrder !== true)}
              success={quizOrderV > 0 && !errors.quizOrder}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormField
              {...isQuizPrimary}
              value={isQuizPrimaryV}
              setFieldValue={setFieldValue}
              inForm
            />
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

QuizGeneralInfo.defaultProps = {
  lookups: null,
  allBooks: null,
  localizations: null,
};

QuizGeneralInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  lookups: PropTypes.objectOf(PropTypes.any),
  allBooks: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => {
  let lookups = {};
  if (state.data.lookups && Object.keys(state.data.lookups).length !== 0) {
    lookups = {
      l_quizTypes: state.data.lookups[QUIZ_TYPES],
      l_grades: state.data.lookups[GRADES],
      l_quizLevels: state.data.lookups[QUIZ_LEVELS],
    };
  }

  return { lookups, allBooks: state.data.books, localizations: state.localization?.pairs };
};

export default connect(mapStateToProps, null)(QuizGeneralInfo);
