import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Alert from "custom/Alert";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import SuiBox from "components/SuiBox";
import MaterialDetails from "../components/material-details";

const NewMaterial = ({ redirectTo }) => {
  const history = useHistory();

  useEffect(() => {
    if (redirectTo && redirectTo !== "" && redirectTo !== "/material/new") {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox mt={3} mb={8}>
          <MaterialDetails />
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

NewMaterial.defaultProps = {
  redirectTo: "",
};

NewMaterial.propTypes = {
  redirectTo: PropTypes.string,
};

const mapStateToProps = (state) => ({
  redirectTo: state.ui.redirectTo,
});

export default connect(mapStateToProps, null)(NewMaterial);
