import _ from "lodash";
import FinderTypes from "enums/finder-types";
import StateOperationTypes from "enums/state-operation-types";

export class StateHelper {
  static stateManipulator(state, operation, finder, value) {
    const copyOfState = { ...state };

    if (!finder) {
      return copyOfState;
    }

    const copyOfFinder = [...finder];
    if (copyOfFinder.length === 0) {
      return copyOfState;
    }

    const path = [];
    copyOfFinder.forEach((f, index) => {
      switch (f.type) {
        case FinderTypes.object:
          path.push(f.where);

          if (f.location && f.location.key) {
            path.push(f.location.key);
          }

          break;
        case FinderTypes.array:
          path.push(f.where);
          if (f.location !== null && f.location !== undefined) {
            const itemIndex = _.get(copyOfState, path)?.findIndex(
              (item) => item[f.location.key] === f.location.value
            );

            if (itemIndex >= 0) {
              path.push(itemIndex);
            }
          } else if (index === copyOfFinder.length - 1) {
            const itemIndex = _.get(copyOfState, path)?.length;

            if (itemIndex !== null && itemIndex !== undefined && itemIndex >= 0) {
              path.push(itemIndex);
            }
          }
          break;
        default:
          // Do nothing
          break;
      }
    });

    switch (operation) {
      case StateOperationTypes.append:
        if (typeof value === "object") {
          _.set(copyOfState, path, { ...value });
        } else {
          _.set(copyOfState, path, value);
        }
        return copyOfState;
      default:
        return copyOfState;
    }
  }
}
