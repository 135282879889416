import BookList from "layouts/book/book-list";
import EditBook from "layouts/book/edit-book";
import NewBook from "layouts/book/new-book";
import QuizList from "layouts/quiz/quiz-list";
import EditQuiz from "layouts/quiz/edit-quiz";
import NewQuiz from "layouts/quiz/new-quiz";
import MaterialList from "layouts/material/material-list";
import EditMaterial from "layouts/material/edit-material";
import NewMaterial from "layouts/material/new-material";

export const screenComponentMapping = {
  LIST_BOOK: BookList,
  ADD_BOOK: NewBook,
  EDIT_BOOK: EditBook,
  LIST_QUIZ: QuizList,
  ADD_QUIZ: NewQuiz,
  EDIT_QUIZ: EditQuiz,
  LIST_MATERIAL: MaterialList,
  ADD_MATERIAL: NewMaterial,
  EDIT_MATERIAL: EditMaterial,
};
