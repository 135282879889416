export default {
  formId: "page-info-form",
  formField: {
    imageStyle: {
      name: "imageStyle",
      label: "Image Style",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Select an image style",
      errorMsg: "Image Style is required.",
    },
    textStyle: {
      name: "textStyle",
      label: "Text Style",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Select an text style",
      errorMsg: "Text Style is required.",
    },
    textContent: {
      name: "textContent",
      label: "Text Content",
      type: "editor",
      errorMsg: "Text content is required.",
    },
  },
};
