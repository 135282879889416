export const TOKEN_STORAGE_KEY = "EdictDigitalToken";
export const LANGUAGE_STORAGE_KEY = "EdictDigitalLanguage";

export const BASE_URL = "https://okumaplatformu.com"; // Backend
export const ROOT_URL = "edict.okumaplatformu.com";
export const PRODUCT_TYPE = "FRONTEND-EDICT";
export const MAIN_FRONTEND_URL = "https://okumaplatformu.com";
export const ACCOUNTS_DASHBOARD_URL = "https://accounts.okumaplatformu.com";

export const CLIENT = "EDICT";

// LOOKUP KEYS
export const AUTHORS = "AUTHORS";
export const ILLUSTRATORS = "ILLUSTRATORS";
export const TRANSLATORS = "TRANSLATORS";
export const LANGUAGES = "LANGUAGES";
export const GRADES = "GRADES";
export const SERIES = "SERIES";
export const LEVELS = "LEVELS";
export const CATEGORIES = "CATEGORIES";
export const GENRES = "GENRES";
export const KEYWORDS = "KEYWORDS";
export const SUBJECT_AREAS = "SUBJECTS";
export const USES = "USES";
export const PUBLISHERS = "PUBLISHERS";
export const TEXT_STYLES = "TEXT-STYLES";
export const IMAGE_STYLES = "IMAGE-STYLES";
export const QUIZ_TYPES = "QUIZ-TYPES";
export const QUIZ_LEVELS = "QUIZ-LEVELS";
export const COURSES = "COURSES";
export const SKILLS = "SKILLS";
export const UNITS = "UNITS";
export const OBJECTIVES = "OBJECTIVES";
export const SPECIAL_DAYS = "SPECIAL_DAYS";
export const MATERIAL_LEVELS = "MATERIAL-LEVELS";
export const DOCUMENT_TYPES = "DOCUMENT-TYPES";
export const LEARNING_DESIGN_TYPES = "LEARNING-DESIGN-TYPES";
