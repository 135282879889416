/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "quiz-details-form",
  formField: {
    book: {
      name: "book",
      label: "Kitap",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen bir kitap seçiniz.",
      errorMsg: "Kitap seçimi zorunlu.",
    },

    quizType: {
      name: "quizType",
      label: "Quiz Türü",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen bir quiz türü seçiniz.",
      errorMsg: "Quiz türü seçimi zorunlu.",
    },

    duration: {
      name: "duration",
      label: "Süre (dk)",
      type: "number",
      placeholder: "örn. 6",
      size: "large",
      errorMsg: "Süre en az 1 dk olmalıdır.",
    },

    quizGrade: {
      name: "quizGrade",
      label: "Sınıf",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen sınıf seçiniz.",
      errorMsg: "Sınıf seçimi zorunlu.",
    },

    quizLevel: {
      name: "quizLevel",
      label: "Seviye",
      type: "select",
      size: "large",
      isMulti: false,
      placeholder: "Lütfen seviye seçiniz.",
      errorMsg: "Seviye seçimi zorunlu.",
    },

    quizOrder: {
      name: "quizOrder",
      label: "Sıra",
      type: "number",
      placeholder: "örn. 1",
      size: "large",
      errorMsg: "Sıra numarası 0'dan büyük olmalıdır.",
    },

    isQuizPublished: {
      name: "isQuizPublished",
      label: "Yayınla",
      type: "switch",
    },

    isQuizPrimary: {
      name: "isQuizPrimary",
      label: "Temel Quiz (Kişisel çalışmada görünür.)",
      type: "switch",
    },
  },
};
