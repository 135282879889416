import * as Yup from "yup";
import checkout from "layouts/book/components/PageInfo/schema/pageInfoForm";

const {
  formField: { imageStyle, textStyle, textContent },
} = checkout;

export default {
  validation: Yup.object().shape({
    [imageStyle.name]: Yup.object().nullable().required(imageStyle.errorMsg),
    [textStyle.name]: Yup.object().nullable().required(textStyle.errorMsg),
    [textContent.name]: Yup.string()
      .required(textContent.errorMsg)
      .test("is really empty?", textContent.errorMsg, (val) => val !== "<p><br></p>"),
  }),
};
