/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { Grid, Card } from "@mui/material";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

import initialValues from "layouts/material/components/material-details/schemas/materialDetailsFormInitialValues";
import validations from "layouts/material/components/material-details/schemas/materialDetailsFormValidations";
import initialForm from "layouts/material/components/material-details/schemas/materialDetailsForm";
import localizeForm from "layouts/material/components/material-details/schemas/materialDetailsLocalizeForm";
import MessageTypes from "enums/message-types";
import { removeMessage } from "redux/actions/message";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import {
  startNewMaterialScreen,
  getMaterialFormLookups,
  GET_MATERIAL_FORM_LOOKUPS,
  saveMaterial,
} from "redux/actions/pages";
import MaterialTitle from "../MaterialTitle";
import MaterialForm from "../MaterialForm";

const MaterialDetails = ({
  material,
  allBooks,
  localizations,
  message,
  removeErrorMsg,
  startScreen,
  getLookups,
  saveNewMaterial,
}) => {
  const [form, setForm] = useState(initialForm);
  const [materialUploadLoading, setMaterialUploadLoading] = useState(false);
  const [materialUrl, setMaterialUrl] = useState("");
  const history = useHistory();

  useEffect(() => {
    startScreen();
    getLookups();

    if (material && material.id > 0) {
      setMaterialUrl(material.fileUrl);
    }
  }, []);

  useEffect(() => {
    if (localizations) {
      setForm(localizeForm(form, localizations));
    }
  }, [localizations]);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title:
          message.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText:
          localizations?.MESSAGE_GO_MATERIAL_LIST_CONFIRM_BTN ?? "Materyal listesine git",
      }).then(() => {
        removeErrorMsg(GET_MATERIAL_FORM_LOOKUPS);
        history.push("/material");
      });
    }
  }, [message]);

  const getInitialValues = () => {
    if (material && material.id > 0) {
      return {
        book: material.book ? { value: material.book?.id, label: material.book?.title } : null,
        grade: material.grade
          ? { value: material.grade, label: localizations[material.grade] ?? material.grade }
          : null,
        course: material.course
          ? { value: material.course, label: localizations[material.course] ?? material.course }
          : null,
        skill: material.skill
          ? { value: material.skill, label: localizations[material.skill] ?? material.skill }
          : null,
        unit: material.unit
          ? { value: material.unit, label: localizations[material.unit] ?? material.unit }
          : null,
        specialDay: material.specialDay
          ? {
              value: material.specialDay,
              label: localizations[material.specialDay] ?? material.specialDay,
            }
          : null,
        objectives:
          material.objectives && material.objectives !== ""
            ? material.objectives
                .split(",")
                .map((obj) => ({ value: obj, label: localizations[obj] ?? obj }))
            : [],
        duration: material.duration ?? 0,
        level: material.level
          ? { value: material.level, label: localizations[material.level] ?? material.level }
          : null,
        materialOrder: material.materialOrder ?? 0,
        documentType: material.documentType
          ? {
              value: material.documentType,
              label: localizations[material.documentType] ?? material.documentType,
            }
          : null,
        description: material.description ?? "",
        introduction: material.introduction ?? "",
        activity: material.activity ?? "",
        assessment: material.assessment ?? "",
        learningDesignType: material.learningDesignType
          ? {
              value: material.learningDesignType,
              label: localizations[material.learningDesignType] ?? material.learningDesignType,
            }
          : null,
        isPublished: material.isPublished ?? false,
      };
    }
    return initialValues;
  };

  const handleSubmit = (values, actions) => {
    if (!materialUrl || materialUrl === "") {
      Swal.fire({
        icon: "error",
        title: localizations?.MESSAGE_ERROR ?? "HATA!",
        text:
          localizations?.MESSAGE_NO_MATERIAL_ERROR_CONTENT ??
          "Materyal yüklemediniz. Lütfen materyal yükleyip tekrar Kaydet butonuna basınız.",
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? "Tamam",
      }).then(() => {
        // Do nothing
      });
      actions.setSubmitting(false);
      return;
    }
    const formattedValues = {
      bookId: values?.book?.value ?? null,
      grade: values?.grade?.value ?? null,
      course: values?.course?.value ?? null,
      skill: values?.skill?.value ?? null,
      unit: values?.unit?.value ?? null,
      specialDay: values?.specialDay?.value ?? null,
      objectives: values?.objectives?.map((v) => v.value).join(",") ?? "",
      duration: values?.duration ?? 0,
      level: values?.level?.value ?? null,
      materialOrder: values?.materialOrder ?? 0,
      documentType: values?.documentType?.value ?? null,
      description: values?.description ?? "",
      introduction: values?.introduction ?? "",
      activity: values?.activity ?? "",
      assessment: values?.assessment ?? "",
      learningDesignType: values?.learningDesignType?.value ?? null,
      isPublished: values?.isPublished ?? false,
      fileUrl: materialUrl,
    };

    // If there is a current book id, it is not create, it is update
    // so we have to send id
    if (material && material.id > 0) {
      formattedValues.id = material.id;
    }

    saveNewMaterial({ material: { ...formattedValues } });

    // saveGeneralInformation(formattedValues);
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const onMaterialChange = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 20) {
        Swal.fire({
          icon: "error",
          title: localizations?.MESSAGE_ERROR ?? "HATA!",
          text:
            localizations?.MESSAGE_MATERIAL_SIZE_ERROR_CONTENT ??
            "Yüklediğiniz materyal 20 MB boyutunu geçmektedir.",
          showCancelButton: false,
          confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? "Tamam",
        }).then(() => {
          // Do nothing
        });

        return;
      }

      setMaterialUploadLoading(true);

      const fileNameArray = file.name.split(".");
      let extention;
      if (fileNameArray && fileNameArray.length > 1) {
        extention = fileNameArray[fileNameArray.length - 1];
      }
      const folderName = "materials";

      axios
        .get(
          `https://okumaplatformu.com/api/upload/get-do-presigned-url?type=application/pdf&folder=${folderName}&extention=${extention}`
        )
        .then((res) => {
          const { key, url } = res.data;

          const xhr = new XMLHttpRequest();
          xhr.open("PUT", `${url}`);
          xhr.setRequestHeader("Content-Type", file.type);
          xhr.setRequestHeader("x-amz-acl", "public-read");
          xhr.send(file);
          // setSoundUploadLoading(true);

          xhr.onload = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                setMaterialUrl(`https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`);
              } else {
                // eslint-disable-next-line no-console
                console.error(xhr.statusText);
              }
            }
          };

          xhr.onerror = () => {
            // eslint-disable-next-line no-console
            console.error("on sound upload error");
            // eslint-disable-next-line no-console
            console.error(xhr.statusText);
          };

          xhr.onloadend = () => {
            setMaterialUploadLoading(false);
          };
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        validationSchema={validations.validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => {
          let book = null;
          if (
            values &&
            values.book &&
            values.book.value &&
            allBooks &&
            allBooks[values.book.value]
          ) {
            book = allBooks[values.book.value];
          }

          return (
            <Form id={form.formId} autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <MaterialTitle
                    cover={book?.pages?.find((p) => p.pageSequence === 1)?.image}
                    bookTitle={book?.title ?? ""}
                    learningDesignType={
                      localizations[values?.learningDesignType?.value] ??
                      values?.learningDesignType?.value ??
                      ""
                    }
                    duration={values?.duration ?? 0}
                    {...form.formField.isPublished}
                    value={values.isPublished}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Card sx={{ overflow: "visible" }}>
                    <SuiBox p={3}>
                      <MaterialForm
                        formData={{
                          values,
                          touched,
                          formField: form.formField,
                          setFieldValue,
                          setFieldTouched,
                          handleBlur,
                          handleChange,
                          errors,
                        }}
                        loading={materialUploadLoading}
                        url={materialUrl}
                        onMaterialRemove={() => setMaterialUrl("")}
                        onMaterialChange={onMaterialChange}
                      />
                      <SuiBox display="flex" my={5}>
                        <SuiBox ml="auto">
                          <SuiButton
                            disabled={isSubmitting || materialUploadLoading}
                            type="submit"
                            variant="contained"
                            color="dark"
                            size="small"
                          >
                            {localizations?.FORM_SAVE_BTN}
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

MaterialDetails.defaultProps = {
  allBooks: null,
  localizations: null,
  message: null,
  material: null,
};

MaterialDetails.propTypes = {
  allBooks: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  message: PropTypes.objectOf(PropTypes.any),
  removeErrorMsg: PropTypes.func.isRequired,
  startScreen: PropTypes.func.isRequired,
  getLookups: PropTypes.func.isRequired,
  saveNewMaterial: PropTypes.func.isRequired,
  material: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  allBooks: state.data.books,
  localizations: state.localization?.pairs,
  message: state.message[GET_MATERIAL_FORM_LOOKUPS],
});

const mapDispatchToProps = {
  removeErrorMsg: removeMessage,
  startScreen: startNewMaterialScreen,
  getLookups: getMaterialFormLookups,
  saveNewMaterial: saveMaterial,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialDetails);
