/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/book/components/book-details/schemas/generalInfoForm";

const {
  formField: {
    generalInfo,
    author,
    illustrator,
    translator,
    language,
    grade,
    series,
    level,
    orderInLevel,
    pageCount,
    wordCount,
    category,
    genre,
    subjectArea,
    keywords,
    use,
    title,
    publisher,
    isPublished,
  },
} = checkout;

export default {
  [generalInfo.name]: "",
  [author.name]: [],
  [illustrator.name]: [],
  [translator.name]: [],
  [language.name]: null,
  [grade.name]: [],
  [series.name]: null,
  [level.name]: null,
  [orderInLevel.name]: 0,
  [pageCount.name]: 0,
  [wordCount.name]: 0,
  [category.name]: null,
  [genre.name]: null,
  [subjectArea.name]: null,
  [keywords.name]: [],
  [use.name]: null,
  [title.name]: "",
  [publisher.name]: null,
  [isPublished.name]: false,
};
