/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  VALIDATE_EMAIL_TOKEN,
  startCreatePasswordPage,
  validateEmailToken,
  resetPassword,
} from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import MessageTypes from "enums/message-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import lock from "assets/images/illustrations/lock.png";

import Alert from "custom/Alert";
import Loading from "custom/Loading";

const CreatePassword = ({
  redirectTo,
  message,
  startPage,
  validate,
  reset,
  removeNotificationMsg,
}) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    startPage();
  }, []);

  useEffect(() => {
    if (redirectTo && redirectTo !== "" && redirectTo !== "/create-password") {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (token && token !== "") {
      validate({ token });
    }
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title: message.messageType === MessageTypes.success ? "BAŞARILI!" : "HATA!",
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: `Giriş sayfasına dön`,
      }).then(() => {
        removeNotificationMsg(VALIDATE_EMAIL_TOKEN);
        history.push("/signin");
      });
    }
    // eslint-disable-next-line
  }, [message]);

  const onResetPasswordClick = (e) => {
    e.preventDefault();

    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      reset({ password, password2, token });
    } else {
      // eslint-disable-next-line no-console
      console.log("Token yok!");
    }
  };
  return (
    <>
      <Alert />
      <Loading />
      <IllustrationLayout
        title="Yeni Şifre"
        description="Şifre ve şifre tekrarı birbiriniz aynısı olmalıdır."
        illustration={{
          image: lock,
          title: "Class Readers",
          description:
            "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
        }}
      >
        <SuiBox component="form" role="form">
          <SuiBox mb={2}>
            <SuiInput
              type="password"
              placeholder="Şifre"
              size="large"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiInput
              type="password"
              placeholder="Şifre Tekrarı"
              size="large"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              onClick={onResetPasswordClick}
            >
              ŞİFREYİ YENİLE
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </IllustrationLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state.ui.redirectTo,
  message: state.message[VALIDATE_EMAIL_TOKEN],
});

const mapDispatchToProps = {
  startPage: startCreatePasswordPage,
  validate: validateEmailToken,
  reset: resetPassword,
  removeNotificationMsg: removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
