const localizeForm = (
  formObject,
  {
    MATERIAL_FORM_BOOK_LABEL,
    MATERIAL_FORM_BOOK_PLACEHOLDER,
    MATERIAL_FORM_BOOK_ERROR,
    MATERIAL_FORM_GRADE_LABEL,
    MATERIAL_FORM_GRADE_PLACEHOLDER,
    MATERIAL_FORM_GRADE_ERROR,
    MATERIAL_FORM_COURSE_LABEL,
    MATERIAL_FORM_COURSE_PLACEHOLDER,
    MATERIAL_FORM_COURSE_ERROR,
    MATERIAL_FORM_SKILL_LABEL,
    MATERIAL_FORM_SKILL_PLACEHOLDER,
    MATERIAL_FORM_SKILL_ERROR,
    MATERIAL_FORM_UNIT_LABEL,
    MATERIAL_FORM_UNIT_PLACEHOLDER,
    MATERIAL_FORM_UNIT_ERROR,
    MATERIAL_FORM_SPECIALDAY_LABEL,
    MATERIAL_FORM_SPECIALDAY_PLACEHOLDER,
    MATERIAL_FORM_SPECIALDAY_ERROR,
    MATERIAL_FORM_OBJECTIVE_LABEL,
    MATERIAL_FORM_OBJECTIVE_PLACEHOLDER,
    MATERIAL_FORM_OBJECTIVE_ERROR,
    MATERIAL_FORM_DURATION_LABEL,
    MATERIAL_FORM_DURATION_PLACEHOLDER,
    MATERIAL_FORM_DURATION_ERROR,
    MATERIAL_FORM_LEVEL_LABEL,
    MATERIAL_FORM_LEVEL_PLACEHOLDER,
    MATERIAL_FORM_LEVEL_ERROR,
    MATERIAL_FORM_ORDER_LABEL,
    MATERIAL_FORM_ORDER_PLACEHOLDER,
    MATERIAL_FORM_ORDER_ERROR,
    MATERIAL_FORM_DOCUMENTTYPE_LABEL,
    MATERIAL_FORM_DOCUMENTTYPE_PLACEHOLDER,
    MATERIAL_FORM_DOCUMENTTYPE_ERROR,
    MATERIAL_FORM_DESCRIPTION_LABEL,
    MATERIAL_FORM_DESCRIPTION_PLACEHOLDER,
    MATERIAL_FORM_DESCRIPTION_ERROR,
    MATERIAL_FORM_INTRODUCTION_LABEL,
    MATERIAL_FORM_INTRODUCTION_PLACEHOLDER,
    MATERIAL_FORM_INTRODUCTION_ERROR,
    MATERIAL_FORM_ACTIVITY_LABEL,
    MATERIAL_FORM_ACTIVITY_PLACEHOLDER,
    MATERIAL_FORM_ACTIVITY_ERROR,
    MATERIAL_FORM_ASSESSMENT_LABEL,
    MATERIAL_FORM_ASSESSMENT_PLACEHOLDER,
    MATERIAL_FORM_ASSESSMENT_ERROR,
    MATERIAL_FORM_LEARNINGDESIGNTYPE_LABEL,
    MATERIAL_FORM_LEARNINGDESIGNTYPE_PLACEHOLDER,
    MATERIAL_FORM_LEARNINGDESIGNTYPE_ERROR,
    MATERIAL_FORM_ISPUBLISHED_LABEL,
  }
) => {
  const updatedForm = {
    ...formObject,
    formField: {
      ...formObject.formField,
      book: {
        ...formObject.formField.book,
        label: MATERIAL_FORM_BOOK_LABEL ?? formObject.formField.book.label,
        placeholder: MATERIAL_FORM_BOOK_PLACEHOLDER ?? formObject.formField.book.placeholder,
        errorMsg: MATERIAL_FORM_BOOK_ERROR ?? formObject.formField.book.errorMsg,
      },
      grade: {
        ...formObject.formField.grade,
        label: MATERIAL_FORM_GRADE_LABEL ?? formObject.formField.grade.label,
        placeholder: MATERIAL_FORM_GRADE_PLACEHOLDER ?? formObject.formField.grade.placeholder,
        errorMsg: MATERIAL_FORM_GRADE_ERROR ?? formObject.formField.grade.errorMsg,
      },
      course: {
        ...formObject.formField.course,
        label: MATERIAL_FORM_COURSE_LABEL ?? formObject.formField.course.label,
        placeholder: MATERIAL_FORM_COURSE_PLACEHOLDER ?? formObject.formField.course.placeholder,
        errorMsg: MATERIAL_FORM_COURSE_ERROR ?? formObject.formField.course.errorMsg,
      },
      skill: {
        ...formObject.formField.skill,
        label: MATERIAL_FORM_SKILL_LABEL ?? formObject.formField.skill.label,
        placeholder: MATERIAL_FORM_SKILL_PLACEHOLDER ?? formObject.formField.skill.placeholder,
        errorMsg: MATERIAL_FORM_SKILL_ERROR ?? formObject.formField.skill.errorMsg,
      },
      unit: {
        ...formObject.formField.unit,
        label: MATERIAL_FORM_UNIT_LABEL ?? formObject.formField.unit.label,
        placeholder: MATERIAL_FORM_UNIT_PLACEHOLDER ?? formObject.formField.unit.placeholder,
        errorMsg: MATERIAL_FORM_UNIT_ERROR ?? formObject.formField.unit.errorMsg,
      },
      specialDay: {
        ...formObject.formField.specialDay,
        label: MATERIAL_FORM_SPECIALDAY_LABEL ?? formObject.formField.specialDay.label,
        placeholder:
          MATERIAL_FORM_SPECIALDAY_PLACEHOLDER ?? formObject.formField.specialDay.placeholder,
        errorMsg: MATERIAL_FORM_SPECIALDAY_ERROR ?? formObject.formField.specialDay.errorMsg,
      },
      objectives: {
        ...formObject.formField.objectives,
        label: MATERIAL_FORM_OBJECTIVE_LABEL ?? formObject.formField.objectives.label,
        placeholder:
          MATERIAL_FORM_OBJECTIVE_PLACEHOLDER ?? formObject.formField.objectives.placeholder,
        errorMsg: MATERIAL_FORM_OBJECTIVE_ERROR ?? formObject.formField.objectives.errorMsg,
      },
      duration: {
        ...formObject.formField.duration,
        label: MATERIAL_FORM_DURATION_LABEL ?? formObject.formField.duration.label,
        placeholder:
          MATERIAL_FORM_DURATION_PLACEHOLDER ?? formObject.formField.duration.placeholder,
        errorMsg: MATERIAL_FORM_DURATION_ERROR ?? formObject.formField.duration.errorMsg,
      },
      level: {
        ...formObject.formField.level,
        label: MATERIAL_FORM_LEVEL_LABEL ?? formObject.formField.level.label,
        placeholder: MATERIAL_FORM_LEVEL_PLACEHOLDER ?? formObject.formField.level.placeholder,
        errorMsg: MATERIAL_FORM_LEVEL_ERROR ?? formObject.formField.level.errorMsg,
      },
      order: {
        ...formObject.formField.materialOrder,
        label: MATERIAL_FORM_ORDER_LABEL ?? formObject.formField.materialOrder.label,
        placeholder:
          MATERIAL_FORM_ORDER_PLACEHOLDER ?? formObject.formField.materialOrder.placeholder,
        errorMsg: MATERIAL_FORM_ORDER_ERROR ?? formObject.formField.materialOrder.errorMsg,
      },
      documentType: {
        ...formObject.formField.documentType,
        label: MATERIAL_FORM_DOCUMENTTYPE_LABEL ?? formObject.formField.documentType.label,
        placeholder:
          MATERIAL_FORM_DOCUMENTTYPE_PLACEHOLDER ?? formObject.formField.documentType.placeholder,
        errorMsg: MATERIAL_FORM_DOCUMENTTYPE_ERROR ?? formObject.formField.documentType.errorMsg,
      },
      description: {
        ...formObject.formField.description,
        label: MATERIAL_FORM_DESCRIPTION_LABEL ?? formObject.formField.description.label,
        placeholder:
          MATERIAL_FORM_DESCRIPTION_PLACEHOLDER ?? formObject.formField.description.placeholder,
        errorMsg: MATERIAL_FORM_DESCRIPTION_ERROR ?? formObject.formField.description.errorMsg,
      },
      introduction: {
        ...formObject.formField.introduction,
        label: MATERIAL_FORM_INTRODUCTION_LABEL ?? formObject.formField.introduction.label,
        placeholder:
          MATERIAL_FORM_INTRODUCTION_PLACEHOLDER ?? formObject.formField.introduction.placeholder,
        errorMsg: MATERIAL_FORM_INTRODUCTION_ERROR ?? formObject.formField.introduction.errorMsg,
      },
      activity: {
        ...formObject.formField.activity,
        label: MATERIAL_FORM_ACTIVITY_LABEL ?? formObject.formField.activity.label,
        placeholder:
          MATERIAL_FORM_ACTIVITY_PLACEHOLDER ?? formObject.formField.activity.placeholder,
        errorMsg: MATERIAL_FORM_ACTIVITY_ERROR ?? formObject.formField.activity.errorMsg,
      },
      assessment: {
        ...formObject.formField.assessment,
        label: MATERIAL_FORM_ASSESSMENT_LABEL ?? formObject.formField.assessment.label,
        placeholder:
          MATERIAL_FORM_ASSESSMENT_PLACEHOLDER ?? formObject.formField.assessment.placeholder,
        errorMsg: MATERIAL_FORM_ASSESSMENT_ERROR ?? formObject.formField.assessment.errorMsg,
      },
      learningDesignType: {
        ...formObject.formField.learningDesignType,
        label:
          MATERIAL_FORM_LEARNINGDESIGNTYPE_LABEL ?? formObject.formField.learningDesignType.label,
        placeholder:
          MATERIAL_FORM_LEARNINGDESIGNTYPE_PLACEHOLDER ??
          formObject.formField.learningDesignType.placeholder,
        errorMsg:
          MATERIAL_FORM_LEARNINGDESIGNTYPE_ERROR ??
          formObject.formField.learningDesignType.errorMsg,
      },
      isPublished: {
        ...formObject.formField.isPublished,
        label: MATERIAL_FORM_ISPUBLISHED_LABEL ?? formObject.formField.isPublished.label,
      },
    },
  };

  return updatedForm;
};

export default localizeForm;
