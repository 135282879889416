/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import ChoiceDraggable from "../ChoiceDraggable";

const ChoiceDroppable = ({ questionId, isDropDisabled, unsavedQuiz }) => (
  <Droppable droppableId={questionId.toString()} type="choice" isDropDisabled={isDropDisabled}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{
          padding: "5px",
          borderRadius: "8px",
          backgroundColor: snapshot.isDraggingOver ? "#CBDDF1" : "transparent",
          transition: "background-color 0.5s ease",
        }}
      >
        {unsavedQuiz?.questions[questionId]?.choiceOrder.map((c, index) => (
          <ChoiceDraggable key={c} questionId={questionId} choiceId={c} index={index} />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

ChoiceDroppable.defaultProps = {
  unsavedQuiz: null,
  isDropDisabled: false,
};

ChoiceDroppable.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unsavedQuiz: PropTypes.objectOf(PropTypes.any),
  isDropDisabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  unsavedQuiz: state.data.unsavedQuiz,
});

export default connect(mapStateToProps, null)(ChoiceDroppable);
