/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { connect } from "react-redux";
import { savePageInfo } from "redux/actions/pages";
import Grid from "@mui/material/Grid";
import { grey } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import ImageKit from "imagekit-javascript";
import { Divider } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import initialValues from "layouts/book/components/PageInfo/schema/pageInfoInitialValues";
import validations from "layouts/book/components/PageInfo/schema/pageInfoFormValidations";
import form from "layouts/book/components/PageInfo/schema/pageInfoForm";
import { Formik, Form } from "formik";
import CustomFormField from "components/CustomFormField";
import axios from "axios";

// LOOKUP KEYS
import { TEXT_STYLES, IMAGE_STYLES } from "redux/actions/constants";
import PagePreview from "../PagePreview";

function PageInfo({
  name,
  firstStep,
  lastStep,
  handleBack,
  handleNext,
  number,
  sequence,
  currentBookId,
  pages,
  savePage,
  lookups,
}) {
  const { formId, formField } = form;
  const {
    imageStyle: { errorMsg: _is, ...imageStyleRest },
    textStyle: { errorMsg: _ts, ...textStyleRest },
    textContent: { errorMsg: _tc, ...textContentRest },
  } = formField;

  // const [editorValue, setEditorValue] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [soundUrl, setSoundUrl] = useState("");
  const [soundFileName, setSoundFileName] = useState("");
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [soundUploadLoading, setSoundUploadLoading] = useState(false);
  const [imageStyleOptions, setImageStyleOptions] = useState([]);
  const [textStyleOptions, setTextStyleOptions] = useState([]);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (lookups) {
      if (lookups.l_textStyles && lookups.l_textStyles.lookupValues) {
        setTextStyleOptions(
          lookups.l_textStyles.lookupValues.map((l) => ({
            value: l.lookupValueKey,
            label: l.lookupValueKey,
          }))
        );
      }
      if (lookups.l_imageStyles && lookups.l_imageStyles.lookupValues) {
        setImageStyleOptions(
          lookups.l_imageStyles.lookupValues.map((l) => ({
            value: l.lookupValueKey,
            label: l.lookupValueKey,
          }))
        );
      }
    }
  }, [lookups]);

  // Should be in lookups
  // const imageStyleOptions = [
  //   { value: "text-below", label: "Text Below" },
  //   { value: "text-above", label: "Text Above" },
  //   { value: "full-image", label: "Full Page Image" },
  // ];
  // const textStyleOptions = [
  //   { value: "futura-xs", label: "Futura-XS font" },
  //   { value: "futura-sm", label: "Futura-SM font" },
  //   { value: "futura-md", label: "Futura-MD font" },
  //   { value: "futura-lg", label: "Futura-LG font" },
  //   { value: "futura-xl", label: "Futura-XL font" },
  //   { value: "roboto-xs", label: "Roboto-XS font" },
  //   { value: "roboto-sm", label: "Roboto-SM font" },
  //   { value: "roboto-md", label: "Roboto-MD font" },
  //   { value: "roboto-lg", label: "Roboto-LG font" },
  //   { value: "roboto-xl", label: "Roboto-XL font" },
  // ];

  const onFileInputChange = (event, isImage) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      if (isImage) {
        setImageUploadLoading(true);
      } else {
        setSoundUploadLoading(true);
      }
      const imagekit = new ImageKit({
        publicKey: "public_qaLsRdm60abMeMnCPrq6XYAc0Gw=",
        urlEndpoint: "https://ik.imagekit.io/mvnw0kw1mga",
        authenticationEndpoint: "https://okumaplatformu.com/api/upload/imagekit-endpoint",
      });

      const file = files[0];

      if (isImage) {
        imagekit.upload(
          {
            file,
            fileName: file.name,
          },
          (err, result) => {
            if (err) {
              // eslint-disable-next-line no-console
              console.log("Error on imagekit.upload");
              // eslint-disable-next-line no-console
              console.log(err);
              return;
            }

            setImageUrl(result.url);
            setImageUploadLoading(false);
          }
        );
      } else {
        // CODE HERE
        const fileNameArray = file.name.split(".");
        let extention;
        if (fileNameArray && fileNameArray.length > 1) {
          extention = fileNameArray[fileNameArray.length - 1];
        }
        const folderName = "edictsoundfiles";

        axios
          .get(
            `https://okumaplatformu.com/api/upload/get-do-presigned-url?type=audio/mpeg&folder=${folderName}&extention=${extention}`
          )
          .then((res) => {
            const { key, url } = res.data;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", `${url}`);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.setRequestHeader("x-amz-acl", "public-read");
            xhr.send(file);
            // setSoundUploadLoading(true);

            xhr.onload = (e) => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  // eslint-disable-next-line no-console
                  // console.log("Response Url:", xhr.responseURL.split("?")[0]);
                  setSoundUrl(`https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`);
                  setSoundFileName(file.name);
                } else {
                  // eslint-disable-next-line no-console
                  console.error(xhr.statusText);
                }
              }
            };

            xhr.onerror = (e) => {
              // eslint-disable-next-line no-console
              console.error("on sound upload error");
              // eslint-disable-next-line no-console
              console.error(xhr.statusText);
            };

            xhr.onloadend = (ev) => {
              setSoundUploadLoading(false);
            };
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    const currentPage = pages.find((el) => el.pageSequence === sequence);

    if (currentPage) {
      setImageUrl(currentPage.image);
      setSoundUrl(currentPage.sound);
    }
    // return () => {
    //   currentPage = null;
    // };
  }, [pages, sequence]);

  const handleSubmit = (values, actions) => {
    // If there is no bookId, dont do anything
    if (!currentBookId || currentBookId < 1) {
      return;
    }

    const formattedValues = {
      bookId: currentBookId,
      pageSequence: sequence,
      pageNumber: number,
      image: imageUrl,
      text: values.textContent,
      sound: soundUrl,
      imageStyle: values.imageStyle?.value,
      textStyle: values.textStyle?.value,
    };

    const index = pages.findIndex((el) => el.pageSequence === sequence);
    if (index >= 0) {
      formattedValues.id = pages[index].id;
    }

    savePage(formattedValues);
    if (isMounted.current) {
      actions.setSubmitting(false);
    }

    if (!lastStep) {
      handleNext();
      if (isMounted.current) {
        actions.resetForm(initialValues);
      }
      setImageUrl("");
      setSoundUrl("");
      setSoundFileName("");
      setImageUploadLoading(false);
      setSoundUploadLoading(false);
    }
  };

  const getInitialValue = () => {
    if (pages && sequence) {
      const currentPage = pages.find((el) => el.pageSequence === sequence);

      if (currentPage) {
        return {
          imageStyle: currentPage.imageStyle
            ? imageStyleOptions.find((img) => img.value === currentPage.imageStyle)
            : null,
          textStyle: currentPage.textStyle
            ? textStyleOptions.find((txt) => txt.value === currentPage.textStyle)
            : null,
          textContent: currentPage.text,
        };
      }
    }
    return initialValues;
  };

  return isMounted.current ? (
    <Formik
      initialValues={getInitialValue()}
      validationSchema={validations.validation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue, setFieldTouched, isSubmitting }) => (
        <>
          <Form id={formId} autoComplete="off">
            <SuiBox>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    alignItems="stretch"
                    pr={2}
                    style={{
                      borderRightColor: grey[200],
                      borderRightWidth: "1px",
                      borderRightStyle: "solid",
                    }}
                  >
                    <SuiTypography variant="h5" align="left" textTransform="capitalize">
                      {name}
                    </SuiTypography>
                    <SuiTypography
                      align="left"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      mt={0}
                    >
                      Cover pages consist of images only.
                    </SuiTypography>

                    <SuiBox mt={5}>
                      <CustomFormField
                        {...imageStyleRest}
                        options={imageStyleOptions}
                        value={values.imageStyle}
                        error={errors.imageStyle?.length > 0 && !(touched.imageStyle !== true)}
                        success={values.imageStyle && !errors.imageStyle}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <SuiBox mt={2}>
                        <SuiButton
                          variant="outlined"
                          color="dark"
                          size="small"
                          component="label"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                          }}
                        >
                          {imageUploadLoading ? (
                            <CircularProgress color="primary" size="1rem" />
                          ) : (
                            "Upload Image"
                          )}
                          <input
                            name="file"
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(e) => onFileInputChange(e, true)}
                          />
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox mt={5}>
                      <CustomFormField
                        {...textStyleRest}
                        options={textStyleOptions}
                        value={values.textStyle}
                        error={errors.textStyle?.length > 0 && !(touched.textStyle !== true)}
                        success={values.textStyle && !errors.textStyle}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </SuiBox>

                    <SuiBox>
                      <CustomFormField
                        {...textContentRest}
                        value={values.textContent}
                        error={errors.textContent?.length > 0 && touched.textContent}
                        success={values.textContent.length > 0 && !errors.textContent}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      {/* 
                  AŞAĞIDAKİ KISIM PREVIEW'DE KULLANILABİLİR:
                  <div dangerouslySetInnerHTML={{ __html: editorValue }} /> 
                */}
                    </SuiBox>

                    <SuiBox mt={5}>
                      <SuiButton
                        variant="outlined"
                        color="dark"
                        size="small"
                        component="label"
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                        }}
                      >
                        {soundUploadLoading ? (
                          <CircularProgress color="primary" size="1rem" />
                        ) : (
                          "Upload Sound"
                        )}
                        <input
                          name="file"
                          type="file"
                          accept="audio/*"
                          hidden
                          onChange={(e) => onFileInputChange(e, false)}
                        />
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SuiBox display="flex" flexDirection="column" alignItems="start">
                    <SuiTypography variant="h5" align="center" textTransform="capitalize">
                      Preview
                    </SuiTypography>
                    <SuiTypography
                      align="center"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      mt={0}
                    >
                      {" "}
                    </SuiTypography>
                    <PagePreview
                      imageUrl={imageUrl}
                      pageNumber={number}
                      textContent={values.textContent}
                      textStyle={values.textStyle?.value}
                    />
                    {/* <SuiBox mt={2}>
                      {values.imageStyle?.value === "text-above" ? (
                        <div dangerouslySetInnerHTML={{ __html: values.textContent }} />
                      ) : null}

                      <SuiBox>
                        {imageUploadLoading ? (
                          <CircularProgress color="primary" size="4rem" />
                        ) : imageUrl !== "" ? (
                          <img
                            src={imageUrl}
                            alt="Kitap kapak dış ya da iç imajı"
                            style={{ objectFit: "fill", width: "100%", maxWidth: "100%" }}
                          />
                        ) : (
                          <SuiBox />
                        )}
                      </SuiBox>

                      {values.imageStyle?.value === "text-below" ? (
                        <div dangerouslySetInnerHTML={{ __html: values.textContent }} />
                      ) : null}
                    </SuiBox> */}
                    <SuiBox mt={5}>
                      {soundUploadLoading ? (
                        <CircularProgress color="primary" size="4rem" />
                      ) : soundUrl !== "" ? (
                        <>
                          <SuiTypography
                            align="left"
                            variant="h6"
                            fontWeight="regular"
                            color="text"
                            mt={0}
                          >
                            {soundFileName}
                          </SuiTypography>
                          <ReactAudioPlayer src={soundUrl} controls />
                        </>
                      ) : (
                        <SuiBox />
                      )}
                    </SuiBox>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
            <SuiBox mt={5}>
              <Divider />
            </SuiBox>
            <SuiBox mt={5} width="100%" display="flex" justifyContent="space-between">
              {firstStep ? (
                <SuiBox />
              ) : (
                <SuiButton
                  disabled={imageUploadLoading || soundUploadLoading}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={handleBack}
                >
                  previous page
                </SuiButton>
              )}
              <SuiBox ml={1}>
                <SuiButton
                  variant="outlined"
                  disabled={imageUploadLoading || soundUploadLoading || isSubmitting}
                  color="dark"
                  size="small"
                  type="submit"
                >
                  {lastStep ? "save last page" : "save and go next page"}
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Form>
        </>
      )}
    </Formik>
  ) : (
    <SuiBox width="100%" display="flex" flexDirection="column" alignItems="center">
      <CircularProgress color="primary" size="8rem" />
    </SuiBox>
  );
}

PageInfo.propTypes = {
  name: PropTypes.string.isRequired,
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  sequence: PropTypes.number.isRequired,
  currentBookId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pages: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  lookups: PropTypes.object.isRequired,
  savePage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentBookId } = state.data;
  let pages = [];
  if (currentBookId && currentBookId > 0) {
    pages = state.data.books[currentBookId].pages;
  }

  let lookups = {};
  if (state.data.lookups && Object.keys(state.data.lookups).length !== 0) {
    lookups = {
      l_textStyles: state.data.lookups[TEXT_STYLES],
      l_imageStyles: state.data.lookups[IMAGE_STYLES],
    };
  }

  return {
    currentBookId,
    pages,
    lookups,
  };
};

const mapDispatchToProps = {
  savePage: savePageInfo,
  // startScreen: startCoverInfoScreen,
  // getLookups: getBookFormLookups,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageInfo);
