import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import QuestionDraggable from "../QuestionDraggable";

/* Droppable children function's snapshot
    droppableSnapshot = {
      isDraggingOver: true,
      draggingOverWith: 'task-1',
    };
  */

const QuestionsDroppable = ({ unsavedQuiz, permittedDroppable }) => (
  <Droppable droppableId="all-quiz" type="question">
    {(provided, snapshot) => (
      <Card
        ref={provided.innerRef}
        {...provided.droppableProps}
        raised
        sx={({ borders: { borderWidth, borderColor } }) => ({
          height: "100%",
          backgroundColor: snapshot.isDraggingOver ? "#CBDDF1" : "transparent",
          boxShadow: "none",
          border: `${borderWidth[1]} solid ${borderColor}`,
          transition: "background-color 0.5s ease",
        })}
      >
        <SuiBox display="flex" flexDirection="column" height="100%" p={3}>
          {unsavedQuiz?.questionOrder?.map((q, index) => (
            <QuestionDraggable
              key={q}
              questionId={q}
              index={index}
              permittedDroppable={permittedDroppable}
            />
          ))}
          {provided.placeholder}
        </SuiBox>
      </Card>
    )}
  </Droppable>
);

QuestionsDroppable.defaultProps = {
  unsavedQuiz: null,
  permittedDroppable: "",
};

QuestionsDroppable.propTypes = {
  unsavedQuiz: PropTypes.objectOf(PropTypes.any),
  permittedDroppable: PropTypes.string,
};

const mapStateToProps = (state) => ({
  unsavedQuiz: state.data.unsavedQuiz,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsDroppable);
