/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from "react";
import "../../../../assets/fonts/OKAFuturaBook.ttf";
import "./_page-preview.css";

const PagePreview = ({ imageUrl, pageNumber, textContent, textStyle }) => (
  <div className="page-preview">
    <div className="page-content">
      <div className="image">
        <img src={imageUrl} alt="" />
      </div>
      <div className="text-area">
        <div className="page-number to-left">
          <span>{pageNumber % 2 === 0 ? pageNumber : ""}</span>
        </div>
        <div
          className={`text-content ${textStyle?.toLowerCase()}`}
          dangerouslySetInnerHTML={{
            __html: textContent,
          }}
        />
        <div className="page-number to-right">
          <span>{pageNumber % 2 === 1 ? pageNumber : ""}</span>
        </div>
      </div>
    </div>
  </div>
);

export default PagePreview;
