const localizeForm = (
  formObject,
  {
    QUIZ_FORM_BOOK_LABEL,
    QUIZ_FORM_BOOK_PLACEHOLDER,
    QUIZ_FORM_BOOK_ERROR,
    QUIZ_FORM_TYPE_LABEL,
    QUIZ_FORM_TYPE_PLACEHOLDER,
    QUIZ_FORM_TYPE_ERROR,
    QUIZ_FORM_DURATION_LABEL,
    QUIZ_FORM_DURATION_PLACEHOLDER,
    QUIZ_FORM_DURATION_ERROR,
    QUIZ_FORM_GRADE_LABEL,
    QUIZ_FORM_GRADE_PLACEHOLDER,
    QUIZ_FORM_GRADE_ERROR,
    QUIZ_FORM_LEVEL_LABEL,
    QUIZ_FORM_LEVEL_PLACEHOLDER,
    QUIZ_FORM_LEVEL_ERROR,
    QUIZ_FORM_ORDER_LABEL,
    QUIZ_FORM_ORDER_PLACEHOLDER,
    QUIZ_FORM_ORDER_ERROR,
    QUIZ_FORM_PUBLISHED_LABEL,
    QUIZ_FORM_PRIMARY_LABEL,
  }
) => {
  const updatedForm = {
    ...formObject,
    formField: {
      ...formObject.formField,
      book: {
        ...formObject.formField.book,
        label: QUIZ_FORM_BOOK_LABEL ?? formObject.formField.book.label,
        placeholder: QUIZ_FORM_BOOK_PLACEHOLDER ?? formObject.formField.book.placeholder,
        errorMsg: QUIZ_FORM_BOOK_ERROR ?? formObject.formField.book.errorMsg,
      },
      quizType: {
        ...formObject.formField.quizType,
        label: QUIZ_FORM_TYPE_LABEL ?? formObject.formField.quizType.label,
        placeholder: QUIZ_FORM_TYPE_PLACEHOLDER ?? formObject.formField.quizType.placeholder,
        errorMsg: QUIZ_FORM_TYPE_ERROR ?? formObject.formField.quizType.errorMsg,
      },
      duration: {
        ...formObject.formField.duration,
        label: QUIZ_FORM_DURATION_LABEL ?? formObject.formField.duration.label,
        placeholder: QUIZ_FORM_DURATION_PLACEHOLDER ?? formObject.formField.duration.placeholder,
        errorMsg: QUIZ_FORM_DURATION_ERROR ?? formObject.formField.duration.errorMsg,
      },
      quizGrade: {
        ...formObject.formField.quizGrade,
        label: QUIZ_FORM_GRADE_LABEL ?? formObject.formField.quizGrade.label,
        placeholder: QUIZ_FORM_GRADE_PLACEHOLDER ?? formObject.formField.quizGrade.placeholder,
        errorMsg: QUIZ_FORM_GRADE_ERROR ?? formObject.formField.quizGrade.errorMsg,
      },
      quizLevel: {
        ...formObject.formField.quizLevel,
        label: QUIZ_FORM_LEVEL_LABEL ?? formObject.formField.quizLevel.label,
        placeholder: QUIZ_FORM_LEVEL_PLACEHOLDER ?? formObject.formField.quizLevel.placeholder,
        errorMsg: QUIZ_FORM_LEVEL_ERROR ?? formObject.formField.quizLevel.errorMsg,
      },
      quizOrder: {
        ...formObject.formField.quizOrder,
        label: QUIZ_FORM_ORDER_LABEL ?? formObject.formField.quizOrder.label,
        placeholder: QUIZ_FORM_ORDER_PLACEHOLDER ?? formObject.formField.quizOrder.placeholder,
        errorMsg: QUIZ_FORM_ORDER_ERROR ?? formObject.formField.quizOrder.errorMsg,
      },
      isQuizPublished: {
        ...formObject.formField.isQuizPublished,
        label: QUIZ_FORM_PUBLISHED_LABEL ?? formObject.formField.isQuizPublished.label,
      },
      isQuizPrimary: {
        ...formObject.formField.isQuizPrimary,
        label: QUIZ_FORM_PRIMARY_LABEL ?? formObject.formField.isQuizPrimary.label,
      },
    },
  };

  return updatedForm;
};

export default localizeForm;
