import * as Yup from "yup";
import checkout from "layouts/material/components/material-details/schemas/materialDetailsForm";

const {
  formField: {
    book,
    grade,
    course,
    skill,
    unit,
    specialDay,
    objectives,
    duration,
    level,
    materialOrder,
    documentType,
    description,
    introduction,
    activity,
    assessment,

    learningDesignType,
  },
} = checkout;

export default {
  validation: Yup.object().shape({
    [book.name]: Yup.object().nullable().required(book.errorMsg),
    [grade.name]: Yup.object().nullable().required(grade.errorMsg),
    [course.name]: Yup.object().nullable().required(course.errorMsg),
    [skill.name]: Yup.object().when(course.name, (courseValue, schema) =>
      courseValue?.value?.includes("TURKISH") ? schema.required(skill.errorMsg) : schema.nullable()
    ),
    [unit.name]: Yup.object().when(course.name, (courseValue, schema) =>
      courseValue?.value?.includes("LIFE_SCIENCE")
        ? schema.required(unit.errorMsg)
        : schema.nullable()
    ),
    [specialDay.name]: Yup.object().when(course.name, (courseValue, schema) =>
      courseValue?.value === "SPECIAL_DAYS"
        ? schema.required(specialDay.errorMsg)
        : schema.nullable()
    ),
    [objectives.name]: Yup.array().when(course.name, (courseValue, schema) =>
      courseValue?.value === "SPECIAL_DAYS"
        ? schema.nullable()
        : schema
            .min(1, objectives.errorMsg)
            .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    ),
    [duration.name]: Yup.number().min(1, duration.errorMsg),
    [level.name]: Yup.object().nullable().required(level.errorMsg),
    [materialOrder.name]: Yup.number().min(1, materialOrder.errorMsg),
    [documentType.name]: Yup.object().nullable().required(documentType.errorMsg),
    [description.name]: Yup.string().required(description.errorMsg),
    [introduction.name]: Yup.string().required(introduction.errorMsg),
    [activity.name]: Yup.string().required(activity.errorMsg),
    [assessment.name]: Yup.string().required(assessment.errorMsg),
    [learningDesignType.name]: Yup.object().nullable().required(learningDesignType.errorMsg),
  }),
};
