/* eslint-disable no-case-declarations */
import { apiRequest, API_SUCCESS, API_ERROR } from "redux/actions/api";
import { setLocalization, LOCALIZATION, START_LOCALIZATION } from "redux/actions/localization";
import { getFromStorage, FOUND_IN_STORAGE, NOT_FOUND_IN_STORAGE } from "redux/actions/storage";
import { BASE_URL, PRODUCT_TYPE, CLIENT, LANGUAGE_STORAGE_KEY } from "redux/actions/constants";
import SeverityTypes from "enums/severity-types";
import { sendLog } from "redux/actions/log";

// Constants
const LOCALIZATION_URL = `${BASE_URL}/api/localization/get-current-localizations`;

// Localization Middleware implementation
export const localizationMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case START_LOCALIZATION:
        const currentUserId = getState().auth?.currentUser?.id;

        if (currentUserId && currentUserId > 0) {
          // IF logged in, send api request without lang parameter
          next(
            apiRequest({
              body: { client: CLIENT },
              method: "POST",
              url: LOCALIZATION_URL,
              feature: LOCALIZATION,
            })
          );
        } else if (action.payload && action.payload.language && action.payload.language !== "") {
          next(
            apiRequest({
              body: { client: CLIENT, lang: action.payload.language },
              method: "POST",
              url: LOCALIZATION_URL,
              feature: LOCALIZATION,
            })
          );
        } else {
          // IF NOT logged in, get LANG from local storage
          // and send request with lang parameter
          next(
            getFromStorage({
              storageKey: LANGUAGE_STORAGE_KEY,
              feature: LOCALIZATION,
            })
          );
        }

        break;
      case `${LOCALIZATION} ${FOUND_IN_STORAGE}`:
        next(
          apiRequest({
            body: { client: CLIENT, lang: action.payload },
            method: "POST",
            url: LOCALIZATION_URL,
            feature: LOCALIZATION,
          })
        );
        break;
      case `${LOCALIZATION} ${NOT_FOUND_IN_STORAGE}`:
        const defaultLanguage = "TR";
        next(
          apiRequest({
            body: { client: CLIENT, lang: defaultLanguage },
            method: "POST",
            url: LOCALIZATION_URL,
            feature: LOCALIZATION,
          })
        );
        break;
      case `${LOCALIZATION} ${API_SUCCESS}`:
        next(setLocalization({ ...action.payload }));
        break;
      case `${LOCALIZATION} ${API_ERROR}`:
        // eslint-disable-next-line no-console
        console.log("ERROR on localization :", action.payload);
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${LOCALIZATION} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      default:
        break;
    }
  };
