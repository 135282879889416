import { PAGES } from "redux/actions/pages/constants";

// feature name
export const ALL_MATERIALS = `${PAGES} [AllMaterials]`;
export const MATERIAL_FORM = `${PAGES} [MaterialForm]`;

// action types
export const START_NEW_MATERIAL_SCREEN = `${MATERIAL_FORM} START`;
export const GET_MATERIAL_FORM_LOOKUPS = `${MATERIAL_FORM} GET_LOOKUPS`;
export const SAVE_MATERIAL = `${MATERIAL_FORM} SAVE_MATERIAL`;
export const START_ALL_MATERIALS_SCREEN = `${ALL_MATERIALS} START`;
export const UPDATE_MATERIAL_ON_ALL_MATERIALS = `${ALL_MATERIALS} UPDATE`;

// action creators
export const startNewMaterialScreen = () => ({
  type: START_NEW_MATERIAL_SCREEN,
});

export const getMaterialFormLookups = () => ({
  type: GET_MATERIAL_FORM_LOOKUPS,
});

export const saveMaterial = ({ material }) => ({
  type: SAVE_MATERIAL,
  payload: { material },
});

export const startAllMaterialsScreen = () => ({
  type: START_ALL_MATERIALS_SCREEN,
});

export const updateMaterialOnAllMaterials = ({ materialId, isPublished }) => ({
  type: UPDATE_MATERIAL_ON_ALL_MATERIALS,
  payload: { materialId, isPublished },
});
