/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/material/components/material-details/schemas/materialDetailsForm";

const {
  formField: {
    book,
    grade,
    course,
    skill,
    unit,
    specialDay,
    objectives,
    duration,
    level,
    materialOrder,
    documentType,
    description,
    introduction,
    activity,
    assessment,
    learningDesignType,
    isPublished,
  },
} = checkout;

export default {
  [book.name]: null,
  [grade.name]: null,
  [course.name]: null,
  [skill.name]: null,
  [unit.name]: null,
  [specialDay.name]: null,
  [objectives.name]: [],
  [duration.name]: 0,
  [level.name]: null,
  [materialOrder.name]: 0,
  [documentType.name]: null,
  [description.name]: "",
  [introduction.name]: "",
  [activity.name]: "",
  [assessment.name]: "",
  [learningDesignType.name]: null,
  [isPublished.name]: false,
};
