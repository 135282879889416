/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
// import { v4 as uuidv4 } from "uuid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiBadgeDotLevelColor from "components/SuiBadgeDotLevelColor";

// TODO: Bu kısım en son düzenlenecek...
// LEVEL_KEY'ler belirlendikten sonra aşağıdaki kısım güncellenecek.
const levelKeys = [
  "LEVEL_1",
  "LEVEL_1+",
  "LEVEL_2",
  "LEVEL_2+",
  "LEVEL_3",
  "LEVEL_3+",
  "LEVEL_4",
  "LEVEL_4+",
  "LEVEL_5",
  "LEVEL_5+",
  "LEVEL_6",
  "LEVEL_7",
  "LEVEL_8",
  "LEVEL_9",
  "LEVEL_10",
];

function BookLevel({ content }) {
  return (
    <SuiBox ml={-1.325}>
      <SuiBadgeDotLevelColor
        size="sm"
        badgeContent={content}
        color={levelKeys.includes(content) ? content : "dark"}
      />
    </SuiBox>
  );
}

// Typechecking props for the BookLevel
BookLevel.propTypes = {
  content: PropTypes.string.isRequired,
};

export default BookLevel;
