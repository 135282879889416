/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

// uuid is a library for generating unique id
// import { v4 as uuidv4 } from "uuid";

// Soft UI Dashboard PRO React components
import SuiCover from "components/SuiCover";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function BookCell({ image, name, fontWeight, path, id }) {
  return (
    <SuiBox display="flex" alignItems="center" py={0.5} px={1}>
      <SuiBox mr={2}>
        <SuiCover src={image} name={name} variant="square" size="sm" />
      </SuiBox>
      <Link
        to={{
          pathname: path,
          value: { id },
        }}
      >
        <SuiTypography variant="button" fontWeight={fontWeight} sx={{ width: "max-content" }}>
          {name}
        </SuiTypography>
      </Link>
    </SuiBox>
  );
}

// Setting default value for the props of BookCell
BookCell.defaultProps = {
  fontWeight: "regular",
};

// Typechecking props for the BookCell
BookCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fontWeight: PropTypes.string,
  path: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default BookCell;
