/* eslint-disable react/require-default-props */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiCover from "components/SuiCover";
import coverPlaceholder from "assets/images/book-cover-placeholder.png";
import CustomFormField from "../../../../components/CustomFormField";

function BookTitle({ author, illustrator, title, cover, ...rest }) {
  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiBox display="flex" alignItems="center">
          <SuiBox mr={2}>
            <SuiCover src={cover ?? coverPlaceholder} name="cover" variant="square" size="lg" />
          </SuiBox>
          <SuiBox lineHeight={0.8}>
            <SuiTypography variant="h5">{title === "" ? "Title of the Book" : title}</SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text" mt={0}>
              {author && author !== "" ? author : "Author"} /{" "}
              {illustrator && illustrator !== "" ? illustrator : "Illustrator"}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox display="flex" alignItems="baseline">
          <CustomFormField {...rest} />
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

BookTitle.propTypes = {
  author: PropTypes.string,
  illustrator: PropTypes.string,
  title: PropTypes.string,
  cover: PropTypes.string,
};

BookTitle.defaultProps = {
  title: "",
};

export default BookTitle;
