import { PAGES } from "redux/actions/pages/constants";

// feature name
export const ALL_BOOKS = `${PAGES} [AllBooks]`;
export const BOOK_FORM = `${PAGES} [BookForm]`;

// action types
export const START_ALL_BOOKS_SCREEN = `${ALL_BOOKS} START`;
export const UPDATE_BOOK_ON_ALL_BOOKS = `${ALL_BOOKS} UPDATE`;
export const START_BOOK_FORM_SCREEN = `${BOOK_FORM} START`;
export const SAVE_GENERAL_INFO = `${BOOK_FORM} SAVE_GENERAL_INFO`;
export const CLOSE_BOOK_FORM_SCREEN = `${BOOK_FORM} CLOSE`;
export const GET_BOOK_FORM_LOOKUPS = `${BOOK_FORM} GET_LOOKUPS`;
export const SAVE_PAGE_INFO = `${BOOK_FORM} SAVE_PAGE_INFO`;

// action creators
export const startAllBooksScreen = () => ({
  type: START_ALL_BOOKS_SCREEN,
});

export const updateBookOnAllBooks = ({ bookId, isPublished }) => ({
  type: UPDATE_BOOK_ON_ALL_BOOKS,
  payload: { bookId, isPublished },
});

export const startBookFormScreen = ({ bookId }) => ({
  type: START_BOOK_FORM_SCREEN,
  payload: { bookId },
});

export const closeBookFormScreen = () => ({
  type: CLOSE_BOOK_FORM_SCREEN,
});

export const getBookFormLookups = () => ({
  type: GET_BOOK_FORM_LOOKUPS,
});

export const saveGeneralInfo = ({
  id,
  title,
  generalInfo,
  publisher,
  author,
  illustrator,
  language,
  translator,
  pageCount,
  wordCount,
  grade,
  category,
  genre,
  series,
  level,
  orderInLevel,
  use,
  keywords,
  isPublished,
  subjectArea,
}) => ({
  type: SAVE_GENERAL_INFO,
  payload: {
    id,
    title,
    generalInfo,
    publisher,
    author,
    illustrator,
    language,
    translator,
    pageCount,
    wordCount,
    grade,
    category,
    genre,
    series,
    level,
    orderInLevel,
    use,
    keywords,
    isPublished,
    subjectArea,
  },
});

export const savePageInfo = ({
  id,
  bookId,
  pageSequence,
  pageNumber,
  image,
  text,
  sound,
  imageStyle,
  textStyle,
}) => ({
  type: SAVE_PAGE_INFO,
  payload: {
    id,
    bookId,
    pageSequence,
    pageNumber,
    image,
    text,
    sound,
    imageStyle,
    textStyle,
  },
});
